import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { BLACK, PRIMARY, WHITE } from "../../appConstants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const OnBoarding = () => {
    const navigate = useNavigate();
    //const [stage, setStage] = useState<string | null>(null);
    const stage = useSelector((state: any) => state.appData.stage);
    const { pathname } = useLocation();
    useEffect(() => {
        if (stage === "ONBOARDING") {
            navigate("/onBoarding/category");
        } else if (stage === "CRAWLING") {
            navigate("/onBoarding/crawl");
        } else if (stage === "CRAWLED") {
            navigate("/onBoarding/categoryMapping");
        } else if (stage === "ONBOARDED") {
         //   navigate("/onBoarding/onboarded");
        }
    }, [stage]);


    return (
        <div>
            {
                // stage === "ONBOARDED" ? (
                //     <div className="flex justify-center mt-20" style={{ fontSize: 36, fontWeight: 400 }} >
                //         Brand Already Onboarded.
                //     </div>
                // ) : (
                <>
                    <div className="flex items-center justify-between" style={{ height: 56, padding: "28px 40px", backgroundColor: BLACK.B_100, borderTop: `1px solid ${WHITE.W_10}` }} >
                        {/* Category */}
                        <div style={{ fontSize: 18, fontWeight: 500, color: (pathname === "/onBoarding/category" || pathname === "/onBoarding/viewCategory" ? PRIMARY.P_BLue : WHITE.W_40), pointerEvents: (stage === "ONBOARDING" ? "all" : "none"), opacity: (stage === "ONBOARDING" ? 1 : 0.5) }} onClick={() => navigate("/onBoarding/category")} className="flex flex-col items-center max-w-fit cursor-pointer" >
                            Category
                        </div>
                        <hr style={{ width: 40 }} />
                        {/* Keyword */}
                        <div style={{ fontSize: 18, fontWeight: 500, color: (pathname === "/onBoarding/keyword" || pathname === "/onBoarding/viewKeyword" ? PRIMARY.P_BLue : WHITE.W_40), pointerEvents: (stage === "ONBOARDING" ? "all" : "none"), opacity: (stage === "ONBOARDING" ? 1 : 0.5) }} onClick={() => navigate("/onBoarding/keyword")} className="flex flex-col items-center max-w-fit cursor-pointer" >
                            Keyword
                        </div>
                        <hr style={{ width: 40 }} />
                        {/* Product */}
                        <div style={{ fontSize: 18, fontWeight: 500, color: (pathname === "/onBoarding/product" ? PRIMARY.P_BLue : WHITE.W_40), pointerEvents: (stage === "ONBOARDING" ? "all" : "none"), opacity: (stage === "ONBOARDING" ? 1 : 0.5) }} onClick={() => navigate("/onBoarding/product")} className="flex flex-col items-center max-w-fit cursor-pointer" >
                            Product
                        </div>
                        <hr style={{ width: 40 }} />
                        {/* Campaign */}
                        <div style={{ fontSize: 18, fontWeight: 500, color: (pathname === "/onBoarding/campaign" ? PRIMARY.P_BLue : WHITE.W_40), pointerEvents: (stage === "ONBOARDING" ? "all" : "none"), opacity: (stage === "ONBOARDING" ? 1 : 0.5) }} onClick={() => navigate("/onBoarding/campaign")} className="flex flex-col items-center max-w-fit" >
                            Campaign
                        </div>
                        <hr style={{ width: 40 }} />
                        {/* Crawl */}
                        <div style={{ fontSize: 18, fontWeight: 500, color: (pathname === "/onBoarding/crawl" ? PRIMARY.P_BLue : WHITE.W_40), pointerEvents: (stage === "ONBOARDING" || stage === "CRAWLING" ? "all" : "none"), opacity: (stage === "ONBOARDING" || stage === "CRAWLING" ? 1 : 0.5) }} onClick={() => navigate("/onBoarding/crawl")} className="flex flex-col items-center max-w-fit cursor-pointer" >
                            Crawl
                        </div>
                        <hr style={{ width: 40 }} />
                        {/* Category Mapping */}
                        <div style={{ fontSize: 18, fontWeight: 500, color: (pathname === "/onBoarding/categoryMapping" ? PRIMARY.P_BLue : WHITE.W_40), pointerEvents: (stage === "CRAWLED" ? "all" : "none"), opacity: (stage === "CRAWLED" ? 1 : 0.5) }} onClick={() => navigate("/onBoarding/categoryMapping")} className="flex flex-col items-center max-w-fit cursor-pointer" >
                            Category Mapping
                        </div>
                        <hr style={{ width: 40 }} />
                        {/* Product Mapping */}
                        <div style={{ fontSize: 18, fontWeight: 500, color: (pathname === "/onBoarding/parentChildMapping" ? PRIMARY.P_BLue : WHITE.W_40), pointerEvents: (stage === "CRAWLED" ? "all" : "none"), opacity: (stage === "CRAWLED" ? 1 : 0.5) }} onClick={() => navigate("/onBoarding/parentChildMapping")} className="flex flex-col items-center max-w-fit cursor-pointer" >
                            Product Mapping
                        </div>
                        <hr style={{ width: 40 }} />
                        {/* Favorite Selection */}
                        <div style={{ fontSize: 18, fontWeight: 500, color: (pathname === "/onBoarding/favoriteSelection" ? PRIMARY.P_BLue : WHITE.W_40), pointerEvents: (stage === "CRAWLED" ? "all" : "none"), opacity: (stage === "CRAWLED" ? 1 : 0.5) }} onClick={() => navigate("/onBoarding/favoriteSelection")} className="flex flex-col items-center max-w-fit cursor-pointer" >
                            Favorite Selection
                        </div>
                    </div>
                    <div style={{ height: "calc(100vh - 128px)" }} >
                        <Outlet />
                    </div>
                </>
                // )
            }

        </div >
    )
}



export default OnBoarding;