import React, { useCallback, useEffect, useMemo, useState } from "react"
import { BLACK, PRIMARY, WHITE } from "../../appConstants"
import { Outlet, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { getNextTimes } from "../../appFunctions";
import { useSelector } from "react-redux";



const UnmappedData: React.FC = () => {
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState<string>("PRODUCT_MASTER");
    const { brand_id: brandId } = useSelector((state: any) => state.appData);


    useEffect(() => {
        const times = getNextTimes();

        toast.custom((t) => (
            <div className="flex" style={{ width: 500, height: 92, borderRadius: 6, backgroundColor: WHITE.W_100, borderLeft: `6px solid ${PRIMARY.P_BLue}`, boxShadow: "0px 4px 10px 4px rgba(36, 36, 36, 0.15)", paddingTop: 16, paddingBottom: 16, paddingLeft: 22, paddingRight: 16 }} >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path d="M16 22.6665C16.3778 22.6665 16.6945 22.5387 16.95 22.2832C17.2056 22.0276 17.3334 21.7109 17.3334 21.3332V15.9998C17.3334 15.6221 17.2056 15.3054 16.95 15.0498C16.6945 14.7943 16.3778 14.6665 16 14.6665C15.6222 14.6665 15.3056 14.7943 15.05 15.0498C14.7945 15.3054 14.6667 15.6221 14.6667 15.9998V21.3332C14.6667 21.7109 14.7945 22.0276 15.05 22.2832C15.3056 22.5387 15.6222 22.6665 16 22.6665ZM16 11.9998C16.3778 11.9998 16.6945 11.8721 16.95 11.6165C17.2056 11.3609 17.3334 11.0443 17.3334 10.6665C17.3334 10.2887 17.2056 9.97206 16.95 9.7165C16.6945 9.46095 16.3778 9.33317 16 9.33317C15.6222 9.33317 15.3056 9.46095 15.05 9.7165C14.7945 9.97206 14.6667 10.2887 14.6667 10.6665C14.6667 11.0443 14.7945 11.3609 15.05 11.6165C15.3056 11.8721 15.6222 11.9998 16 11.9998ZM16 29.3332C14.1556 29.3332 12.4222 28.9832 10.8 28.2832C9.1778 27.5832 7.76669 26.6332 6.56669 25.4332C5.36669 24.2332 4.41669 22.8221 3.71669 21.1998C3.01669 19.5776 2.66669 17.8443 2.66669 15.9998C2.66669 14.1554 3.01669 12.4221 3.71669 10.7998C4.41669 9.17761 5.36669 7.7665 6.56669 6.5665C7.76669 5.3665 9.1778 4.4165 10.8 3.7165C12.4222 3.0165 14.1556 2.6665 16 2.6665C17.8445 2.6665 19.5778 3.0165 21.2 3.7165C22.8222 4.4165 24.2334 5.3665 25.4334 6.5665C26.6334 7.7665 27.5834 9.17761 28.2834 10.7998C28.9834 12.4221 29.3334 14.1554 29.3334 15.9998C29.3334 17.8443 28.9834 19.5776 28.2834 21.1998C27.5834 22.8221 26.6334 24.2332 25.4334 25.4332C24.2334 26.6332 22.8222 27.5832 21.2 28.2832C19.5778 28.9832 17.8445 29.3332 16 29.3332Z" fill="#1886FE" />
                </svg>
                <div className="flex flex-col ml-4 mr-6" style={{ width: 370, height: 60 }} >
                    <div className="mb-1" style={{ fontSize: 14, fontWeight: 700, lineHeight: "20px" }} >
                        Schedule Refresh Reminder
                    </div>
                    <div style={{ fontSize: 12, fontWeight: 400, lineHeight: "18px" }}>
                        Next refresh at <b>{times.nextTime}</b>. Map all data before then or wait until {times.nextToNextTime} to update the dashboard.
                    </div>
                </div>
                <div onClick={() => toast.dismiss(t.id)} className="cursor-pointer" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M16.0672 15.1827C16.1252 15.2407 16.1713 15.3097 16.2027 15.3855C16.2342 15.4614 16.2503 15.5427 16.2503 15.6249C16.2503 15.707 16.2342 15.7883 16.2027 15.8642C16.1713 15.94 16.1252 16.009 16.0672 16.067C16.0091 16.1251 15.9402 16.1712 15.8643 16.2026C15.7884 16.234 15.7071 16.2502 15.625 16.2502C15.5429 16.2502 15.4615 16.234 15.3857 16.2026C15.3098 16.1712 15.2409 16.1251 15.1828 16.067L9.99998 10.8835L4.81717 16.067C4.69989 16.1843 4.54083 16.2502 4.37498 16.2502C4.20913 16.2502 4.05007 16.1843 3.93279 16.067C3.81552 15.9498 3.74963 15.7907 3.74963 15.6249C3.74963 15.459 3.81552 15.2999 3.93279 15.1827L9.11639 9.99986L3.93279 4.81705C3.81552 4.69977 3.74963 4.54071 3.74963 4.37486C3.74963 4.20901 3.81552 4.04995 3.93279 3.93267C4.05007 3.8154 4.20913 3.74951 4.37498 3.74951C4.54083 3.74951 4.69989 3.8154 4.81717 3.93267L9.99998 9.11627L15.1828 3.93267C15.3001 3.8154 15.4591 3.74951 15.625 3.74951C15.7908 3.74951 15.9499 3.8154 16.0672 3.93267C16.1844 4.04995 16.2503 4.20901 16.2503 4.37486C16.2503 4.54071 16.1844 4.69977 16.0672 4.81705L10.8836 9.99986L16.0672 15.1827Z" fill="#1C1C1C" fill-opacity="0.4" />
                    </svg>
                </div>


            </div>
        ), { duration: 5000 })
    }, [])
    useEffect(() => {
        navigate("/unmappedData/productMaster");
    }, []);

    const tabs = useMemo(() => {
        return [
            { id: "PRODUCT_MASTER", name: "Product Master", url: "/unmappedData/productMaster" },
            { id: "CAMPAIGN_MASTER", name: "Campaign Master", url: "/unmappedData/campaignMaster" },
            { id: "KEYWORD_MASTER", name: "Keyword Master", url: "/unmappedData/keywordMaster" }]
    }, []);

    const onTabChange = (tab: string, url: string) => {
        setSelectedTab(tab);
        navigate(url);
    }

    return (
        <div style={{ height: "calc(100vh - 72px)" }} >
            <div className="flex flex-row  px-10" style={{ height: 52, boxShadow: "0px 4px 10px 4px rgba(99, 99, 99, 0.05)" }} >
                {
                    tabs.map(tab => {
                        return <div onClick={() => onTabChange(tab.id, tab.url)} key={tab.id} className="p-4 cursor-pointer" style={{ fontSize: 14, fontWeight: 500, color: selectedTab === tab.id ? PRIMARY.P_BLue : BLACK.B_40, borderBottom: selectedTab === tab.id ? `2px solid ${PRIMARY.P_BLue}` : "" }} >{tab.name}</div>
                    })
                }
            </div>
            <div className="" style={{ height: "calc(100vh - 124px)" }} >
                <Outlet />
            </div>
        </div>
    )
}


export default UnmappedData