import { Button, Option, Select, SvgIcon,selectClasses } from "@mui/joy";
import { BASE_URL, BLACK, PRIMARY } from "../../appConstants";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { UUID } from "crypto";
import axios from "axios";
import appError from "../../appError";
import { useSelector } from "react-redux";

const VisuallyHiddenInput = styled('input')`
clip: rect(0 0 0 0);
clip-path: inset(50%);
height: 1px;
overflow: hidden;
position: absolute;
bottom: 0;
left: 0;
white-space: nowrap;
width: 1px;
`;

type SubCategorybject = {
    brand_sub_category: string,
    brand_sub_category_id: UUID
}
const FavoriteSelection = () => {
    const [subCategories, seSubCategories] = useState<SubCategorybject[]>();
    const [selectedSubCategory, setSelectedSubCategory] = useState<string>("");
    const { brand_id: brandId } = useSelector((state: any) => state.appData);

    useEffect(() => {
        fetchSubCategories();
    }, []);


    const fetchSubCategories = async () => {
        try {
            const token = localStorage.getItem("token");

            const res = await axios.get(`${BASE_URL}onboarding/subCategories?brand_id=${brandId}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const { brand_sub_category_array } = res?.data?.data;
            seSubCategories(brand_sub_category_array);
        } catch (error) {
            appError(error);
        }
    }

    const onSelectSubCategoryHandler = (event: any, value: any) => {
        setSelectedSubCategory(value);
    }

    return (
        <div className="flex flex-col pt-4 px-10" >
            <div className="flex flex-row items-center mb-5" style={{ height: 62 }} >
                <div className="w-full" >
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Favorite Selection</div>
                    <div style={{ fontSize: 18, fontWeight: 400, color: BLACK.B_40 }} >Please review the list of categories along with their corresponding products and Amazon categories. Feel free to make any changes if necessary.</div>
                </div>
                <Button
                    variant="solid"
                    sx={{
                        height: "56px",
                        width: "265px",
                        marginRight: "16px",
                        ":hover": { cursor: "pointer" }
                    }}
                    startDecorator={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M17.5 11.875V16.25C17.5 16.5815 17.3683 16.8995 17.1339 17.1339C16.8995 17.3683 16.5815 17.5 16.25 17.5H3.75C3.41848 17.5 3.10054 17.3683 2.86612 17.1339C2.6317 16.8995 2.5 16.5815 2.5 16.25V11.875C2.5 11.7092 2.56585 11.5503 2.68306 11.4331C2.80027 11.3158 2.95924 11.25 3.125 11.25C3.29076 11.25 3.44973 11.3158 3.56694 11.4331C3.68415 11.5503 3.75 11.7092 3.75 11.875V16.25H16.25V11.875C16.25 11.7092 16.3158 11.5503 16.4331 11.4331C16.5503 11.3158 16.7092 11.25 16.875 11.25C17.0408 11.25 17.1997 11.3158 17.3169 11.4331C17.4342 11.5503 17.5 11.7092 17.5 11.875ZM9.55781 12.3172C9.61586 12.3753 9.68479 12.4214 9.76066 12.4529C9.83654 12.4843 9.91787 12.5005 10 12.5005C10.0821 12.5005 10.1635 12.4843 10.2393 12.4529C10.3152 12.4214 10.3841 12.3753 10.4422 12.3172L13.5672 9.19219C13.6253 9.13412 13.6713 9.06518 13.7027 8.98931C13.7342 8.91344 13.7503 8.83212 13.7503 8.75C13.7503 8.66788 13.7342 8.58656 13.7027 8.51069C13.6713 8.43482 13.6253 8.36588 13.5672 8.30781C13.5091 8.24974 13.4402 8.20368 13.3643 8.17225C13.2884 8.14083 13.2071 8.12465 13.125 8.12465C13.0429 8.12465 12.9616 8.14083 12.8857 8.17225C12.8098 8.20368 12.7409 8.24974 12.6828 8.30781L10.625 10.3664V3.125C10.625 2.95924 10.5592 2.80027 10.4419 2.68306C10.3247 2.56585 10.1658 2.5 10 2.5C9.83424 2.5 9.67527 2.56585 9.55806 2.68306C9.44085 2.80027 9.375 2.95924 9.375 3.125V10.3664L7.31719 8.30781C7.19991 8.19054 7.04085 8.12465 6.875 8.12465C6.70915 8.12465 6.55009 8.19054 6.43281 8.30781C6.31554 8.42509 6.24965 8.58415 6.24965 8.75C6.24965 8.91585 6.31554 9.07491 6.43281 9.19219L9.55781 12.3172Z" fill="white" />
                    </svg>
                    }
                >Download Template</Button>
                <Button
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    variant="outlined"
                    color="neutral"
                    size="sm"
                    sx={{
                        color: PRIMARY.P_BLue,
                        borderColor: PRIMARY.P_BLue,
                        borderRadius: "8px",
                        width: "172px",
                        height: "56px",
                    }}
                    startDecorator={
                        <SvgIcon>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                            >
                                <path
                                    color={PRIMARY.P_BLue}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                />
                            </svg>
                        </SvgIcon>
                    }
                >
                    Upload Excel
                    <VisuallyHiddenInput
                        //onChange={onFileChangeHandler}
                        type="file" />
                </Button>
            </div>

            <div className="flex flex-row">
                <div style={{ borderRadius: 8, border: `1px solid ${BLACK.B_10}` }} className="flex flex-col p-4 w-2/3">
                    {/* Header */}
                    <div  className="flex justify-start mr-8" >
                        <Select
                            value={selectedSubCategory}
                            onChange={onSelectSubCategoryHandler}
                            placeholder="Select Category"
                            indicator={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Iconography">
                                    <path id="Vector" d="M13.354 6.35354L8.35403 11.3535C8.30759 11.4 8.25245 11.4369 8.19175 11.4621C8.13105 11.4872 8.06599 11.5002 8.00028 11.5002C7.93457 11.5002 7.86951 11.4872 7.80881 11.4621C7.74811 11.4369 7.69296 11.4 7.64653 11.3535L2.64653 6.35354C2.55271 6.25972 2.5 6.13247 2.5 5.99979C2.5 5.86711 2.55271 5.73986 2.64653 5.64604C2.74035 5.55222 2.8676 5.49951 3.00028 5.49951C3.13296 5.49951 3.26021 5.55222 3.35403 5.64604L8.00028 10.2929L12.6465 5.64604C12.693 5.59958 12.7481 5.56273 12.8088 5.53759C12.8695 5.51245 12.9346 5.49951 13.0003 5.49951C13.066 5.49951 13.131 5.51245 13.1917 5.53759C13.2524 5.56273 13.3076 5.59958 13.354 5.64604C13.4005 5.69249 13.4373 5.74764 13.4625 5.80834C13.4876 5.86904 13.5006 5.93409 13.5006 5.99979C13.5006 6.06549 13.4876 6.13054 13.4625 6.19124C13.4373 6.25193 13.4005 6.30708 13.354 6.35354Z" fill="#1886FE" />
                                </g>
                            </svg>}
                            size="sm"
                            sx={{

                                marginLeft: "16px",
                                minWidth: 160, color: "#1886FE", borderRadius: 8, borderColor: "#1886FE",
                                [`& .${selectClasses.indicator}`]: {
                                    transition: '0.2s',
                                    [`&.${selectClasses.expanded}`]: {
                                        transform: 'rotate(-180deg)',
                                    },
                                },
                            }}
                        >
                            {
                                subCategories?.map(el => {
                                    return <Option value={el?.brand_sub_category}  >{el?.brand_sub_category}</Option>
                                })
                            }
                        </Select>
                    </div>
                    
                </div>
                <div className="w-1/3 ml-8" >
                    Right
                </div>
            </div>


            <Button
                sx={{
                    margin: "24px 0px",
                    width: "236px",
                    ":disabled": {
                        backgroundColor: PRIMARY.P_Blue,
                        color: BLACK.B_10,
                    }
                }}
                className="self-end"
                endDecorator={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M17.3172 10.4425L11.6922 16.0675C11.5749 16.1848 11.4159 16.2507 11.25 16.2507C11.0841 16.2507 10.9251 16.1848 10.8078 16.0675C10.6905 15.9503 10.6247 15.7912 10.6247 15.6253C10.6247 15.4595 10.6905 15.3004 10.8078 15.1832L15.3664 10.6253H3.125C2.95924 10.6253 2.80027 10.5595 2.68306 10.4423C2.56585 10.3251 2.5 10.1661 2.5 10.0003C2.5 9.83459 2.56585 9.67562 2.68306 9.55841C2.80027 9.4412 2.95924 9.37535 3.125 9.37535H15.3664L10.8078 4.81753C10.6905 4.70026 10.6247 4.5412 10.6247 4.37535C10.6247 4.2095 10.6905 4.05044 10.8078 3.93316C10.9251 3.81588 11.0841 3.75 11.25 3.75C11.4159 3.75 11.5749 3.81588 11.6922 3.93316L17.3172 9.55816C17.3753 9.61621 17.4214 9.68514 17.4529 9.76101C17.4843 9.83688 17.5005 9.91821 17.5005 10.0003C17.5005 10.0825 17.4843 10.1638 17.4529 10.2397C17.4214 10.3156 17.3753 10.3845 17.3172 10.4425Z" fill="white" />
                </svg>
                }
            //disabled={rowData.length > 0 ? false : true}
            //onClick={onSaveClickHandler}
            > Save & Continue</Button>
        </div>
    )
}

export default FavoriteSelection;