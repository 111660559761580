import { Button, Input } from "@mui/joy";
import React, { useState } from "react"
import axios from "axios";
import { BASE_URL, PRIMARY } from "../../appConstants";
import Joi, { string } from "joi";
import toast from "react-hot-toast";
import appError from "../../appError";
import AppLogo from "../appComponents/AppLogo";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [state, setState] = useState({
        email: "",
        password: ""
    });
    const [loader, setloader] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const navigate = useNavigate();

    const onInputChangeHandler = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>): void => {
        setState(prevState => ({ ...prevState, [name]: value }))
    }

    const onSubmitHandler = async () => {

        const schema = Joi.object({
            email: Joi.string().email({ tlds: { allow: false } }),
            password: Joi.string().min(8).max(50)
        })
        const validate = schema.validate(state);
        if (validate.error) {
            appError(validate?.error?.toString());
            return;
        }
        try {
            setloader(true);
            const res = await axios.post(`${BASE_URL}auth/login`, validate?.value);
            const { token, user: { first_name, user_type } } = res?.data?.data;
            localStorage.setItem("token", token);
            localStorage.setItem("first_name", first_name);
            localStorage.setItem("user_type", user_type);
            setState({ email: "", password: "" });
            toast.success('Logged in Successfully!');
            navigate("/dashboard");
        } catch (error) {
            appError(error, "auth");
        } finally {
            setloader(false);
        }
    }
    const disabledBtn = (!(state.email != "" && state.password != "") ? true : loader);
    return (
        <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/infytrix-background.webp)`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}
            className="flex flex-row justify-center  items-center   bg-red-200 h-dvh"
        >
            <div style={{ width: 1128 }} className="flex justify-between items-center" >
                <AppLogo height={72} />
                <div style={{ width: 456, height: 374 }} className="flex flex-col py-10 px-10  bg-white opacity-80 rounded-3xl">
                    <h1 className="text-center text-2xl mb-10" >Sign In</h1>
                    <Input
                        // startDecorator={}
                        // endDecorator={}
                        onChange={onInputChangeHandler}
                        name="email"
                        className="self-center mb-4"
                        placeholder="Email-ID"
                        sx={{
                            "--Input-focusedThickness": "0px",
                            width: "376px",
                            height: "60px"
                        }}
                        disabled={loader}
                    />
                    <Input
                        // startDecorator={}
                        endDecorator={
                            <>
                                {
                                    viewPassword ? (

                                        <svg onClick={() => setViewPassword(prevState => !prevState)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash cursor-pointer" viewBox="0 0 16 16">
                                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                            <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                        </svg>
                                    ) : (
                                        <svg onClick={() => setViewPassword(prevState => !prevState)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye cursor-pointer" viewBox="0 0 16 16">
                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                        </svg>
                                    )

                                }
                            </>

                        }
                        onChange={onInputChangeHandler}
                        name="password"
                        placeholder="Password"
                        type={viewPassword ? "text" : "password"}
                        className="self-center mb-8"
                        sx={{
                            "--Input-focusedThickness": "0px",
                            width: "376px",
                            height: "60px"
                        }}
                        disabled={loader}
                    />
                    {/* <h1 className="text-right text-sm mb-10" >Forgot Password?</h1> */}
                    <Button
                        loading={loader}
                        disabled={disabledBtn}
                        onClick={onSubmitHandler}
                        endDecorator={<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                            <path d="M17.8172 10.4425L12.1922 16.0675C12.0749 16.1848 11.9159 16.2507 11.75 16.2507C11.5841 16.2507 11.4251 16.1848 11.3078 16.0675C11.1905 15.9503 11.1247 15.7912 11.1247 15.6253C11.1247 15.4595 11.1905 15.3004 11.3078 15.1832L15.8664 10.6253H3.625C3.45924 10.6253 3.30027 10.5595 3.18306 10.4423C3.06585 10.3251 3 10.1661 3 10.0003C3 9.83459 3.06585 9.67562 3.18306 9.55841C3.30027 9.4412 3.45924 9.37535 3.625 9.37535H15.8664L11.3078 4.81753C11.1905 4.70026 11.1247 4.5412 11.1247 4.37535C11.1247 4.2095 11.1905 4.05044 11.3078 3.93316C11.4251 3.81588 11.5841 3.75 11.75 3.75C11.9159 3.75 12.0749 3.81588 12.1922 3.93316L17.8172 9.55816C17.8753 9.61621 17.9214 9.68514 17.9529 9.76101C17.9843 9.83688 18.0005 9.91821 18.0005 10.0003C18.0005 10.0825 17.9843 10.1638 17.9529 10.2397C17.9214 10.3156 17.8753 10.3845 17.8172 10.4425Z" fill="white" />
                        </svg>
                        }
                    >Sign In</Button>
                </div>
            </div>
        </div>
    )
}


export default Login;