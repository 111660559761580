import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL, BLACK } from "../../appConstants";
import appError from "../../appError";
import { UUID } from "crypto";
type BrandsProps = {
    user_id: UUID,
    refresh: boolean
}
const Brands = ({ user_id, refresh }: BrandsProps) => {
    type BrandObject = {
        brand_name: string,
        brand_id: UUID
    }
    const [brandArray, setBrandArray] = useState<BrandObject[]>([]);
    useEffect(() => {
        (async () => {
            try {
                const token = localStorage.getItem("token");
                const brandsRes = await axios.get(`${BASE_URL}brand/getUserBrands?user_id=${user_id}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                const { brands } = brandsRes?.data?.data;
                setBrandArray(brands);
            } catch (error) {
                appError(error);
            }
        })()
    }, [refresh]);
    return (
        <div className="flex flex-row items-center h-full w-full flex-wrap" >
            {
                brandArray?.map(brand => {
                    return (
                        <div style={{ height: 28, fontSize: 14, fontWeight: 400, color: BLACK.B_40, backgroundColor: BLACK.B_10 }} className="flex flex-row items-center px-2 py-1 rounded-lg mr-2 mt-1 mb-1" >
                            {brand?.brand_name}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Brands;