import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from "powerbi-client"
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, BLACK } from '../../appConstants';
import { CircularProgress } from '@mui/joy';
import axios from 'axios';
import appError from '../../appError';
import { UUID } from 'crypto';

type EmbedUrlObject = {
    embedUrl: string,
    reportId: UUID,
    reportName: string
}

type EmbedTokenObject = {
    accessToken: string,
    embedUrl: EmbedUrlObject[],
    expiry: string,
    status: number

}

const PowerBi = () => {
    const [embedTokenObject, setEmbedTokenObject] = useState<EmbedTokenObject>({} as EmbedTokenObject)
    const [loadingDashboard, setLoadingDashboard] = useState<boolean>(false);

    const { brand_id: brandId, stage, power_bi_url, brand_power_bi_dashboard_id } = useSelector((state: any) => state.appData);
    const navigate = useNavigate();
    useEffect(() => {
        if (stage === "PREPARING_DASHBOARD") {
            navigate("/preparingDashboard");
            return;
        }
    }, [brandId]);

    useEffect(() => {
        fetchBrands();
    }, [brand_power_bi_dashboard_id])

    const fetchBrands = async () => {
        if (!brandId) {
            return;
        }
        if (brand_power_bi_dashboard_id === "nodashboard" || brand_power_bi_dashboard_id === "loading") {
            return;
        }
        try {

            setEmbedTokenObject({} as EmbedTokenObject);

            setLoadingDashboard(true);
            const token = localStorage.getItem("token");
            const res = await axios.get(`${BASE_URL}brand/getEmbedToken/${brand_power_bi_dashboard_id}?brand_id=${brandId}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            const embedTokenObject: EmbedTokenObject = res?.data?.data?.embedTokenObject;
            setEmbedTokenObject(embedTokenObject);


        } catch (error) {
            appError(error);
        } finally {
            setLoadingDashboard(false);
        }
    }




    return (
        <>
            {
                loadingDashboard ? <>
                    <div className="flex flex-row items-center justify-center h-full" >
                        <span className="mr-4" style={{ color: BLACK.B_40 }} >Loading Dashboard</span> <CircularProgress size="sm" />
                    </div>
                </> :
                    (() => {
                        if (stage === "ONBOARDED") {
                            return (
                                (() => {
                                    if (brand_power_bi_dashboard_id === "loading") {
                                        return (
                                            <div className="flex flex-row items-center justify-center h-full" >
                                                <span className="mr-4" style={{ color: BLACK.B_40 }} >Loading dashboards</span>   <CircularProgress size="sm" />
                                            </div>
                                        )
                                    } else if (brand_power_bi_dashboard_id === "nodashboard") {
                                        return (
                                            <div className="text-center pt-20" style={{ fontSize: 28, fontWeight: 400, color: BLACK.B_80 }} >
                                                No Dashboards to view.
                                            </div>
                                        )
                                    } else {
                                        return (
                                            embedTokenObject.status === 200 && <PowerBIEmbed
                                                cssClassName='h-full'
                                                embedConfig={{
                                                    type: 'report',   // Supported types: report, dashboard, tile, visual, qna and paginated report
                                                    id: embedTokenObject?.embedUrl[0]?.reportId,
                                                    embedUrl: embedTokenObject?.embedUrl[0]?.embedUrl,
                                                    accessToken: embedTokenObject?.accessToken,
                                                    tokenType: models.TokenType.Embed

                                                }}
                                            />
                                        )
                                    }
                                })()
                            )
                        } else if (stage === "loading") {
                            return (
                                <div className="flex flex-row items-center justify-center h-full" >
                                    <span className="mr-4" style={{ color: BLACK.B_40 }} >Loading brands</span> <CircularProgress size="sm" />
                                </div>
                            )
                        } else {
                            return (
                                <div className="text-center pt-20" style={{ fontSize: 28, fontWeight: 400, color: BLACK.B_80 }} >
                                    You can view dashboards only after onboarding.
                                </div>
                            )
                        }

                    })()
            }
        </>
    )
}

export default PowerBi;






