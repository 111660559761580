import React, { useMemo, useState } from "react";
import { BLACK, PRIMARY, WHITE } from "../../appConstants";
import { Button, Skeleton, Tooltip } from "@mui/joy";
import AddBulkScheduleModal from "./AddBulkScheduleModal";
import UpcomingTable from "./UpcomingTable";
import { getDateString } from "../../appFunctions";

const LatestFiles: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState<string>("UPCOMING");
    const [openModal, setOpenModal] = useState(false);
    const [refrest, setRefresh] = useState<boolean>(false);
    const onTabChange = (tab: string, url: string) => {
        setSelectedTab(tab);
        // navigate(url);
    }


    return (
        <>
            <div className="flex flex-row justify-between  p-6 mb-6" style={{ height: 96, backgroundColor: PRIMARY.P_Purple50, borderRadius: 8 }} >
                <div className="flex flex-col justify-between" >
                    <div style={{ fontSize: "16px", fontWeight: 600, lineHeight: "24px", color: BLACK.B_100 }}>Add New Bulk Schedule</div>
                    <div style={{ fontSize: 12, fontWeight: 400, lineHeight: "18px", color: BLACK.B_40 }} >Provide the information for the new bulk schedule here</div>
                </div>
                <Button
                    //loading={lodingOnSave}
                    onClick={() => setOpenModal(true)}
                    startDecorator={
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M9.16663 10.8333H4.99996C4.76385 10.8333 4.56593 10.7534 4.40621 10.5937C4.24649 10.434 4.16663 10.2361 4.16663 9.99996C4.16663 9.76385 4.24649 9.56593 4.40621 9.40621C4.56593 9.24649 4.76385 9.16663 4.99996 9.16663H9.16663V4.99996C9.16663 4.76385 9.24649 4.56593 9.40621 4.40621C9.56593 4.24649 9.76385 4.16663 9.99996 4.16663C10.2361 4.16663 10.434 4.24649 10.5937 4.40621C10.7534 4.56593 10.8333 4.76385 10.8333 4.99996V9.16663H15C15.2361 9.16663 15.434 9.24649 15.5937 9.40621C15.7534 9.56593 15.8333 9.76385 15.8333 9.99996C15.8333 10.2361 15.7534 10.434 15.5937 10.5937C15.434 10.7534 15.2361 10.8333 15 10.8333H10.8333V15C10.8333 15.2361 10.7534 15.434 10.5937 15.5937C10.434 15.7534 10.2361 15.8333 9.99996 15.8333C9.76385 15.8333 9.56593 15.7534 9.40621 15.5937C9.24649 15.434 9.16663 15.2361 9.16663 15V10.8333Z" fill="white" />
                        </svg>
                    }
                    sx={{
                        height: "48px",
                        width: "200px",
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: "20px",
                        backgroundColor: PRIMARY.P_BLue,
                    }}
                >Add Bulk Schedule</Button>
            </div>


            <UpcomingTable route="latestFiles" filterState={{
                schedule_date: getDateString(new Date())
            }} refrest={refrest} />
            {
                openModal && <AddBulkScheduleModal setRefresh={setRefresh} setOpenModal={setOpenModal} />
            }
        </>
    )
}

export default LatestFiles;