import * as XLSX from "xlsx";


const xlsx_to_json = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      if (e.target && e.target.result) {
        const data = e.target.result as ArrayBuffer;
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const raw_data: any = XLSX.utils.sheet_to_json(worksheet);
        resolve(raw_data)
      }
    };
    reader.onerror = (e) => {
      reject(new Error('An error occurred while reading the file.'));
    };
  });
}


const getUTC = (utc: Date): Date => {
  const date = new Date(utc);
  return new Date(`${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`);
}

const addZero = new Map([
  ["1", "01"],
  ["2", "02"],
  ["3", "03"],
  ["4", "04"],
  ["5", "05"],
  ["6", "06"],
  ["7", "07"],
  ["8", "08"],
  ["9", "09"],
  ["10", "10"],
  ["11", "11"],
  ["12", "12"],
  ["13", "13"],
  ["14", "14"],
  ["15", "15"],
  ["16", "16"],
  ["17", "17"],
  ["18", "18"],
  ["19", "19"],
  ["20", "20"],
  ["21", "21"],
  ["22", "22"],
  ["23", "23"],
  ["24", "24"],
  ["25", "25"],
  ["26", "26"],
  ["27", "27"],
  ["28", "28"],
  ["29", "29"],
  ["30", "30"],
  ["31", "31"],
]);

const getDateString = (utc: Date | null) => {
  if (utc === null) {
    return "";
  }
  const [dd, mm, yyyy] = utc.toLocaleDateString("en-IN").split("/");
  return `${yyyy}-${addZero.get(mm)}-${ addZero.get(dd)}`;
}

const getTimeString = (utc: Date | null): string | undefined => {
  if (utc === null) {
    return;
  }
  const [hh, mm, ss] = utc.toLocaleTimeString('en-IN', { hour12: false }).split(":");
  return `${hh}:${mm}:${ss}`;
}



const formatDateTime = (isoString: string): Array<string> => {
  // Step 1: Parse the ISO 8601 string into a Date object
  const date = new Date(isoString);

  // Step 2: Add 5 hours and 30 minutes
  date.setUTCHours(date.getUTCHours() + 5);
  date.setUTCMinutes(date.getUTCMinutes() + 30);

  // Step 3: Extract the components
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Step 4: Format the time to 12-hour format
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Step 5: Create the formatted date and time strings
  const [d1, d2, d3, d4] = year?.toString().split("");
  const formattedDate = `${day}-${month}-${d3}${d4}`;
  const formattedTime = `${hours}:${minutes}${ampm}`;

  return [formattedDate, formattedTime];
}




export default xlsx_to_json;

// function getNextTimes() {
//   const now = new Date();

//   let currentHour = now.getHours();

//   let nextHour = currentHour;

//   if (nextHour >= 24) {
//     nextHour = 0;
//   }

//   let nextToNextHour = nextHour + 1;

//   if (nextToNextHour >= 24) {
//     nextToNextHour = 0;
//   }
//   const formatTime = (hour: any) => {
//     const ampm = hour >= 12 ? 'PM' : 'AM';
//     hour = hour % 12;
//     hour = hour ? hour : 12;
//     return `${hour}:${"00"} ${ampm}`;
//   };

//   return {
//     nextTime: formatTime(nextHour),
//     nextToNextTime: formatTime(nextToNextHour),
//   };
// }

function getNextTimes(date = new Date()) {
  // Get the next hour
  const nextHour = new Date(date);
  nextHour.setMinutes(0, 0, 0); // Set minutes, seconds, and milliseconds to 0
  nextHour.setHours(nextHour.getHours() + 1); // Increment the hour

  // Get the hour after next
  const nextToNextHour = new Date(nextHour);
  nextToNextHour.setHours(nextToNextHour.getHours() + 1); // Increment the hour again

  return {
    nextTime: nextHour.toTimeString().slice(0, 5), // Return time in HH:mm format
    nextToNextTime: nextToNextHour.toTimeString().slice(0, 5) // Return time in HH:mm format
  };
}


const getFutureDate = (days: number) => {
  const today = new Date();
  const futureDate = new Date(today);
  futureDate.setDate(today.getDate() + days);
  return futureDate;
}

const getCurrentMonthLastDate = () => {
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  return lastDayOfMonth;
}


const getNextEvent = (start_time_stamp: string, end_time_stamp: string, days: number[], time_slot: string[]): Array<string> => {

  let today = getUTC(new Date());

  if (today.getTime() < new Date(start_time_stamp).getTime()) {
    today = new Date(start_time_stamp);
  }


  let dates: Date[] = [];
  const times: string[] = [];
  let end_time_stamp_obj = new Date(end_time_stamp);
  while (today <= end_time_stamp_obj) {
    let todayDay = today.getDay();
    if (days.includes(todayDay)) {

      const currentHour = new Date().getHours();
      const currentMin = new Date().getMinutes();

      const totalCurrentMin = (currentHour * 60) + currentMin;

      for (let i = 0; i < time_slot.length; i++) {
        const [hr, min] = time_slot[i].split(":");

        const totalMin = (parseInt(hr) * 60) + parseInt(min)
        if (today.getTime() === getUTC(new Date()).getTime() && totalMin > totalCurrentMin) {

          times.push(time_slot[i]);
          dates.push(new Date(today));
        } else if (today > getUTC(new Date())) {
          times.push(time_slot[i]);
          dates.push(new Date(today));
        }

        if (times.length) {
          break;
        }
      }

    }
    if (dates.length) {
      break;
    }
    today.setDate(today.getDate() + 1);
  }

  let [nextDate] = dates;

  if (!nextDate) {
    return []
  }

  const [nextTime] = times;
  if (!nextTime) {
    return []
  }
  return [nextDate.toString(), nextTime]
}

const canEdit = (pdate: string, ptime: string) => {
  const date = getDateString(new Date(pdate));
  const date_time = new Date();
  return new Date(`${date}T${ptime}`) > date_time;
}


export {
  formatDateTime,
  getNextTimes,
  getUTC,
  getNextEvent,
  getDateString,
  getTimeString,
  canEdit,
  getFutureDate,
  getCurrentMonthLastDate
}
