import React from "react"
import { BLACK } from "../../appConstants"



const PreparingDashboard: React.FC = () => {
    return (
        <div className="flex flex-col items-center" style={{ height: "calc(100vh - 72px)" }}>
            <img className="mt-10 mb-20" height={400} width={400} src="https://s3-alpha-sig.figma.com/img/82be/dbd5/2e3d47bc63f65e689ec6805021f14641?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Xhn9OAdfywKzR4TsPJYpMQ5KSlyouTV6fZN2yVTTQVJmofaOmaAfxCIeCMLJ6hiXHX4xS0W6~wNWQPLxJfIMMW7edk7LOhpaXJBwuXydc9fg0-Bro8YJldDiLqA2gzJ3eHFX9K0pJI0~572UStQXfNGib40sI4zeJwgR~xrYH84fSa0V3HiDeUGwfQWJ0GBYKrUy1CjL8tw1Sh66NfKpNu3dKiF27zoIaATIUE4ej7QyizdXFYxaWq9pNW2f7ZyLsR1bc9hsn3R0JBova7yZRrr~O0Dy1hqRb8rK-2urAMEvDkaKvLpAyAC5mRfmqe3oTUhIHvWhagCpYhLrX3UtYw__" />
            <div className="mb-6" style={{ fontSize: 32, fontWeight: 600, color: BLACK.B_100 }} >Dashboard Under Development</div>
            <div style={{fontSize:24,fontWeight:400, color:BLACK.B_40}} >Your dashboard is being prepared. Please come back after 48 hours.</div>
        </div>
    )
}

export default PreparingDashboard