import React, { useCallback, useEffect, useMemo, useState } from "react"
import { BLACK, PRIMARY, WHITE } from "../../appConstants"
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { getNextTimes } from "../../appFunctions";
import { useSelector } from "react-redux";



const BulkUpload: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedTab, setSelectedTab] = useState<string>("LATEST_FILES");
    const { brand_id: brandId } = useSelector((state: any) => state.appData);



    useEffect(() => {
        if (location.pathname === "/bulkUpload/allFiles") {
            setSelectedTab("ALL_FILES");
        }else if(location.pathname === "/bulkUpload/latestFiles"){
            setSelectedTab("LATEST_FILES");
        }
    }, [location.pathname]);
    const tabs = useMemo(() => {
        return [
            { id: "LATEST_FILES", name: "Today's Schedules", url: "/bulkUpload/latestFiles" },
            { id: "ALL_FILES", name: "All Schedules", url: "/bulkUpload/allFiles" }
        ]
    }, []);

    const onTabChange = (tab: string, url: string) => {
        setSelectedTab(tab);
        navigate(url);
    }

    return (
        <div style={{ height: "calc(100vh - 72px)" }} >
            <div className="flex flex-row  px-10" style={{ height: 52, boxShadow: "0px 4px 10px 4px rgba(99, 99, 99, 0.05)" }} >
                {
                    tabs.map(tab => {
                        return <div onClick={() => onTabChange(tab.id, tab.url)} key={tab.id} className="p-4 cursor-pointer" style={{ fontSize: 14, fontWeight: 500, color: selectedTab === tab.id ? PRIMARY.P_BLue : BLACK.B_40, borderBottom: selectedTab === tab.id ? `2px solid ${PRIMARY.P_BLue}` : "" }} >{tab.name}</div>
                    })
                }
            </div>
            <div className="pt-6 pb-13 px-10" style={{ height: "calc(100vh - 124px)" }} >
                <Outlet />
            </div>
        </div>
    )
}


export default BulkUpload