import ViewGroupKeyword from "./ViewGroupKeyword"

const GroupKeyword = () => {
    return (
        <div className="py-10 px-10" style={{ height: "calc(100vh - 72px)" }}>
            <ViewGroupKeyword />
        </div>
    )
}

export default GroupKeyword