import axios from "axios";
import Product from "./Product";
import { BASE_URL, BLACK, PRIMARY } from "../../appConstants";
import { UUID } from "crypto";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Keyword from "./Keyword";
import AdsTargeting from "./AdsTargeting";
import { Button } from "@mui/joy";
import toast from "react-hot-toast";
import { updateBrandData } from "../../redux/appDataSlice";
import appError from "../../appError";
import { useNavigate } from "react-router-dom";
import CampaignMaster from "./CampaignMaster";
import CrawledCampaign from "./CrawledCampaign";
import WarningModal from "./WarningModal";


type FileDetailObj = {
    file_id: UUID,
    file_type: string,
    file_name: string,
    created_time_stamp: string,
    updated_time_stamp: string
}
type BrandObject = {
    brand_id: string,
    brand_name: string,
    stage: string,
    onboarding_type: string
}

const ManualOnBoarding = () => {
    const { brand_id, stage } = useSelector((state: any) => state.appData);
    let brand_array: BrandObject[] = useSelector((state: any) => state.appData.brand_array);
    const [fileDetail, setFileDetail] = useState<Map<any, FileDetailObj | undefined>>(new Map());
    const [loadingUpdateBrandStage, setLoadingUpdateBrandStage] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [open, setOpen] = useState<boolean>(false);
    useEffect(() => {
        fetchFileDetail();
    }, []);

    const fetchFileDetail = async () => {
        try {
            //setLoadingBrands(true);
            const token = localStorage.getItem("token");
            const res = await axios.get(`${BASE_URL}onboarding/manual/manualOnBoardingDetail?brand_id=${brand_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const file_detail_array: FileDetailObj[] = res?.data?.data?.file_detail_array;
            let fileDetailMap = new Map<any, FileDetailObj>();
            if (file_detail_array.length) {
                for (let file of file_detail_array) {
                    const { file_type } = file;
                    fileDetailMap.set(file_type, file);
                }
            }
            setFileDetail(fileDetailMap);
        } catch (error) {
            //appError(error);
        } finally {
            //setLoadingBrands(false);
        }
    }


    const onUpdateBrandStage = async () => {
        try {
            const token = localStorage.getItem("token");
            setLoadingUpdateBrandStage(true);
            const res = await axios.post(`${BASE_URL}onboarding/updateBrandStage?brand_id=${brand_id}`,
                {
                    stage: "PREPARING_DASHBOARD"
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
            const { message, brand: { brand_id: brandId, stage, onboarding_type } } = res.data.data;
            //toast.success("Brand master details added successfully.");
            setSuccess(true);
            setTimeout(() => {

                brand_array = structuredClone(brand_array);

                brand_array.forEach(brand => {
                    if (brand.brand_id === brandId) {
                        brand.stage = stage;
                    }
                });
                dispatch(updateBrandData({ brand_id: brandId, stage, onboarding_type, brand_array }));
                setSuccess(true);
            }, 3000);
        } catch (error) {
            appError(error);
        } finally {
            setLoadingUpdateBrandStage(false);
        }
    }

    const onSaveAndProceedClick = () => {

        if (fileDetail.has("PRODUCT") && fileDetail.has("KEYWORD") && fileDetail.has("ADS_TARGETING") && fileDetail.has("CAMPAIGN_MASTER") && fileDetail.has("CRAWLED_CAMPAIGN")) {
            onUpdateBrandStage();
        } else {
            setOpen(true);
        }
    }

    return (
        <div className="p-10" style={{ height: "calc(100vh - 72px)", overflow: "auto" }} >

            {
                success ? (
                    <>
                        <div className="flex flex-col items-center" style={{ paddingTop: 200 }} >
                            <img src="https://s3-alpha-sig.figma.com/img/fd3a/6eee/584773a254d83d9061d45a8243696bea?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=H~YOeUkvE-Y4NdSBZBcdAZdAlRGnHpXQzlILqabIGG5ft2AAfsUUnVqoor6FzWEQRsS4tLIIJBVHLQDu3ca7f-Hu8I0~cujefaNhHlD4VdyIRctsLvC6Y5qnGgu16iqB-B9y94w8cBYwxgLathnPZDUOFdQev2~dh1hDY0ltvcSNZFKPf0juppA2Re5muNWacuNm5AdjJkX5dciNa8GA6iUZ7dxcDiVeq8jRx-6VlNk2wG2WpfNEQM~3PwbtsPHFtIfstP2HEqzzHFKiShUFIB3VAjyiu6obl5o21fMEbE0vhRy18TCTYJew556bVgy6jth5egW-aMoM2Jx3SMas8w__" height={200} width={100} />
                            <div className="mt-20" style={{ fontSize: 32, fontWeight: 600 }} >Upload Successful</div>
                            <div className="mt-6" style={{ fontSize: 24, fontWeight: 400, color: BLACK.B_40 }} >Documents uploaded successfully. Time to sit back and relax!</div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex flex-row justify-between items-center mb-10" style={{ height: 62 }} >
                            <div>
                                <div className="mb-2" style={{ fontSize: 24, fontWeight: 500 }} >Add Brand Master Details</div>
                                <div style={{ color: BLACK.B_40, fontSize: 18, fontWeight: 400 }}>Efficiently upload and manage brand data using Excel sheets and EXE files.</div>
                            </div>
                            <div>
                                <Button
                                    loading={loadingUpdateBrandStage}
                                    sx={{
                                        width: 200,
                                        height: 56,
                                        backgroundColor: PRIMARY.P_BLue,

                                        ":disabled": {
                                            backgroundColor: PRIMARY.P_Blue,
                                            color: BLACK.B_10
                                        }
                                    }}
                                    onClick={onSaveAndProceedClick}
                                >
                                    Save & Proceed
                                </Button>
                            </div>
                        </div>
                        {/* <div style={{ height: "calc(100vh - 254px)", overflow: "auto" }} >
            </div> */}
                        <div className="flex" >
                            <div className="mr-3 w-1/2">
                                <Product fetchFileDetail={fetchFileDetail} fileDetail={fileDetail.get("PRODUCT")} />
                            </div>
                            <div className="ml-3 w-1/2" >
                                <Keyword fetchFileDetail={fetchFileDetail} fileDetail={fileDetail.get("KEYWORD")} />
                            </div>
                        </div>
                        <div className="flex mt-6" >
                            <div className="mr-3 w-1/2">
                                <AdsTargeting fetchFileDetail={fetchFileDetail} fileDetail={fileDetail.get("ADS_TARGETING")} />
                            </div>
                            <div className="ml-3 w-1/2" >
                                <CampaignMaster fetchFileDetail={fetchFileDetail} fileDetail={fileDetail.get("CAMPAIGN_MASTER")} />
                            </div>
                        </div>
                        <div className="flex mt-6" >
                            <div className="mr-3 w-1/2">
                                <CrawledCampaign fetchFileDetail={fetchFileDetail} fileDetail={fileDetail.get("CRAWLED_CAMPAIGN")} />
                            </div>
                            <div className="ml-3 w-1/2" >
                            </div>
                        </div>
                    </>
                )
            }
            {open && <WarningModal setOpen={setOpen} onUpdateBrandStage={onUpdateBrandStage} fileDetail={fileDetail} />}
        </div>
    )
}

export default ManualOnBoarding;