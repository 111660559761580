import { Button, Modal } from "@mui/joy";
import { BASE_URL, BLACK, PRIMARY, STATUS } from "../../appConstants";
import React, { Dispatch, SetStateAction } from "react";
import { UUID } from "crypto";
import Joi from "joi";
import appError from "../../appError";
import axios from "axios";
import toast from "react-hot-toast";
type UserObj = {
    user_id: UUID
    first_name: string,
    email: string
}

type DeleteUserModalProps = {
    setOpenDelete: Dispatch<SetStateAction<boolean>>,
    deleteUser: UserObj | any,
    setDeleteUser: Dispatch<SetStateAction<any>>,
    fetchUsers:()=>void
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({ setOpenDelete, deleteUser, setDeleteUser,fetchUsers }) => {
    const first_name = deleteUser?.first_name;
    const user_id = deleteUser?.user_id;

    const onSaveClickHandler = async () => {
        const schema = Joi.object({
            user_id: Joi.string().required().guid({ version: "uuidv4" }),
        });

        const validate = schema.validate({ user_id });
        if (validate.error) {
            appError(validate?.error?.toString());
            return;
        }
        try {
            const token = localStorage.getItem("token");
            const { user_id } = validate?.value;
            const res = await axios.delete(`${BASE_URL}user/delete/${user_id}`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            );
            const { message } = res?.data?.data;
            fetchUsers();
            setOpenDelete(false)
            toast.success(message);
        } catch (error) {
            appError(error);
        } finally {

        }
    }

    return (
        <Modal
            open={true}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <div
                style={{ width: 450, height: 308 }}
                className="border rounded-lg px-12 py-12 bg-white m-12"
            >
                <div style={{ fontSize: 24, fontWeight: 500 }} className="text-center" >
                    Are you sure you want to delete this user?
                </div>
                <div style={{ fontSize: 18, fontWeight: 400, color: BLACK.B_40 }} className="mt-4 text-center" >
                    {first_name}
                </div>
                <div className="flex flex-row justify-between mt-16" >
                    <Button
                        sx={{
                            height: "48px",
                            width: "168px",
                            backgroundColor: BLACK.B_5,
                            color: BLACK.B_100,
                            ":hover": {
                                backgroundColor: BLACK.B_5,
                                color: BLACK.B_100,
                            },

                        }}
                        onClick={() => { setOpenDelete(false); setDeleteUser(null); }}
                    >Cancel</Button>
                    <Button
                        startDecorator={
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                <path d="M17.375 3.75H14.25V3.125C14.25 2.62772 14.0525 2.15081 13.7008 1.79917C13.3492 1.44754 12.8723 1.25 12.375 1.25H8.625C8.12772 1.25 7.65081 1.44754 7.29917 1.79917C6.94754 2.15081 6.75 2.62772 6.75 3.125V3.75H3.625C3.45924 3.75 3.30027 3.81585 3.18306 3.93306C3.06585 4.05027 3 4.20924 3 4.375C3 4.54076 3.06585 4.69973 3.18306 4.81694C3.30027 4.93415 3.45924 5 3.625 5H4.25V16.25C4.25 16.5815 4.3817 16.8995 4.61612 17.1339C4.85054 17.3683 5.16848 17.5 5.5 17.5H15.5C15.8315 17.5 16.1495 17.3683 16.3839 17.1339C16.6183 16.8995 16.75 16.5815 16.75 16.25V5H17.375C17.5408 5 17.6997 4.93415 17.8169 4.81694C17.9342 4.69973 18 4.54076 18 4.375C18 4.20924 17.9342 4.05027 17.8169 3.93306C17.6997 3.81585 17.5408 3.75 17.375 3.75ZM8 3.125C8 2.95924 8.06585 2.80027 8.18306 2.68306C8.30027 2.56585 8.45924 2.5 8.625 2.5H12.375C12.5408 2.5 12.6997 2.56585 12.8169 2.68306C12.9342 2.80027 13 2.95924 13 3.125V3.75H8V3.125ZM15.5 16.25H5.5V5H15.5V16.25ZM9.25 8.125V13.125C9.25 13.2908 9.18415 13.4497 9.06694 13.5669C8.94973 13.6842 8.79076 13.75 8.625 13.75C8.45924 13.75 8.30027 13.6842 8.18306 13.5669C8.06585 13.4497 8 13.2908 8 13.125V8.125C8 7.95924 8.06585 7.80027 8.18306 7.68306C8.30027 7.56585 8.45924 7.5 8.625 7.5C8.79076 7.5 8.94973 7.56585 9.06694 7.68306C9.18415 7.80027 9.25 7.95924 9.25 8.125ZM13 8.125V13.125C13 13.2908 12.9342 13.4497 12.8169 13.5669C12.6997 13.6842 12.5408 13.75 12.375 13.75C12.2092 13.75 12.0503 13.6842 11.9331 13.5669C11.8158 13.4497 11.75 13.2908 11.75 13.125V8.125C11.75 7.95924 11.8158 7.80027 11.9331 7.68306C12.0503 7.56585 12.2092 7.5 12.375 7.5C12.5408 7.5 12.6997 7.56585 12.8169 7.68306C12.9342 7.80027 13 7.95924 13 8.125Z" fill="white" />
                            </svg>

                        }
                        sx={{
                            height: "48px",
                            width: "168px",
                            backgroundColor: STATUS.S_Red,
                            ":hover": {
                                backgroundColor: STATUS.S_Red
                            }

                        }}
                        onClick={onSaveClickHandler}
                    >Delete</Button>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteUserModal;