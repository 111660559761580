import { Button } from "@mui/joy";
import { BASE_URL, BLACK } from "../../appConstants";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { UUID } from "crypto";
import axios from "axios";
import appError from "../../appError";
import { useSelector } from "react-redux";
import SubCategoriesComponent from "./SubCategoriesComponent";

type mainCategoryObject = {
    brand_main_category: string,
    brand_main_category_id: UUID
}


const ViewCategory = () => {
    const brandId = useSelector((state:any) => state.appData.brand_id);
    const naviagte = useNavigate();
    const [mainCategories, setMainCategories] = useState<mainCategoryObject[]>();



    useEffect(() => {
        fetchMainCategories();
    }, [brandId]);
    //Fetch Main Categories Function
    const fetchMainCategories = async () => {
        try {
            const token = localStorage.getItem("token");
       
            const res = await axios.get(`${BASE_URL}onboarding/mainCategories?brand_id=${brandId}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const { brand_main_category_array } = res?.data?.data;
            setMainCategories(brand_main_category_array);
        } catch (error) {
            appError(error);
        }finally{
            
        }
    }

    return (
        <div className="flex flex-col pt-8 px-10 h-full">
            {/* Header */}
            <div className="flex flex-row items-center mb-5" style={{ height: 62 }} >
                <div className="w-full" >
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Category</div>
                    <div style={{ fontSize: 18, fontWeight: 400, color: BLACK.B_40 }} >You can manually add your category here or either you can upload it from our structured excel format.</div>
                </div>

                <Button
                    variant="outlined"
                    sx={{
                        height: "56px",
                        width: "234px",
                        ":hover": { cursor: "pointer" }
                    }}
                    startDecorator={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.41489 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM10 16.875C8.64026 16.875 7.31105 16.4718 6.18046 15.7164C5.04987 14.9609 4.16868 13.8872 3.64833 12.6309C3.12798 11.3747 2.99183 9.99237 3.2571 8.65875C3.52238 7.32513 4.17716 6.10013 5.13864 5.13864C6.10013 4.17715 7.32514 3.52237 8.65876 3.2571C9.99238 2.99183 11.3747 3.12798 12.631 3.64833C13.8872 4.16868 14.9609 5.04987 15.7164 6.18045C16.4718 7.31104 16.875 8.64025 16.875 10C16.8729 11.8227 16.1479 13.5702 14.8591 14.8591C13.5702 16.1479 11.8227 16.8729 10 16.875ZM13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625H10.625V13.125C10.625 13.2908 10.5592 13.4497 10.4419 13.5669C10.3247 13.6842 10.1658 13.75 10 13.75C9.83424 13.75 9.67527 13.6842 9.55806 13.5669C9.44085 13.4497 9.375 13.2908 9.375 13.125V10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H9.375V6.875C9.375 6.70924 9.44085 6.55027 9.55806 6.43306C9.67527 6.31585 9.83424 6.25 10 6.25C10.1658 6.25 10.3247 6.31585 10.4419 6.43306C10.5592 6.55027 10.625 6.70924 10.625 6.875V9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10Z" fill="#1886FE" />
                    </svg>
                    }
                    onClick={() => naviagte("/onBoarding/category")}
                  // onClick={() => naviagte("/category")}
                >Add Category</Button>
            </div>
            {/* Container */}
            <div style={{ border: `1px solid ${BLACK.B_10}`, borderRadius: 8 }} className="flex flex-row mb-6 p-4 h-full w-full" >
                <div className="flex flex-col h-full mt-2.5 w-full">
                    <div style={{ height: 32, fontSize: 14, fontWeight: 400, color: BLACK.B_40 }} className="flex flex-row items-center w-full border-b" >
                        <div className="w-1/3">Main Category</div>
                        <div className="w-2/3" >Sub Category </div>
                    </div>
                    <div style={{ height: "calc(100vh - 420px)", overflowY: "scroll" }} className="mt-4">
                        {
                            mainCategories?.map(el => {
                                return (
                                    <div key={""} style={{ fontSize: 14, fontWeight: 400 }} className="flex flex-row justify-start w-full my-1" >

                                        <div className="w-1/3 pt-2 pb-2">
                                            {el.brand_main_category}
                                        </div>

                                        <div className="w-2/3" >
                                            <SubCategoriesComponent
                                               brand_main_category_id = {el.brand_main_category_id}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ViewCategory;