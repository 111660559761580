import React, { useEffect, useMemo, useRef, useState } from "react";
import { BLACK, PRIMARY, WHITE } from "../../appConstants";
import { Input } from "@mui/joy";

type HourAndMin = {
    hour: string;
    min: string;
}
type TimeSlotInputProps = {
    setHourAndMinHandler: (unit: string, value: string) => void,
    oneTimeHourAndMin: HourAndMin
}

const TimeSlotInput: React.FC<TimeSlotInputProps> = ({ setHourAndMinHandler, oneTimeHourAndMin }) => {

    const hourRef = useRef<HTMLDivElement | null>(null);
    const minRef = useRef<HTMLDivElement | null>(null);
    const [open, setOpen] = useState(false);



    const time = useMemo(() => [
        { id: 0, hour: "00" },
        { id: 1, hour: "01" },
        { id: 2, hour: "02" },
        { id: 3, hour: "03" },
        { id: 4, hour: "04" },
        { id: 5, hour: "05" },
        { id: 6, hour: "06" },
        { id: 7, hour: "07" },
        { id: 8, hour: "08" },
        { id: 9, hour: "09" },
        { id: 10, hour: "10" },
        { id: 11, hour: "11" },
        { id: 12, hour: "12" },
        { id: 13, hour: "13" },
        { id: 14, hour: "14" },
        { id: 15, hour: "15" },
        { id: 16, hour: "16" },
        { id: 17, hour: "17" },
        { id: 18, hour: "18" },
        { id: 19, hour: "19" },
        { id: 20, hour: "20" },
        { id: 21, hour: "21" },
        { id: 22, hour: "22" },
        { id: 23, hour: "23" },
        { id: 99, hour: "null" },
        { id: 100, hour: "null" },
        { id: 101, hour: "null" },
    ], []);

    const min = useMemo(() => [
        { id: 0, min: "00" },
        { id: 1, min: "15" },
        { id: 2, min: "30" },
        { id: 3, min: "45" },
        { id: 99, min: "null" },
        { id: 100, min: "null" },
        { id: 101, min: "null" }
    ], []);



    const onHourClick = (id: number, hour: string) => {
        if (hour === "null") {
            return;
        }
        if (hourRef.current) {
            // setOneTimeHourAndMin(prevState => ({
            //     ...prevState,
            //     hour
            // }));
            setHourAndMinHandler("hour", hour);
            hourRef.current.scrollBy({
                top: ((34 * id) - hourRef.current.scrollTop), // Adjust the scroll amount as needed
                behavior: 'smooth'
            });
            // let elById = document.getElementById(`hour-${hour}`) as HTMLDivElement | null;
            // let elByClass = document.getElementsByClassName("hour") as HTMLCollectionOf<HTMLDivElement>;
            // if (elById && elByClass.length) {
            //     for (let el of elByClass) {
            //         el.style.color = BLACK?.B_100
            //         el.style.fontWeight = "400";
            //     }

            //     elById.style.color = PRIMARY?.P_BLue;
            //     elById.style.fontWeight = "700";
            // }

        }

    }

    const onMinClick = (id: number, min: string) => {
        if (min === "null") {
            return;
        }
        if (minRef.current) {
            setHourAndMinHandler("min", min);
            minRef.current.scrollBy({
                top: ((34 * id) - minRef.current.scrollTop), // Adjust the scroll amount as needed
                behavior: 'smooth'
            });
            // let elById = document.getElementById(`min-${min}`) as HTMLDivElement | null;
            // let elByClass = document.getElementsByClassName("min") as HTMLCollectionOf<HTMLDivElement>;
            // if (elById && elByClass.length) {
            //     for (let el of elByClass) {
            //         el.style.color = BLACK?.B_100
            //         el.style.fontWeight = "400";
            //     }
            //     elById.style.color = PRIMARY?.P_BLue;
            //     elById.style.fontWeight = "700";
            // }
            // setOneTimeHourAndMin(prevState => ({
            //     ...prevState,
            //     min
            // }));
        }

    }


    return (
        <>
            <Input
                //placeholder="Add Scheduling Name"
                readOnly

                sx={{
                    "--Input-focusedThickness": "0px",
                    width: "347px",
                    height: "52px",
                    backgroundColor: WHITE.W_100,
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "20px",
                    cursor: "pointer",
                    borderRadius: "8px",
                    borderColor:BLACK.B_10

                }}
                // onClick={() => setOpen(prevState => !prevState)}
                //defaultValue={"Choose Slot"}
                value={`${oneTimeHourAndMin?.hour ? oneTimeHourAndMin?.hour : "--"} : ${oneTimeHourAndMin?.min ? oneTimeHourAndMin?.min : "--"}`}
                name="company_name"
                endDecorator={
                    <svg onClick={() => setOpen(prevState=>!prevState)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10.6249 9.74679V6.45825C10.6249 6.28117 10.565 6.1327 10.4451 6.01284C10.3253 5.89311 10.1768 5.83325 9.99971 5.83325C9.82249 5.83325 9.67408 5.89311 9.5545 6.01284C9.43478 6.1327 9.37492 6.28117 9.37492 6.45825V9.93908C9.37492 10.0371 9.39311 10.1321 9.4295 10.2241C9.46575 10.316 9.5229 10.401 9.60096 10.4791L12.4551 13.3333C12.5704 13.4487 12.7154 13.5077 12.8901 13.5103C13.0648 13.513 13.2126 13.4539 13.3333 13.3333C13.4539 13.2126 13.5143 13.0662 13.5143 12.8941C13.5143 12.7221 13.4539 12.5758 13.3333 12.4551L10.6249 9.74679ZM10.0014 17.9166C8.90638 17.9166 7.87714 17.7088 6.91367 17.2933C5.9502 16.8777 5.11214 16.3137 4.3995 15.6014C3.68686 14.889 3.12263 14.0513 2.70679 13.0883C2.2911 12.1252 2.08325 11.0962 2.08325 10.0014C2.08325 8.90638 2.29103 7.87714 2.70659 6.91367C3.12214 5.9502 3.6861 5.11214 4.39846 4.3995C5.11082 3.68686 5.94853 3.12263 6.91159 2.70679C7.87464 2.2911 8.9036 2.08325 9.99846 2.08325C11.0935 2.08325 12.1227 2.29103 13.0862 2.70658C14.0496 3.12214 14.8877 3.6861 15.6003 4.39846C16.313 5.11082 16.8772 5.94853 17.293 6.91159C17.7087 7.87464 17.9166 8.9036 17.9166 9.99846C17.9166 11.0935 17.7088 12.1227 17.2933 13.0862C16.8777 14.0496 16.3137 14.8877 15.6014 15.6003C14.889 16.313 14.0513 16.8772 13.0883 17.293C12.1252 17.7087 11.0962 17.9166 10.0014 17.9166ZM9.99992 16.6666C11.8471 16.6666 13.4201 16.0173 14.7187 14.7187C16.0173 13.4201 16.6666 11.8471 16.6666 9.99992C16.6666 8.1527 16.0173 6.57978 14.7187 5.28117C13.4201 3.98256 11.8471 3.33325 9.99992 3.33325C8.1527 3.33325 6.57978 3.98256 5.28117 5.28117C3.98256 6.57978 3.33325 8.1527 3.33325 9.99992C3.33325 11.8471 3.98256 13.4201 5.28117 14.7187C6.57978 16.0173 8.1527 16.6666 9.99992 16.6666Z" fill="#1C1C1C" fill-opacity="0.4" />
                    </svg>

                    // <>
                    //     {
                    //         open ? (
                    //             <svg onClick={() => setOpen(false)} className="cursor-pointer z-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    //                 <path d="M9.99984 9L6.74984 12.25C6.59706 12.4028 6.40262 12.4792 6.1665 12.4792C5.93039 12.4792 5.73595 12.4028 5.58317 12.25C5.43039 12.0972 5.354 11.9028 5.354 11.6667C5.354 11.4306 5.43039 11.2361 5.58317 11.0833L9.4165 7.25C9.58317 7.08333 9.77762 7 9.99984 7C10.2221 7 10.4165 7.08333 10.5832 7.25L14.4165 11.0833C14.5693 11.2361 14.6457 11.4306 14.6457 11.6667C14.6457 11.9028 14.5693 12.0972 14.4165 12.25C14.2637 12.4028 14.0693 12.4792 13.8332 12.4792C13.5971 12.4792 13.4026 12.4028 13.2498 12.25L9.99984 9Z" fill="#1C1C1C" fill-opacity="0.4" />
                    //             </svg>
                    //         ) : (
                    //             <svg onClick={() => setOpen(true)} className="cursor-pointer z-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    //                 <path d="M9.99984 12.4791C9.88873 12.4791 9.78456 12.4617 9.68734 12.427C9.59012 12.3923 9.49984 12.3333 9.4165 12.2499L5.58317 8.41659C5.43039 8.26381 5.354 8.06936 5.354 7.83325C5.354 7.59714 5.43039 7.4027 5.58317 7.24992C5.73595 7.09714 5.93039 7.02075 6.1665 7.02075C6.40262 7.02075 6.59706 7.09714 6.74984 7.24992L9.99984 10.4999L13.2498 7.24992C13.4026 7.09714 13.5971 7.02075 13.8332 7.02075C14.0693 7.02075 14.2637 7.09714 14.4165 7.24992C14.5693 7.4027 14.6457 7.59714 14.6457 7.83325C14.6457 8.06936 14.5693 8.26381 14.4165 8.41659L10.5832 12.2499C10.4998 12.3333 10.4096 12.3923 10.3123 12.427C10.2151 12.4617 10.1109 12.4791 9.99984 12.4791Z" fill="#1C1C1C" fill-opacity="0.4" />
                    //             </svg>
                    //         )

                    //     }
                    // </>

                }
            // onChange={onChangeHandler}
            />

            <div onMouseLeave={()=>setOpen(false)} className="flex justify-between py-2" style={{ display: open ? "" : "none", borderRadius: 8, height: 152, width: 347, marginTop: 2, position: "absolute", zIndex: 999, boxShadow: "0px 4px 10px 4px rgba(130, 130, 130, 0.10)", backgroundColor: WHITE.W_100 }} >
                <div ref={hourRef} className="hide-scrollbar" style={{ flex: 1, overflow: "scroll" }} >
                    {
                        time?.map(e => {
                            return <div id={`hour-${e?.hour}`} onClick={() => onHourClick(e?.id, e?.hour)} className="py-2 pl-6 hour" style={{ height: 34, fontSize: 14, fontWeight: oneTimeHourAndMin?.hour === e?.hour ? 700 : 400, lineHeight: "20px", color: oneTimeHourAndMin?.hour === e?.hour ? PRIMARY?.P_BLue : BLACK?.B_100 }} >{e?.hour != "null" ? e?.hour : ""}</div>
                        })
                    }
                </div>
                <div>
                    <div style={{ height: 34 }}>:</div>
                    <div style={{ height: 34 }}>:</div>
                    <div style={{ height: 34 }}>:</div>
                    <div style={{ height: 34 }}>:</div>
                </div>
                <div ref={minRef} className="hide-scrollbar" style={{ flex: 1, overflow: "scroll" }}  >
                    {
                        min?.map(e => {
                            return <div id={`min-${e?.min}`} onClick={() => onMinClick(e?.id, e?.min)} className="py-2 pr-6 flex justify-end  w-full min" style={{ height: 34, fontSize: 14, fontWeight: oneTimeHourAndMin?.min === e?.min ? 700 : 400, lineHeight: "20px", color: oneTimeHourAndMin?.min === e?.min ? PRIMARY?.P_BLue : BLACK?.B_100 }}  >{e?.min != "null" ? e?.min : ""}</div>
                        })
                    }
                </div>
            </div>


        </>
    )
}

export default TimeSlotInput;