import { Button, Checkbox, CircularProgress, Input, Modal, Option, Select, selectClasses } from "@mui/joy"
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import React, { Dispatch, SetStateAction, useEffect, useState, } from "react";
import { UUID } from "crypto";
import axios from "axios";

import { debounce } from "lodash";
import { useSelector } from "react-redux";
import Joi from "joi";
import appError from "../../appError";
import toast from "react-hot-toast";
type KeywordObj1 = {
    brand_keyword_id: string,
    brand_keyword: string,
    brand_keyword_type: string
}
type GroupKeywordObj = {
    brand_keyword_group_id: UUID,
    brand_keyword_group_name: string,
    keyword_array: KeywordObj1[]
}

type AddEditGroupKeywordModalProps = {
    toggleModule: (type: string, toggle: boolean) => void,
    fetchGroupKeywords: () => void,
    type: string,
    editGroup: GroupKeywordObj
}
type KeywordObj = {
    "brand_keyword_id": UUID,
    "brand_keyword": string,
    brand_keyword_type?: string,
    checked?: boolean

}

type KeywordTypeObj = {
    brand_keyword_type_id: UUID,
    brand_keyword_type: string
}

type stateObj = {
    brand_keyword_group_id?: UUID,
    brand_keyword_group_name: string
}
const AddEditGroupKeywordModal: React.FC<AddEditGroupKeywordModalProps> = ({ toggleModule, type, editGroup, fetchGroupKeywords }) => {
    const { brand_id: brandId } = useSelector((state: any) => state.appData);
    const [state, setState] = useState<stateObj>({
        brand_keyword_group_id: "" as UUID,
        brand_keyword_group_name: ""
    });
    const [searchFocus, setSearchFocus] = useState(false);
    const [selectedKeywordTypeId, setSelectedKeywordTypeId] = useState<UUID>("" as UUID);
    const [setSearchFileArray, setsetSearchFileArray] = useState("");
    const [searchProductArray, setSearchProductArray] = useState<KeywordObj[]>([]);
    const [keywordArray, setKeywordArray] = useState<KeywordObj[]>([])
    const [keywordTypesArray, setKeywordTypesArray] = useState<KeywordTypeObj[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    // const [selectedProductArray, setSelectedProductArray] = useState([]);
    const [selectedKeywordMap, setSelectedKeywordMap] = useState<Map<string, string>>(new Map());
    const [submitLoading, setSumbitLoading] = useState<boolean>(false)
    const onKeywordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // setSelected(false);
        // setSearchType("");
        if (e.target.value?.trimStart()) {
            setSearchFocus(true);
        } else {
            setSearchFocus(false);
        }
        setsetSearchFileArray(e.target.value?.trimStart());
    }


    const fetchProductSearchData = debounce(async () => {
        const token = localStorage.getItem("token");
        (async () => {
            try {
                let api = `${BASE_URL}brand/getSuggestKeywords?brand_id=${brandId}&brand_keyword=${setSearchFileArray}`;
                const headers = {
                    "Authorization": `Bearer ${token}`
                }
                const result = await axios.get(api, { headers });
                const { brand_keyword_array } = result?.data?.data;
                setSearchProductArray(brand_keyword_array);

            } catch {

            } finally {

            }
        })();
    }, 500)

    useEffect(() => {
        if (setSearchFileArray) {
            fetchProductSearchData.cancel();
            fetchProductSearchData();
        }
        return () => {
            fetchProductSearchData.cancel();
        }
    }, [setSearchFileArray]);

    useEffect(() => {
        getKeywordTypes();
        if (type === "edit") {
            const { brand_keyword_group_id, brand_keyword_group_name, keyword_array } = editGroup;
            setState({
                brand_keyword_group_name,
                brand_keyword_group_id
            });

            let map = new Map<string, string>()
            for (let el of keyword_array) {
                const { brand_keyword_id, brand_keyword } = el;
                map.set(brand_keyword_id, brand_keyword);
            }
            setSelectedKeywordMap(map);
        }
    }, [])
    useEffect(() => {
        getKeywords(setSearchFileArray);
    }, [selectedKeywordTypeId]);

    const getKeywords = async (setSearchFileArray: string) => {
        try {

            setLoading(true);
            const token = localStorage.getItem("token");
            let api = `${BASE_URL}onboarding/getBrandFilterKeywords?brand_id=${brandId}`;
            if (setSearchFileArray) {
                api += `&brand_keyword=${setSearchFileArray}`
            }
            if (selectedKeywordTypeId) {
                api += `&brand_keyword_type_id=${selectedKeywordTypeId}`
            }
            const headers = {
                "Authorization": `Bearer ${token}`
            }
            const result = await axios.get(api, { headers });
            const brand_keyword_array: KeywordObj[] = result?.data?.data?.brand_keyword_array;
            brand_keyword_array.forEach(el => {
                if (selectedKeywordMap.has(el?.brand_keyword_id)) {
                    el.checked = true;
                } else {
                    el.checked = false;
                }
            });
            setKeywordArray(brand_keyword_array);

        } catch (error) {
            appError(error);
        } finally {
            setLoading(false);
        }
    }


    const getKeywordTypes = async () => {
        try {
            const token = localStorage.getItem("token");
            let api = `${BASE_URL}onboarding/keywordTypes?brand_id=${brandId}`;
            const headers = {
                "Authorization": `Bearer ${token}`
            }
            const result = await axios.get(api, { headers });
            const { brand_keyword_type_array } = result?.data?.data;
            setKeywordTypesArray(brand_keyword_type_array);
        } catch {

        } finally {

        }
    }

    const selectKeyword = (keyword: KeywordObj) => {
        const { brand_keyword } = keyword;
        setsetSearchFileArray(brand_keyword);
        setSearchFocus(false);
        getKeywords(brand_keyword);
        // setTimeout(()=>{

        // },100)


    };

    const onInputChangeHandler = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({ ...prevState, [name]: value }))
    }

    // const removeProduct = (product) => {
    //     setSelectedProductArray(prevState => {
    //         let arr = [...prevState];
    //         let index = arr.indexOf(product);
    //         if (index > -1) {
    //            
    //             arr.splice(index, 1);
    //         }
    //         return arr;
    //     });
    // }

    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e?.key === "Enter") {
            setSearchFocus(false);
            getKeywords(setSearchFileArray)
        }
    }

    const onSelectKeywordTypeHandler = (e: any, value: any) => {
        setSelectedKeywordTypeId(value);
    }


    const onDeleteKeyword = (id: UUID) => {
        setSelectedKeywordMap(prevState => {
            let map = new Map(prevState);
            map.delete(id);
            return map;
        });
        setKeywordArray(prevState => {
            let arr = structuredClone(prevState);
            for (let el of arr) {
                if (el.brand_keyword_id === id) {
                    el.checked = false;
                    break;
                }
            }
            return arr;
        });

    };


    const onAllCheckBoxClickHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        let map = new Map<string, string>()

        setKeywordArray(prevState => {
            let arr = structuredClone(prevState);
            for (let el of arr) {
                const { brand_keyword, brand_keyword_id } = el;
                el.checked = checked;
                map.set(brand_keyword_id, brand_keyword);
            }
            return arr;
        });

        if (checked) {
            setSelectedKeywordMap(map);
        } else {
            setSelectedKeywordMap(new Map())
        }


    }
    const onCheckBoxClickHandler = (event: React.ChangeEvent<HTMLInputElement>, keywordObj: KeywordObj) => {

        const { checked } = event.target;
        const { brand_keyword_id, brand_keyword } = keywordObj;
        if (checked) {
            setSelectedKeywordMap(prevState => {
                let map = new Map(prevState);
                map.set(brand_keyword_id, brand_keyword);
                return map;
            });
        } else {
            setSelectedKeywordMap(prevState => {
                let map = new Map(prevState);
                map.delete(brand_keyword_id);
                return map;
            });
        }

        setKeywordArray(prevState => {
            let arr = structuredClone(prevState);
            for (let el of arr) {
                if (el.brand_keyword_id === brand_keyword_id) {
                    el.checked = checked;
                    break;
                }
            }
            return arr;
        });

        // setChecked([event.target.checked, event.target.checked]);
    };


    const onSaveClickHandler = async () => {

        if (type === "edit") {

            let brand_keyword_uuid_array: UUID[] = []
            for (let [key, value] of Array.from(selectedKeywordMap)) {
                brand_keyword_uuid_array.push(key as UUID);
            }

            if (!brand_keyword_uuid_array.length) {
                appError("Please select minimum 1 keyword.");
                return;
            }

            const schema = Joi.object({
                keyword_group_name: Joi.string().required().min(1),
                brand_keyword_uuid_array: Joi.array().items(Joi.string().guid({ version: "uuidv4" })).min(1)
            });
            const { brand_keyword_group_name, brand_keyword_group_id } = state;
            const validate = schema.validate({ keyword_group_name: brand_keyword_group_name, brand_keyword_uuid_array });
            if (validate.error) {
                appError(validate?.error?.toString());
                return;
            }

            try {
                // setLoading(true);
                setSumbitLoading(true);
                const token = localStorage.getItem("token");
                const res = await axios.put(`${BASE_URL}brand/groupKeyword/${brand_keyword_group_id}?brand_id=${brandId}`,
                    validate?.value,
                    {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    }
                );
                const { message } = res?.data?.data;
                toast.success(message);
                fetchGroupKeywords();
                toggleModule("close", false);

            } catch (error) {
                appError(error);
            } finally {
                setSumbitLoading(false);
                // setLoading(false);
            }

        } else {


            let brand_keyword_uuid_array: UUID[] = []
            for (let [key, value] of Array.from(selectedKeywordMap)) {
                brand_keyword_uuid_array.push(key as UUID);
            }

            if (!brand_keyword_uuid_array.length) {
                appError("Please select minimum 1 keyword.");
                return;
            }

            const schema = Joi.object({
                keyword_group_name: Joi.string().required().min(1),
                brand_keyword_uuid_array: Joi.array().items(Joi.string().guid({ version: "uuidv4" })).min(1)
            });
            const { brand_keyword_group_name } = state;
            const validate = schema.validate({ keyword_group_name: brand_keyword_group_name, brand_keyword_uuid_array });
            if (validate.error) {
                appError(validate?.error?.toString());
                return;
            }

            try {
                // setLoading(true);
                setSumbitLoading(true);
                const token = localStorage.getItem("token");
                const res = await axios.post(`${BASE_URL}brand/groupKeyword?brand_id=${brandId}`,
                    validate?.value,
                    {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    }
                );
                const { message } = res?.data?.data;
                toast.success(message);
                fetchGroupKeywords();
                toggleModule("close", false);

            } catch (error) {
                appError(error);
            } finally {
                setSumbitLoading(false);
                // setLoading(false);
            }
        }

    }


    return (
        <Modal
            open={true}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            className="h-full"
        >
            <div
                style={{ height: 738, width: 1200 }}
                className="flex flex-col border rounded-lg px-10 py-10 bg-white"
            >

                <div style={{ height: 570 }}>
                    <div className="flex flex-col justify-between" style={{ height: 46 }} >
                        <div style={{ fontSize: 16, fontWeight: 600, lineHeight: "24px" }} >Add Keywords to Your Group</div>
                        <div style={{ fontSize: 12, fontWeight: 400, lineHeight: "18px", color: BLACK.B_40 }} >Select and add keywords to keep your group organised</div>
                    </div>
                    <div className="flex mt-6" style={{ height: 500 }} >
                        <div style={{ width: 682, borderRight: `1px solid ${BLACK.B_5}` }} >
                            <Input
                                onChange={onInputChangeHandler}
                                value={state.brand_keyword_group_name}
                                placeholder="Favourite Group Name"
                                sx={{
                                    "--Input-focusedThickness": "0px",
                                    width: "658px",
                                    height: "52px",
                                    backgroundColor: WHITE.W_100,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    boxShadow: "none",
                                    marginBottom: "24px",
                                    border: `1px solid ${BLACK.B_10}`
                                }}
                                name="brand_keyword_group_name"
                            // onChange={onChangeHandler}
                            />
                            <div className="flex" >
                                {/* <Input
                                    startDecorator={
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M17.9421 17.0578L14.0304 13.1469C15.1642 11.7857 15.7295 10.0398 15.6089 8.27244C15.4882 6.50506 14.6908 4.85223 13.3825 3.65779C12.0743 2.46334 10.3559 1.81926 8.58486 1.85951C6.81382 1.89976 5.12647 2.62125 3.87383 3.87389C2.62118 5.12653 1.89969 6.81388 1.85944 8.58492C1.8192 10.356 2.46328 12.0744 3.65772 13.3826C4.85217 14.6909 6.50499 15.4883 8.27238 15.6089C10.0398 15.7296 11.7856 15.1642 13.1468 14.0305L17.0577 17.9422C17.1158 18.0003 17.1848 18.0463 17.2606 18.0777C17.3365 18.1092 17.4178 18.1253 17.4999 18.1253C17.5821 18.1253 17.6634 18.1092 17.7392 18.0777C17.8151 18.0463 17.8841 18.0003 17.9421 17.9422C18.0002 17.8841 18.0463 17.8152 18.0777 17.7393C18.1091 17.6634 18.1253 17.5821 18.1253 17.5C18.1253 17.4179 18.1091 17.3366 18.0777 17.2607C18.0463 17.1848 18.0002 17.1159 17.9421 17.0578ZM3.12493 8.75C3.12493 7.63748 3.45483 6.54994 4.07292 5.62491C4.691 4.69989 5.56951 3.97892 6.59734 3.55317C7.62517 3.12743 8.75617 3.01604 9.84732 3.23308C10.9385 3.45012 11.9407 3.98585 12.7274 4.77252C13.5141 5.55919 14.0498 6.56147 14.2669 7.65261C14.4839 8.74376 14.3725 9.87475 13.9468 10.9026C13.521 11.9304 12.8 12.8089 11.875 13.427C10.95 14.0451 9.86245 14.375 8.74993 14.375C7.2586 14.3733 5.82882 13.7802 4.77429 12.7256C3.71975 11.6711 3.12659 10.2413 3.12493 8.75Z" fill="#1C1C1C" fill-opacity="0.4" />
                                        </svg>
                                    }
                                    placeholder="Favourite Group Name"
                                    sx={{
                                        "--Input-focusedThickness": "0px",
                                        width: "320px",
                                        height: "40px",
                                        backgroundColor: WHITE.W_100,
                                        fontSize: 14,
                                        fontWeight: 400,
                                        lineHeight: "20px",
                                        boxShadow: "none",
                                        // marginBottom: "24px"
                                    }}
                                    name="company_name"
                                // onChange={onChangeHandler}
                                /> */}
                                <div onClick={(e) => e.stopPropagation()} style={{ zIndex: 99, display: "flex", position: "relative", flexDirection: "row", alignItems: "center" }} >
                                    <Input
                                        onChange={onKeywordInputChange}
                                        value={setSearchFileArray}
                                        onKeyDown={onKeyDownHandler}
                                        placeholder="Search"
                                        // onFocus={() => setSearchFocus(true)}
                                        // onBlur={() => setSearchFocus(false)}
                                        startDecorator={
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M17.9421 17.0578L14.0304 13.1469C15.1642 11.7857 15.7295 10.0398 15.6089 8.27244C15.4882 6.50506 14.6908 4.85223 13.3825 3.65779C12.0743 2.46334 10.3559 1.81926 8.58486 1.85951C6.81382 1.89976 5.12647 2.62125 3.87383 3.87389C2.62118 5.12653 1.89969 6.81388 1.85944 8.58492C1.8192 10.356 2.46328 12.0744 3.65772 13.3826C4.85217 14.6909 6.50499 15.4883 8.27238 15.6089C10.0398 15.7296 11.7856 15.1642 13.1468 14.0305L17.0577 17.9422C17.1158 18.0003 17.1848 18.0463 17.2606 18.0777C17.3365 18.1092 17.4178 18.1253 17.4999 18.1253C17.5821 18.1253 17.6634 18.1092 17.7392 18.0777C17.8151 18.0463 17.8841 18.0003 17.9421 17.9422C18.0002 17.8841 18.0463 17.8152 18.0777 17.7393C18.1091 17.6634 18.1253 17.5821 18.1253 17.5C18.1253 17.4179 18.1091 17.3366 18.0777 17.2607C18.0463 17.1848 18.0002 17.1159 17.9421 17.0578ZM3.12493 8.75C3.12493 7.63748 3.45483 6.54994 4.07292 5.62491C4.691 4.69989 5.56951 3.97892 6.59734 3.55317C7.62517 3.12743 8.75617 3.01604 9.84732 3.23308C10.9385 3.45012 11.9407 3.98585 12.7274 4.77252C13.5141 5.55919 14.0498 6.56147 14.2669 7.65261C14.4839 8.74376 14.3725 9.87475 13.9468 10.9026C13.521 11.9304 12.8 12.8089 11.875 13.427C10.95 14.0451 9.86245 14.375 8.74993 14.375C7.2586 14.3733 5.82882 13.7802 4.77429 12.7256C3.71975 11.6711 3.12659 10.2413 3.12493 8.75Z" fill="#1C1C1C" fill-opacity="0.4" />
                                            </svg>
                                        }
                                        sx={{
                                            width: "320px",
                                            height: "40px",
                                            "--Input-focusedThickness": "0px",
                                            // '&:focus-within': {
                                            //     borderBottom: "0px",
                                            //     borderBottomRightRadius: "0px",
                                            //     borderBottomLeftRadius: "0px",
                                            //     boxShadow: "none"
                                            // },
                                            borderBottom: searchFocus ? "0px" : `1px solid ${BLACK.B_10}`,
                                            borderTop: `1px solid ${BLACK.B_10}`,
                                            borderLeft: `1px solid ${BLACK.B_10}`,
                                            borderRight: `1px solid ${BLACK.B_10}`,
                                            borderBottomRightRadius: searchFocus ? "0px" : "6px",
                                            borderBottomLeftRadius: searchFocus ? "0px" : "6px",
                                            backgroundColor: WHITE.W_100,
                                            fontSize: 14,
                                            fontWeight: 400,
                                            lineHeight: "20px",
                                            boxShadow: "none",
                                        }}
                                    />
                                    {
                                        searchFocus &&
                                        (
                                            <div style={{
                                                position: "absolute",
                                                top: 40,
                                                left: 0,
                                                width: 320,
                                                borderBottomRightRadius: "6px",
                                                borderBottomLeftRadius: "6px",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                borderBottom: `1px solid ${BLACK.B_10}`,
                                                borderLeft: `1px solid ${BLACK.B_10}`,
                                                borderRight: `1px solid ${BLACK.B_10}`,
                                                backgroundColor: WHITE.W_100
                                            }}>
                                                <div style={{ width: 302, flex: 1, paddingTop: 16, borderTop: `1px solid ${BLACK.B_10}`, maxHeight: 200, overflow: "auto" }} >
                                                    {
                                                        searchProductArray?.map(keyword => {
                                                            return (
                                                                <div
                                                                    key={keyword?.brand_keyword_id}
                                                                    onClick={() => selectKeyword(keyword)}
                                                                    style={{ cursor: "pointer", marginBottom: 16, display: "flex", flexDirection: "column", justifyContent: "space-between", height: 26 }} >
                                                                    <div style={{ height: 20, fontSize: 14, fontWeight: 400, color: BLACK.B_100 }} >
                                                                        {keyword?.brand_keyword}
                                                                        {/* {
                                                                            // selectedProductArray.includes(product?.product_name) && 
                                                                            (
                                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g id="Iconography">
                                                                                        <path id="Vector" d="M14.354 4.85354L6.35403 12.8535C6.30759 12.9 6.25245 12.9369 6.19175 12.9621C6.13105 12.9872 6.06599 13.0002 6.00028 13.0002C5.93457 13.0002 5.86951 12.9872 5.80881 12.9621C5.74811 12.9369 5.69296 12.9 5.64653 12.8535L2.14653 9.35354C2.05271 9.25972 2 9.13247 2 8.99979C2 8.86711 2.05271 8.73986 2.14653 8.64604C2.24035 8.55222 2.3676 8.49951 2.50028 8.49951C2.63296 8.49951 2.76021 8.55222 2.85403 8.64604L6.00028 11.7929L13.6465 4.14604C13.7403 4.05222 13.8676 3.99951 14.0003 3.99951C14.133 3.99951 14.2602 4.05222 14.354 4.14604C14.4478 4.23986 14.5006 4.36711 14.5006 4.49979C14.5006 4.63247 14.4478 4.75972 14.354 4.85354Z" fill="#1886FE" />
                                                                                    </g>
                                                                                </svg>
                                                                            )
                                                                        } */}
                                                                    </div>
                                                                    {/* <div style={{ height: 12, fontWeight: 500, fontSize: 10, color: BLACK.B_40 }} >
                                                                {product?.main_cat}
                                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g id="Iconography">
                                                                        <path id="Vector" d="M8.51552 6.26552L4.76552 10.0155C4.73068 10.0504 4.68932 10.078 4.64379 10.0969C4.59827 10.1157 4.54948 10.1254 4.50021 10.1254C4.45094 10.1254 4.40214 10.1157 4.35662 10.0969C4.3111 10.078 4.26974 10.0504 4.2349 10.0155C4.20005 9.98068 4.17242 9.93932 4.15356 9.8938C4.1347 9.84827 4.125 9.79948 4.125 9.75021C4.125 9.70094 4.1347 9.65214 4.15356 9.60662C4.17242 9.5611 4.20005 9.51974 4.2349 9.4849L7.72005 6.00021L4.2349 2.51552C4.16453 2.44516 4.125 2.34972 4.125 2.25021C4.125 2.1507 4.16453 2.05526 4.2349 1.9849C4.30526 1.91453 4.4007 1.875 4.50021 1.875C4.59972 1.875 4.69516 1.91453 4.76552 1.9849L8.51552 5.7349C8.55039 5.76972 8.57805 5.81108 8.59692 5.85661C8.61579 5.90213 8.6255 5.95093 8.6255 6.00021C8.6255 6.04949 8.61579 6.09829 8.59692 6.14381C8.57805 6.18934 8.55039 6.23069 8.51552 6.26552Z" fill="#1C1C1C" fill-opacity="0.4" />
                                                                    </g>
                                                                </svg>
                                                                {product?.sub_cat}
                                                            </div> */}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>


                                <Select
                                    value={selectedKeywordTypeId}
                                    onChange={onSelectKeywordTypeHandler}
                                    placeholder="Select Keyword Type"
                                    indicator={
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                            <path d="M10.0626 12.0625L7.04175 9.04165C7.00008 8.99998 6.96883 8.95484 6.948 8.90623C6.92716 8.85762 6.91675 8.80554 6.91675 8.74998C6.91675 8.63887 6.95494 8.54165 7.03133 8.45831C7.10772 8.37498 7.20841 8.33331 7.33341 8.33331H13.6667C13.7917 8.33331 13.8924 8.37498 13.9688 8.45831C14.0452 8.54165 14.0834 8.63887 14.0834 8.74998C14.0834 8.77776 14.0417 8.87498 13.9584 9.04165L10.9376 12.0625C10.8681 12.1319 10.7987 12.1805 10.7292 12.2083C10.6598 12.2361 10.5834 12.25 10.5001 12.25C10.4167 12.25 10.3404 12.2361 10.2709 12.2083C10.2015 12.1805 10.132 12.1319 10.0626 12.0625Z" fill="#1C1C1C" />
                                        </svg>
                                    }

                                    sx={{
                                        height: "40px",
                                        marginLeft: "16px",
                                        minWidth: 120,
                                        border: `1px solid ${BLACK.B_10}`,
                                        // color: "#1886FE",
                                        borderRadius: 8, //borderColor: ,
                                        [`& .${selectClasses.indicator}`]: {
                                            transition: '0.2s',
                                            [`&.${selectClasses.expanded}`]: {
                                                transform: 'rotate(-180deg)',
                                            },
                                        },
                                    }}
                                >
                                    {
                                        keywordTypesArray?.map(keyword => {
                                            return <Option value={keyword?.brand_keyword_type_id}  >{keyword?.brand_keyword_type}</Option>
                                        })
                                    }

                                </Select>
                            </div>
                            <div className="mt-4 mr-6" style={{ height: 368, borderRadius: 12, boxShadow: "0px 4px 10px 4px rgba(130, 130, 130, 0.05" }} >
                                <div className="flex items-center w-full" style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12, height: 56, fontSize: 14, fontWeight: 700, lineHeight: "20px", backgroundColor: PRIMARY.P_Purple50 }}  >
                                    <div className="p-4" style={{ width: "6%" }} ><Checkbox onChange={onAllCheckBoxClickHandler} size="sm" /></div>
                                    <div className="p-4" style={{ width: "74%" }}>Keyword</div>
                                    <div className="p-4" style={{ width: "20%" }} >Keyword Type</div>
                                </div>
                                <div style={{ height: 312 }} className="overflow-y-auto" >
                                    {
                                        !loading && keywordArray.length > 0 && keywordArray?.map((keywordObj, index) => {
                                            return (
                                                <div key={keywordObj?.brand_keyword_id} className="flex items-center w-full" style={{ height: 52, fontSize: 12, fontWeight: 400, lineHeight: "18px", borderBottom: `1px solid ${BLACK.B_5}` }}  >
                                                    <div className="p-4" style={{ width: "6%" }} ><Checkbox onChange={(e) => onCheckBoxClickHandler(e, keywordObj)} checked={keywordObj?.checked} size="sm" /></div>
                                                    <div className="p-4" style={{ width: "74%" }}>{keywordObj?.brand_keyword}</div>
                                                    <div className="p-4" style={{ width: "20%" }} >{keywordObj?.brand_keyword_type}</div>
                                                </div>
                                            )
                                        })
                                    }
                                    {

                                        loading && <div className="flex justify-center items-center h-full" > <CircularProgress size="sm" /></div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="pl-6" style={{ width: 434 }}>
                            <div className='flex items-center' style={{ height: 20 }}   >
                                <div style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px" }} >
                                    Selected Keywords
                                </div>

                                <div className='flex justify-center items-center ml-2' style={{ color: WHITE.W_100, height: 20, width: 28, backgroundColor: PRIMARY.P_BLue, fontSize: 10, fontWeight: 700, borderRadius: 4 }} >
                                    {selectedKeywordMap.size}
                                </div>
                            </div>
                            <div className="mt-4" style={{ height: 460 }} >
                                {
                                    Array.from(selectedKeywordMap.entries()).map(([key, value]) => {
                                        return (
                                            <div key={value} className="flex justify-between  px-6 py-4 mb-4" style={{ height: 52, borderRadius: 8, border: `1px solid ${BLACK.B_5}` }}>
                                                <div style={{ width: 330, fontSize: 14, fontWeight: 500, lineHeight: "20px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }} >{value}</div>

                                                <div onClick={() => onDeleteKeyword(key as UUID)} className="cursor-pointer" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M16.875 3.75H13.75V3.125C13.75 2.62772 13.5525 2.15081 13.2008 1.79917C12.8492 1.44754 12.3723 1.25 11.875 1.25H8.125C7.62772 1.25 7.15081 1.44754 6.79917 1.79917C6.44754 2.15081 6.25 2.62772 6.25 3.125V3.75H3.125C2.95924 3.75 2.80027 3.81585 2.68306 3.93306C2.56585 4.05027 2.5 4.20924 2.5 4.375C2.5 4.54076 2.56585 4.69973 2.68306 4.81694C2.80027 4.93415 2.95924 5 3.125 5H3.75V16.25C3.75 16.5815 3.8817 16.8995 4.11612 17.1339C4.35054 17.3683 4.66848 17.5 5 17.5H15C15.3315 17.5 15.6495 17.3683 15.8839 17.1339C16.1183 16.8995 16.25 16.5815 16.25 16.25V5H16.875C17.0408 5 17.1997 4.93415 17.3169 4.81694C17.4342 4.69973 17.5 4.54076 17.5 4.375C17.5 4.20924 17.4342 4.05027 17.3169 3.93306C17.1997 3.81585 17.0408 3.75 16.875 3.75ZM7.5 3.125C7.5 2.95924 7.56585 2.80027 7.68306 2.68306C7.80027 2.56585 7.95924 2.5 8.125 2.5H11.875C12.0408 2.5 12.1997 2.56585 12.3169 2.68306C12.4342 2.80027 12.5 2.95924 12.5 3.125V3.75H7.5V3.125ZM15 16.25H5V5H15V16.25ZM8.75 8.125V13.125C8.75 13.2908 8.68415 13.4497 8.56694 13.5669C8.44973 13.6842 8.29076 13.75 8.125 13.75C7.95924 13.75 7.80027 13.6842 7.68306 13.5669C7.56585 13.4497 7.5 13.2908 7.5 13.125V8.125C7.5 7.95924 7.56585 7.80027 7.68306 7.68306C7.80027 7.56585 7.95924 7.5 8.125 7.5C8.29076 7.5 8.44973 7.56585 8.56694 7.68306C8.68415 7.80027 8.75 7.95924 8.75 8.125ZM12.5 8.125V13.125C12.5 13.2908 12.4342 13.4497 12.3169 13.5669C12.1997 13.6842 12.0408 13.75 11.875 13.75C11.7092 13.75 11.5503 13.6842 11.4331 13.5669C11.3158 13.4497 11.25 13.2908 11.25 13.125V8.125C11.25 7.95924 11.3158 7.80027 11.4331 7.68306C11.5503 7.56585 11.7092 7.5 11.875 7.5C12.0408 7.5 12.1997 7.56585 12.3169 7.68306C12.4342 7.80027 12.5 7.95924 12.5 8.125Z" fill="#D72C0D" />
                                                    </svg>
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>





                <div className="flex flex-row justify-between mt-4">
                    <Button color="neutral"
                        sx={{
                            fontSize: 18,
                            fontWeight: 500,
                            height: "48px",
                            width: "552px",
                            backgroundColor: BLACK.B_5,
                            color: BLACK.B_100,
                            ":hover": {
                                backgroundColor: BLACK.B_20,
                            }
                        }}
                        onClick={() => toggleModule("close", false)}
                    >Cancel</Button>
                    <Button
                        onClick={onSaveClickHandler}
                        // endDecorator={
                        //     <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        //         <path d="M17.8172 10.4425L12.1922 16.0675C12.0749 16.1848 11.9159 16.2507 11.75 16.2507C11.5841 16.2507 11.4251 16.1848 11.3078 16.0675C11.1905 15.9503 11.1247 15.7912 11.1247 15.6253C11.1247 15.4595 11.1905 15.3004 11.3078 15.1832L15.8664 10.6253H3.625C3.45924 10.6253 3.30027 10.5595 3.18306 10.4423C3.06585 10.3251 3 10.1661 3 10.0003C3 9.83459 3.06585 9.67562 3.18306 9.55841C3.30027 9.4412 3.45924 9.37535 3.625 9.37535H15.8664L11.3078 4.81753C11.1905 4.70026 11.1247 4.5412 11.1247 4.37535C11.1247 4.2095 11.1905 4.05044 11.3078 3.93316C11.4251 3.81588 11.5841 3.75 11.75 3.75C11.9159 3.75 12.0749 3.81588 12.1922 3.93316L17.8172 9.55816C17.8753 9.61621 17.9214 9.68514 17.9529 9.76101C17.9843 9.83688 18.0005 9.91821 18.0005 10.0003C18.0005 10.0825 17.9843 10.1638 17.9529 10.2397C17.9214 10.3156 17.8753 10.3845 17.8172 10.4425Z" fill="white" />
                        //     </svg>
                        // }
                        sx={{
                            fontSize: 18,
                            fontWeight: 500,
                            height: "48px",
                            width: "552px",
                            backgroundColor: PRIMARY.P_BLue,
                        }}
                        //onClick={onProceedClick}
                        loading={submitLoading}
                    >Save</Button>
                </div>
            </div>
        </Modal>
    )
}

export default AddEditGroupKeywordModal;