import { Button, CircularProgress, Input } from "@mui/joy";
import AddNewUserModal from "./AddNewUserModal";
import { useEffect, useState } from "react";
import appError from "../../appError";
import axios from "axios";
import { BASE_URL, BLACK, PRIMARY } from "../../appConstants";
import { UUID } from "crypto";
import Brands from "./Brands";
import AddNewBrandModal from "./AddNewBrandModal";
import DashboardUrlModal from "./DashboardUrlModal";
import AddNewDashboardTypeModal from "./AddNewDashboardTypeModal";

const BrandManagement = () => {
    type brandObj = {
        brand_id: UUID
        brand_name: string,
        company_name: string
    }
    const [brandArray, setBrandArray] = useState<brandObj[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<brandObj>();
    const [open, setOpen] = useState<boolean>(false);
    const [openAddDashboardTypePopUp, setOpenAddDashboardTypePopUp] = useState<boolean>(false);

    const [openAddDashboardPopUp, setOpenAddDashboardPopUp] = useState<boolean>(false);
    const [loader, setloader] = useState(false);

    useEffect(() => {
        fetchBrands();
    }, [open])


    const fetchBrands = async () => {
        try {
            setloader(true);
            const token = localStorage.getItem("token");
            const brandsRes = await axios.get(`${BASE_URL}brand/getBrands`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const { brands } = brandsRes?.data?.data;
            setBrandArray(brands);
        } catch (error) {
            appError(error);
        } finally {
            setloader(false);
        }
    }


    const onEditBrandClickHandler = (brand: brandObj) => {
        setSelectedBrand(brand);
        setOpenAddDashboardPopUp(true);
        // setOpen(false);
        // setOpenEdit(true);
        // setEditUser(brand);
        //setOpenDelete(false);
    }
    function bringUserToFront(brands: brandObj[], searchFirstNameSubstring: string): brandObj[] {
        const lowerCaseSearchSubstring = searchFirstNameSubstring.toLowerCase();
        const index = brands.findIndex((brand: brandObj) =>
            brand.brand_name.toLowerCase().includes(lowerCaseSearchSubstring)
        );
        if (index !== -1) {
            const [brand] = brands.splice(index, 1);
            brands.unshift(brand);
        }
        return [...brands];
    }


    const searchBrand = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
        if (!Boolean(value)) {
            return;
        }
       
        setBrandArray(prevState => {
            const updatedUsers = bringUserToFront(prevState, value);
            return updatedUsers;
        })
    }
    return (
        <div className="flex flex-col h-full px-10 py-8" >
            {/* Header */}
            <div style={{ height: 72 }} className="flex flex-row justify-between items-center"  >
                <div style={{ height: 72 }} className="flex flex-col justify-between" >
                    <h1 style={{ fontSize: 24, fontWeight: 500, color: BLACK.B_100 }} >Brand Management</h1>
                    <p style={{ fontSize: 18, fontWeight: 400, color: BLACK.B_40 }} className="text-base">You can manage your Brands here. Add, edit or remove.</p>
                </div>
                <div>
                    <Button
                        startDecorator={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.41489 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM10 16.875C8.64026 16.875 7.31105 16.4718 6.18046 15.7164C5.04987 14.9609 4.16868 13.8872 3.64833 12.6309C3.12798 11.3747 2.99183 9.99237 3.2571 8.65875C3.52238 7.32513 4.17716 6.10013 5.13864 5.13864C6.10013 4.17715 7.32514 3.52237 8.65876 3.2571C9.99238 2.99183 11.3747 3.12798 12.631 3.64833C13.8872 4.16868 14.9609 5.04987 15.7164 6.18045C16.4718 7.31104 16.875 8.64025 16.875 10C16.8729 11.8227 16.1479 13.5702 14.8591 14.8591C13.5702 16.1479 11.8227 16.8729 10 16.875ZM13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625H10.625V13.125C10.625 13.2908 10.5592 13.4497 10.4419 13.5669C10.3247 13.6842 10.1658 13.75 10 13.75C9.83424 13.75 9.67527 13.6842 9.55806 13.5669C9.44085 13.4497 9.375 13.2908 9.375 13.125V10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H9.375V6.875C9.375 6.70924 9.44085 6.55027 9.55806 6.43306C9.67527 6.31585 9.83424 6.25 10 6.25C10.1658 6.25 10.3247 6.31585 10.4419 6.43306C10.5592 6.55027 10.625 6.70924 10.625 6.875V9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10Z" fill="white" />
                        </svg>
                        }
                        sx={{
                            height: "56px",
                            width: "216px",
                            marginRight: "16px",
                            backgroundColor: PRIMARY.P_BLue
                        }}
                        onClick={() => setOpenAddDashboardTypePopUp(true)}
                    >Add Dashboard Type</Button>

                    <Button
                        startDecorator={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.41489 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM10 16.875C8.64026 16.875 7.31105 16.4718 6.18046 15.7164C5.04987 14.9609 4.16868 13.8872 3.64833 12.6309C3.12798 11.3747 2.99183 9.99237 3.2571 8.65875C3.52238 7.32513 4.17716 6.10013 5.13864 5.13864C6.10013 4.17715 7.32514 3.52237 8.65876 3.2571C9.99238 2.99183 11.3747 3.12798 12.631 3.64833C13.8872 4.16868 14.9609 5.04987 15.7164 6.18045C16.4718 7.31104 16.875 8.64025 16.875 10C16.8729 11.8227 16.1479 13.5702 14.8591 14.8591C13.5702 16.1479 11.8227 16.8729 10 16.875ZM13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625H10.625V13.125C10.625 13.2908 10.5592 13.4497 10.4419 13.5669C10.3247 13.6842 10.1658 13.75 10 13.75C9.83424 13.75 9.67527 13.6842 9.55806 13.5669C9.44085 13.4497 9.375 13.2908 9.375 13.125V10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H9.375V6.875C9.375 6.70924 9.44085 6.55027 9.55806 6.43306C9.67527 6.31585 9.83424 6.25 10 6.25C10.1658 6.25 10.3247 6.31585 10.4419 6.43306C10.5592 6.55027 10.625 6.70924 10.625 6.875V9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10Z" fill="white" />
                        </svg>
                        }
                        sx={{
                            height: "56px",
                            width: "216px",
                            backgroundColor: PRIMARY.P_BLue
                        }}
                        onClick={() => setOpen(true)}
                    >Add New Brand</Button>
                </div>
            </div>
            {/* Table Container */}
            <div className="flex flex-col h-full mt-10 px-4 py-4 border rounded-lg" >
                {/* Search Box */}
                <Input
                    // startDecorator={}
                    // endDecorator={}
                    placeholder="Search"
                    sx={{
                        "--Input-focusedThickness": "0px",
                        width: "258px",
                        height: "30px"
                    }}
                    onChange={searchBrand}
                />
                {/* Table */}
                <div className="flex flex-col h-full mt-2.5">
                    <div style={{ height: 32, fontSize: 14, fontWeight: 400, color: BLACK.B_40 }} className="flex flex-row items-center w-full border-b" >
                        <div className="w-1/3">Brand Name</div>
                        <div className="w-1/3" >Brand Link</div>
                        <div className="w-1/3">Dashboards url</div>
                    </div>
                    <div style={{ height: "calc(100vh - 364px)", overflowY: "scroll" }} className="mt-4">
                        {
                            loader && (
                                <div className="flex flex-col justify-center items-center h-full" >
                                    <CircularProgress size="sm" />
                                </div>
                            )
                        }
                        {
                            !loader && brandArray?.map(brand => {
                                return (
                                    <div style={{ fontSize: 14, fontWeight: 400, height: 32 }} className="flex flex-row items-center w-full my-1" >
                                        <div className="w-1/3">{brand?.brand_name}</div>
                                        <div className="w-1/3" >{brand?.company_name}</div>
                                        <div className="w-1/3">
                                            <div onClick={() => onEditBrandClickHandler(brand)} className="mr-6 cursor-pointer max-w-fit"  >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M21.5306 5.46899L18.5306 2.46899C18.461 2.39926 18.3783 2.34394 18.2872 2.30619C18.1962 2.26845 18.0986 2.24902 18 2.24902C17.9014 2.24902 17.8038 2.26845 17.7128 2.30619C17.6217 2.34394 17.539 2.39926 17.4694 2.46899L8.46937 11.469C8.39975 11.5387 8.34454 11.6214 8.3069 11.7125C8.26926 11.8035 8.24992 11.9011 8.25 11.9996V14.9996C8.25 15.1985 8.32902 15.3893 8.46967 15.5299C8.61032 15.6706 8.80109 15.7496 9 15.7496H12C12.0985 15.7497 12.1961 15.7304 12.2871 15.6927C12.3782 15.6551 12.4609 15.5999 12.5306 15.5302L21.5306 6.53024C21.6004 6.46058 21.6557 6.37787 21.6934 6.28682C21.7312 6.19577 21.7506 6.09818 21.7506 5.99961C21.7506 5.90105 21.7312 5.80346 21.6934 5.71241C21.6557 5.62136 21.6004 5.53864 21.5306 5.46899ZM11.6897 14.2496H9.75V12.3099L15.75 6.30993L17.6897 8.24961L11.6897 14.2496ZM18.75 7.1893L16.8103 5.24961L18 4.05993L19.9397 5.99961L18.75 7.1893ZM21 11.2496V19.4996C21 19.8974 20.842 20.279 20.5607 20.5603C20.2794 20.8416 19.8978 20.9996 19.5 20.9996H4.5C4.10218 20.9996 3.72064 20.8416 3.43934 20.5603C3.15804 20.279 3 19.8974 3 19.4996V4.49961C3 4.10179 3.15804 3.72026 3.43934 3.43895C3.72064 3.15765 4.10218 2.99961 4.5 2.99961H12.75C12.9489 2.99961 13.1397 3.07863 13.2803 3.21928C13.421 3.35994 13.5 3.5507 13.5 3.74961C13.5 3.94853 13.421 4.13929 13.2803 4.27994C13.1397 4.4206 12.9489 4.49961 12.75 4.49961H4.5V19.4996H19.5V11.2496C19.5 11.0507 19.579 10.8599 19.7197 10.7193C19.8603 10.5786 20.0511 10.4996 20.25 10.4996C20.4489 10.4996 20.6397 10.5786 20.7803 10.7193C20.921 10.8599 21 11.0507 21 11.2496Z" fill="#1886FE" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            {/* Modal */}
            {
                open && < AddNewBrandModal setOpen={setOpen} />
            }
            {
                openAddDashboardTypePopUp && <AddNewDashboardTypeModal setOpenAddDashboardTypePopUp={setOpenAddDashboardTypePopUp} />
            }
            {
                openAddDashboardPopUp && <DashboardUrlModal brandName={selectedBrand?.brand_name} brandId={selectedBrand?.brand_id} setOpenAddDashboardPopUp={setOpenAddDashboardPopUp} />
            }
        </div>
    )
}


export default BrandManagement;