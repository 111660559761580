import { Button, Checkbox, CircularProgress, Input, Modal, Option, Select } from "@mui/joy";
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { UUID } from "crypto";
import axios from "axios";
import appError from "../../appError";
import Joi from "joi";
import toast from "react-hot-toast";
type UserObj = {
    user_id: UUID
    first_name: string,
    email: string,
    user_type: string,
}

type BrandAccessModalProps = {
    brandArray: { brand_name: string, brand_id: UUID }[],
    setOpenPowerBiAccessModal: Dispatch<SetStateAction<boolean>>,
    setEditUser: Dispatch<SetStateAction<any>>
    editUser: UserObj | null,
    // onRefreshUserBrand: (user_id: UUID | undefined) => void
}
const PowerBiAccessModal: React.FC<BrandAccessModalProps> = ({ brandArray, setOpenPowerBiAccessModal, editUser, setEditUser }) => {
    const user_id: UUID | undefined = editUser?.user_id;

    type DashboardObj = {
        brand_power_bi_dashboard_id: UUID,
        dashboard_type: string,
        brand_power_bi_dashboard_type_id: UUID,
        status: boolean | null
    }

    type BrandObj = {
        brand_name: string,
        brand_id: UUID,
        dashboard_array: DashboardObj[]
    }


    const [brandDashboardArray, setBrandDashboardArray] = useState<BrandObj[]>();
    const [lodingOnSave, setLoadingOnSave] = useState<boolean>(false);
    const [loadingBrandsDashboards, setLoadingBrandsDashboards] = useState<boolean>(false);
    const [state, setState] = useState({
        email: "",
        first_name: "",
        user_type: "USER",
    });

    useEffect(() => {
        const first_name: string | undefined = editUser?.first_name;
        const email: string | undefined = editUser?.email;
        const user_type: string | undefined = editUser?.user_type;
        setState({
            email: email ? email : "",
            first_name: first_name ? first_name : "",
            user_type: user_type ? user_type : ""
        });
        fetchBrandsDashboards();
    }, []);

    const fetchBrandsDashboards = async () => {
        try {
            setLoadingBrandsDashboards(true);
            const token = localStorage.getItem("token");

            const brandsRes = await axios.get(`${BASE_URL}brand/getDashboardsForUserGroupByBrands?user_id=${user_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const { brand_dashboard_array } = brandsRes?.data?.data;
            setBrandDashboardArray(brand_dashboard_array);
        } catch (error) {
            appError(error);
        } finally {
            setLoadingBrandsDashboards(false);
        }
    }






    const onCheckBoxClickHandler = ({ target: { checked, name } }: React.ChangeEvent<HTMLInputElement>, brand_id: UUID): void => {
        setBrandDashboardArray(prevState => {

            const newState: BrandObj[] = [];
            if (!prevState) {
                return newState;
            }
            for (let brand of prevState) {
                if (brand?.brand_id === brand_id) {
                    let new_dashboard_array = [];
                    for (let dashboard of brand?.dashboard_array) {
                        if (dashboard?.brand_power_bi_dashboard_id === name) {
                            dashboard.status = checked;
                        }
                        new_dashboard_array.push(dashboard);
                    }
                    brand.dashboard_array = new_dashboard_array;
                }
                newState.push(brand);
            }
            return newState
        })
    }

    const onChangeHandler = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>): void => {
        setState(prevState => ({ ...prevState, [name]: value }))
    }

    const onSaveClickHandler = async () => {
        if (!brandDashboardArray) {
            return
        }

        let dashboard_array = [];
        for (let brand of brandDashboardArray) {
            for (let dashboard of brand?.dashboard_array) {
                let { brand_power_bi_dashboard_id, status } = dashboard;
                status = Boolean(status);
                dashboard_array.push({
                    brand_power_bi_dashboard_id,
                    status
                });
            }
        }

        const schema = Joi.object({
            user_id: Joi.string().required().guid({ version: "uuidv4" }),
            dashboard_array: Joi.array().required().items(Joi.object({
                brand_power_bi_dashboard_id: Joi.string().required().guid({ version: "uuidv4" }),
                status: Joi.boolean().required()
            })).min(1).label("Brand")
        }).messages({
            'any.required': '{#label} is required',
            'array.min': 'Please select {#label}',
            'string.guid': '{#label} must be a valid UUID'
        });

        const validate = schema.validate({ dashboard_array, user_id });
        if (validate.error) {
            appError(validate?.error?.toString());
            return;
        }
        try {
            setLoadingOnSave(true);
            const token = localStorage.getItem("token");
            const { dashboard_array, user_id } = validate?.value;
            const res = await axios.put(`${BASE_URL}user/powerBiDashboardAccess/${user_id}`,
                { dashboard_array },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            );
            const { message } = res?.data?.data;
            toast.success(message);
            setEditUser(null);
            // onRefreshUserBrand(user_id);
            setOpenPowerBiAccessModal(false);
        } catch (error) {
            appError(error);
        } finally {
            setLoadingOnSave(false);
        }
    }

    return (
        <Modal
            open={true}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <div
                style={{ height: 514, width: 790 }}
                className="border rounded-lg px-12 py-12 bg-white"
            >
                <div style={{ height: 58 }} className="flex flex-col justify-between" >
                    <div style={{ fontSize: 24, fontWeight: 500, color: BLACK.B_100 }}>PowerBi Dashboard Access</div>
                    <p style={{ fontSize: 14, fontWeight: 400, color: BLACK.B_40 }} >Please select the dashboards you want to assign to <span style={{ color: PRIMARY.P_BLue }} >{editUser?.first_name}</span></p>
                </div>
                {/* Form */}
                <div style={{ height: 208, marginTop: 40 }} >
                    <div className="flex flex-row">
                        <div className="w-1/2" >
                            <div className="h-full p-2" style={{ width: 340, borderRadius: 8, backgroundColor: PRIMARY.P_Light }} >
                                {/* <div>
                                    <Input
                                        startDecorator={
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M14.3539 13.6464L11.2245 10.5176C12.1315 9.42871 12.5838 8.03201 12.4873 6.6181C12.3908 5.20419 11.7528 3.88193 10.7062 2.92637C9.65963 1.97082 8.28492 1.45555 6.86808 1.48775C5.45125 1.51995 4.10137 2.09714 3.09926 3.09926C2.09714 4.10137 1.51995 5.45125 1.48775 6.86808C1.45555 8.28492 1.97082 9.65963 2.92637 10.7062C3.88193 11.7528 5.20419 12.3908 6.6181 12.4873C8.03201 12.5838 9.42871 12.1315 10.5176 11.2245L13.6464 14.3539C13.6928 14.4003 13.748 14.4372 13.8087 14.4623C13.8694 14.4875 13.9344 14.5004 14.0001 14.5004C14.0658 14.5004 14.1309 14.4875 14.1916 14.4623C14.2523 14.4372 14.3074 14.4003 14.3539 14.3539C14.4003 14.3074 14.4372 14.2523 14.4623 14.1916C14.4875 14.1309 14.5004 14.0658 14.5004 14.0001C14.5004 13.9344 14.4875 13.8694 14.4623 13.8087C14.4372 13.748 14.4003 13.6928 14.3539 13.6464ZM2.50014 7.00014C2.50014 6.11013 2.76406 5.2401 3.25853 4.50008C3.753 3.76006 4.4558 3.18328 5.27807 2.84268C6.10033 2.50209 7.00513 2.41298 7.87805 2.58661C8.75096 2.76024 9.55279 3.18883 10.1821 3.81816C10.8115 4.4475 11.24 5.24932 11.4137 6.12224C11.5873 6.99515 11.4982 7.89995 11.1576 8.72222C10.817 9.54449 10.2402 10.2473 9.50021 10.7418C8.76019 11.2362 7.89016 11.5001 7.00014 11.5001C5.80707 11.4988 4.66325 11.0243 3.81962 10.1807C2.976 9.33704 2.50147 8.19321 2.50014 7.00014Z" fill="#1C1C1C" fill-opacity="0.2" />
                                            </svg>

                                        }
                                        placeholder="Search Brands"
                                        sx={{
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            "--Input-focusedThickness": "0px",
                                            width: "258px",
                                            height: "30px",
                                            backgroundColor: WHITE.W_100
                                        }}
                                    />
                                </div> */}

                                {
                                    loadingBrandsDashboards && (
                                        <div className="flex justify-center items-center h-full w-full">
                                            <CircularProgress size="sm" />
                                        </div>
                                    )
                                }
                                {
                                    !loadingBrandsDashboards && brandDashboardArray && brandDashboardArray?.length > 0 && (
                                        <div className="flex flex-col overflow-x-scroll" style={{ height: 186 }}>
                                            {
                                                brandDashboardArray?.map(brnad => {
                                                    return (
                                                        <div className="flex flex-col" key={brnad?.brand_id} >
                                                            <div className="my-1" >
                                                                {brnad?.brand_name}
                                                            </div>

                                                            {
                                                                brnad?.dashboard_array?.map(dashboard => {
                                                                    return (
                                                                        <Checkbox key={dashboard?.brand_power_bi_dashboard_id} onChange={(e) => onCheckBoxClickHandler(e, brnad?.brand_id)} sx={{ fontSize: 14, fontWeight: 400, margin: "4px 12px 4px 12px" }} checked={Boolean(dashboard?.status)} name={dashboard?.brand_power_bi_dashboard_id} label={dashboard?.dashboard_type} />

                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )

                                                })
                                            }
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                        <div className="w-1/2" >
                            <Input
                                placeholder="First Name"
                                value={state?.first_name}
                                disabled={true}
                                sx={{
                                    "--Input-focusedThickness": "0px",
                                    marginBottom: "14px",
                                    width: "340px",
                                    height: "58px",
                                    backgroundColor: BLACK.B_5
                                }}
                            />
                            <Input
                                placeholder="Email ID"
                                value={state?.email}
                                disabled={true}
                                sx={{
                                    "--Input-focusedThickness": "0px",
                                    marginBottom: "14px",
                                    width: "340px",
                                    height: "58px",
                                    backgroundColor: BLACK.B_5
                                }}
                                name="email"
                                onChange={onChangeHandler}
                            />
                            <Select
                                //onChange={onSelectUserTypeHandler}
                                disabled={true}
                                value={state?.user_type}
                                placeholder="*Select user type"
                                sx={{
                                    "--Input-focusedThickness": "0px",
                                    width: "340px",
                                    height: "58px",
                                    backgroundColor: BLACK.B_5
                                }}>
                                <Option value={"USER"} >USER</Option>
                                {/* <Option value={"CLIENT"}>CLIENT</Option> */}
                            </Select>
                        </div>
                    </div>
                    {/* <div className="flex flex-row  justify-between w-full mb-4" >
                        <Input
                            placeholder="*Full Name"
                            sx={{
                                "--Input-focusedThickness": "0px",
                                width: "340px",
                                height: "58px",
                                backgroundColor: BLACK.B_5
                            }}
                        />
                        <Input
                            placeholder="*Phone Number"
                            sx={{
                                "--Input-focusedThickness": "0px",
                                width: "340px",
                                height: "58px",
                                backgroundColor: BLACK.B_5
                            }}
                        />
                    </div>
                    <div className="flex flex-row  justify-between w-full mb-4" >
                        <Input
                            placeholder="*Email ID"
                            sx={{
                                "--Input-focusedThickness": "0px",
                                width: "340px",
                                height: "58px",
                                backgroundColor: BLACK.B_5
                            }}
                            name="email"
                            onChange={onChangeHandler}
                        />
                        <Select placeholder="Department/Company" sx={{
                            "--Input-focusedThickness": "0px",
                            width: "340px",
                            height: "58px",
                            backgroundColor: BLACK.B_5
                        }}>
                            <Option value={"Department"} >Department</Option>
                            <Option value={"Company"}>Company</Option>
                        </Select>
                    </div> */}

                    <div className="flex flex-row justify-between mt-16">
                        <Button color="neutral"
                            sx={{
                                height: "48px",
                                width: "340px",
                                backgroundColor: BLACK.B_5,
                                color: BLACK.B_100,
                                ":hover": {
                                    backgroundColor: BLACK.B_20,
                                }
                            }}
                            onClick={() => setOpenPowerBiAccessModal(false)}
                        >Cancel</Button>
                        <Button
                            loading={lodingOnSave}
                            onClick={onSaveClickHandler}
                            endDecorator={
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                    <path d="M17.8172 10.4425L12.1922 16.0675C12.0749 16.1848 11.9159 16.2507 11.75 16.2507C11.5841 16.2507 11.4251 16.1848 11.3078 16.0675C11.1905 15.9503 11.1247 15.7912 11.1247 15.6253C11.1247 15.4595 11.1905 15.3004 11.3078 15.1832L15.8664 10.6253H3.625C3.45924 10.6253 3.30027 10.5595 3.18306 10.4423C3.06585 10.3251 3 10.1661 3 10.0003C3 9.83459 3.06585 9.67562 3.18306 9.55841C3.30027 9.4412 3.45924 9.37535 3.625 9.37535H15.8664L11.3078 4.81753C11.1905 4.70026 11.1247 4.5412 11.1247 4.37535C11.1247 4.2095 11.1905 4.05044 11.3078 3.93316C11.4251 3.81588 11.5841 3.75 11.75 3.75C11.9159 3.75 12.0749 3.81588 12.1922 3.93316L17.8172 9.55816C17.8753 9.61621 17.9214 9.68514 17.9529 9.76101C17.9843 9.83688 18.0005 9.91821 18.0005 10.0003C18.0005 10.0825 17.9843 10.1638 17.9529 10.2397C17.9214 10.3156 17.8753 10.3845 17.8172 10.4425Z" fill="white" />
                                </svg>
                            }
                            sx={{
                                height: "48px",
                                width: "340px",
                                backgroundColor: PRIMARY.P_BLue,
                            }}
                        >Save</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default PowerBiAccessModal