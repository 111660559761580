import { Button, Modal } from "@mui/joy"
import { BASE_URL, BLACK, PRIMARY } from "../../appConstants";
import React, { Dispatch, SetStateAction, useEffect, useState, } from "react";
import { UUID } from "crypto";


type FileDetailObj = {
    file_id: UUID,
    file_type: string,
    file_name: string,
    created_time_stamp: string,
    updated_time_stamp: string
}


type WarningModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>
    onUpdateBrandStage: () => void
    fileDetail: Map<any, FileDetailObj | undefined>
}
const WarningModal: React.FC<WarningModalProps> = ({ setOpen, onUpdateBrandStage, fileDetail }) => {
    const [state,setState] = useState<string>("");
    const onProceedClick = () => {
        onUpdateBrandStage();
        setOpen(false);
    }
    useEffect(() => {
        let arr = [];
        fileDetail.has("CRAWLED_CAMPAIGN")
        if (!fileDetail.has("PRODUCT")) {
            arr.push("product");
        }
        if (!fileDetail.has("KEYWORD")) {
            arr.push("keyword");
        }
        if (!fileDetail.has("ADS_TARGETING")) {
            arr.push("ads targeting");
        }
        if (!fileDetail.has("CAMPAIGN_MASTER")) {
            arr.push("campaign");
        }
        if (!fileDetail.has("CRAWLED_CAMPAIGN")) {
            arr.push("downloaded campaign");
        }

        let result = "";
        if (arr.length > 1) {
            result = arr.slice(0, -1).join(", ") + " & " + arr[arr.length - 1];
        } else if (arr.length === 1) {
            result = arr[0];
        }
        setState(result);

    }, []);

    return (
        <Modal
            open={true}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            className="h-full"
        >
            <div
                style={{ height: 556, width: 790 }}
                className="flex flex-col border rounded-lg px-12 py-12 bg-white"
            >

                <div className="flex flex-col items-center  h-full w-full">
                    <img height={200} width={200} src={`${process.env.PUBLIC_URL}/fatal_error.png`} alt="Fatal Error" />
                    <div className="mt-10 text-center" style={{ fontSize: 24, fontWeight: 500 }} >
                        Are you sure you want to proceed with empty {state} Details?
                    </div>
                    <div className="mt-3" style={{ fontSize: 18, fontWeight: 400, color: BLACK.B_40 }}>
                        If you choose to <b>Proceed</b>, contact the Data team for manual upload.
                    </div>
                </div>
                <div className="flex flex-row justify-between mt-4">
                    <Button color="neutral"
                        sx={{
                            fontSize: 18,
                            fontWeight: 500,
                            height: "48px",
                            width: "340px",
                            backgroundColor: BLACK.B_5,
                            color: BLACK.B_100,
                            ":hover": {
                                backgroundColor: BLACK.B_20,
                            }
                        }}
                        onClick={() => setOpen(false)}
                    >Cancel</Button>
                    <Button
                        // onClick={onSaveClickHandler}
                        // endDecorator={
                        //     <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        //         <path d="M17.8172 10.4425L12.1922 16.0675C12.0749 16.1848 11.9159 16.2507 11.75 16.2507C11.5841 16.2507 11.4251 16.1848 11.3078 16.0675C11.1905 15.9503 11.1247 15.7912 11.1247 15.6253C11.1247 15.4595 11.1905 15.3004 11.3078 15.1832L15.8664 10.6253H3.625C3.45924 10.6253 3.30027 10.5595 3.18306 10.4423C3.06585 10.3251 3 10.1661 3 10.0003C3 9.83459 3.06585 9.67562 3.18306 9.55841C3.30027 9.4412 3.45924 9.37535 3.625 9.37535H15.8664L11.3078 4.81753C11.1905 4.70026 11.1247 4.5412 11.1247 4.37535C11.1247 4.2095 11.1905 4.05044 11.3078 3.93316C11.4251 3.81588 11.5841 3.75 11.75 3.75C11.9159 3.75 12.0749 3.81588 12.1922 3.93316L17.8172 9.55816C17.8753 9.61621 17.9214 9.68514 17.9529 9.76101C17.9843 9.83688 18.0005 9.91821 18.0005 10.0003C18.0005 10.0825 17.9843 10.1638 17.9529 10.2397C17.9214 10.3156 17.8753 10.3845 17.8172 10.4425Z" fill="white" />
                        //     </svg>
                        // }
                        sx={{
                            fontSize: 18,
                            fontWeight: 500,
                            height: "48px",
                            width: "340px",
                            backgroundColor: PRIMARY.P_BLue,
                        }}
                        onClick={onProceedClick}
                    >Proceed</Button>
                </div>
            </div>
        </Modal>
    )
}

export default WarningModal;