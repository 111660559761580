import { Autocomplete, AutocompleteOption, Button, DialogContent, DialogTitle, Input, List, ListItem, ListItemButton, ModalClose, Option, Select, selectClasses, createFilterOptions, Tooltip, Skeleton } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import Drawer from '@mui/joy/Drawer';
import { useSelector } from "react-redux";
import axios from "axios";
import appError from "../../appError";
import { UUID } from "crypto";
import toast from "react-hot-toast";

type DropDownObject = {
    mpn: string,
    master_mpn: string,
    brand_sub_category: string,
    brand_main_category: string
}




type CampaignObject = {
    campaign_master_id: string,
    campaign_name: string,
    campaign_type: string,
    brand_main_category: string,
    brand_sub_category: string,
    mpn: string,
    product_code: string,
    master_mpn: string,
    mapped?: string
}

type CampaignMapObject = {
    campaign_name: string,
    campaign_type: string,
    brand_main_category: string,
    brand_sub_category: string,
    mpn: string,
    product_code: string | null,
    master_mpn: string,
}

const filter = createFilterOptions<string>();
const CampaignMaster: React.FC = () => {

    const { brand_id: brandId } = useSelector((state: any) => state.appData);
    //const []
    const [state, setState] = useState<CampaignObject>({
        campaign_master_id: "",
        campaign_name: "",
        campaign_type: "",
        brand_main_category: "",
        brand_sub_category: "",
        mpn: "",
        product_code: "",
        master_mpn: ""
    });

    const [editDrawer, setEditDrawer] = useState(false);
    const [unmappedCampaign, setUnmappedCampaign] = useState<CampaignObject[]>([]);
    const [mpn, setMpn] = useState<string[]>();
    const [masterMpn, setMasterMpn] = useState<string[]>();
    const [subCategory, setSubCategory] = useState<string[]>();
    const [mainCategory, setMainCategory] = useState<string[]>();
    const [lodingOnSave, setLoadingOnSave] = useState<boolean>(false);
    const [loadingUnmappedData, setLoadingUnmappedData] = useState<boolean>(false);
    //const [selectedMpn, setSelectedMpn] = useState<string>("");
    // const [mpnOpen, setMpnOpen] = useState<boolean>(false);



    const [dropDown, setDropDown] = useState<DropDownObject[]>();



    useEffect(() => {
        fetchDropDownData();
        fetchUnmappedProduct();
    }, [brandId]);


    const fetchUnmappedProduct = async () => {

        try {
            setLoadingUnmappedData(true);
            const token = localStorage.getItem("token");
            if (!brandId) {
                return;
            }
            const unmappedProductRes = await axios.get(`${BASE_URL}onboarding/unmappedData/campaigns?brand_id=${brandId}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            const unmapped_campaign_array: CampaignObject[] = unmappedProductRes?.data?.data?.unmapped_campaign_array;
            setUnmappedCampaign(unmapped_campaign_array);
        } catch (error) {
            appError(error);
        } finally {
            setLoadingUnmappedData(false);
        }

    }



    const fetchDropDownData = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!brandId) {
                return;
            }
            const brandsRes = await axios.get(`${BASE_URL}onboarding/unmappedData?brand_id=${brandId}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const dropdown_array: DropDownObject[] = brandsRes?.data?.data?.dropdown_array;
            const mpnSet = new Set<string>();
            const masterMpnSet = new Set<string>();
            const subCategorySet = new Set<string>();
            const mainCategorySet = new Set<string>();

            for (let dropDown of dropdown_array) {
                const { mpn, master_mpn, brand_sub_category, brand_main_category } = dropDown;
                mpnSet.add(mpn);
                masterMpnSet.add(master_mpn);
                subCategorySet.add(brand_sub_category);
                mainCategorySet.add(brand_main_category);
            }
            setMpn(Array.from(mpnSet));
            setMasterMpn(Array.from(masterMpnSet));
            setSubCategory(Array.from(subCategorySet));
            setMainCategory(Array.from(mainCategorySet));

            setDropDown(dropdown_array);

        } catch (error) {

        } finally {

        }
    }


    const toggleDrawer = (open: boolean, product: CampaignObject | null) => {
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //     return;
        // }
        if (product) {
            setState(product);
        }
        setEditDrawer(open);
    };


    const onSelectChangeHandler = (name: string, value: string) => {
        setState(prevState => {
            if (value != "Other") {
                if (value === null) {
                    if (name === "mpn") {
                        return {
                            ...prevState,
                            mpn: "",
                            master_mpn: "",
                            brand_sub_category: "",
                            brand_main_category: ""
                        }
                    } else {
                        return prevState
                    }
                } else {
                    let obj = dropDown?.find(el => el[name as keyof DropDownObject] === value);
                    if (!obj) {
                        return prevState
                    }
                    if (name === "mpn") {
                        return {
                            ...prevState,
                            mpn: value,
                            master_mpn: obj?.master_mpn,
                            brand_sub_category: obj?.brand_sub_category,
                            brand_main_category: obj?.brand_main_category
                        }
                    } else {
                        return prevState
                    }
                }

            } else {
                if (name === "mpn") {
                    return {
                        ...prevState,
                        mpn: value,
                        master_mpn: "",
                        brand_sub_category: "",
                        brand_main_category: ""
                    }
                } else {
                    return prevState
                }

            }
        });
    }

    const onInputChangeHandler = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({ ...prevState, [name]: value }))
    }


    const onSaveClickHandler = async () => {

        let tempState = {} as CampaignObject;
        for (let [key, value] of Object.entries(state)) {
            tempState[key as keyof CampaignObject] = value?.trim();
        }

        setUnmappedCampaign(prevState => {
            let unmapped_campaign_array: CampaignObject[] = [];
            for (let unmapped_campaign of prevState) {
                if (tempState?.campaign_master_id === unmapped_campaign?.campaign_master_id) {
                    unmapped_campaign_array.push({ ...tempState, mapped: "YES" });
                    continue;
                }
                unmapped_campaign_array.push(unmapped_campaign);
            }
            return unmapped_campaign_array;
        });

        setState({
            campaign_master_id: "",
            mpn: "",
            master_mpn: "",
            brand_sub_category: "",
            brand_main_category: "",
            campaign_name: "",
            product_code: "",
            campaign_type: "",
            mapped: ""
        });
        setEditDrawer(false);
    }


    const onMapDataClickHandler = async () => {

        try {
            const brand_campaign_array: CampaignMapObject[] = [];
            const unmapped_campaign: CampaignObject[] = [];
            for (let campaign of unmappedCampaign) {
                if (campaign?.mapped === "YES") {
                    const {
                        campaign_name,
                        mpn,
                        master_mpn,
                        brand_sub_category,
                        brand_main_category,
                        campaign_type,
                        product_code
                    } = campaign;


                    brand_campaign_array.push({
                        campaign_name,
                        mpn,
                        master_mpn,
                        brand_sub_category,
                        brand_main_category,
                        campaign_type,
                        product_code: product_code ? product_code : null
                    });
                } else {
                    unmapped_campaign.push(campaign);
                }
            }

            if (!brand_campaign_array.length) {
                return;
            }

            setLoadingOnSave(true);
            const token = localStorage.getItem("token");
            const res = await axios.post(`${BASE_URL}onboarding/map/campaigns?brand_id=${brandId}`,
                { brand_campaign_array },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });

            const message = res?.data?.data?.message;
            toast.success(message);
            setState({} as CampaignObject);
            setUnmappedCampaign(unmapped_campaign);
            setEditDrawer(false);
            // fetchUnmappedProduct();
        } catch (error) {
            appError(error);
        } finally {
            setLoadingOnSave(false);
        }


    }


    //disabled
    const disabledMasterMpnSelect = state.mpn != "Other";
    const disabledSubCategorySelect = state.master_mpn != "Other";
    const disabledMainCategorySelect = state.brand_sub_category != "Other";

    return (
        <div className="flex flex-col pt-10 h-full" >
            <div className="h-full" >
                <div className="flex mx-10" style={{ marginBottom: 24 }} >
                    <Input
                        startDecorator={
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M17.9422 17.0578L14.0305 13.1469C15.1642 11.7857 15.7296 10.0398 15.6089 8.27244C15.4883 6.50506 14.6909 4.85223 13.3826 3.65779C12.0744 2.46334 10.356 1.81926 8.58493 1.85951C6.81388 1.89976 5.12653 2.62125 3.87389 3.87389C2.62125 5.12653 1.89976 6.81388 1.85951 8.58492C1.81926 10.356 2.46335 12.0744 3.65779 13.3826C4.85223 14.6909 6.50506 15.4883 8.27245 15.6089C10.0398 15.7296 11.7857 15.1642 13.1469 14.0305L17.0578 17.9422C17.1159 18.0003 17.1848 18.0463 17.2607 18.0777C17.3366 18.1092 17.4179 18.1253 17.5 18.1253C17.5821 18.1253 17.6634 18.1092 17.7393 18.0777C17.8152 18.0463 17.8841 18.0003 17.9422 17.9422C18.0003 17.8841 18.0463 17.8152 18.0777 17.7393C18.1092 17.6634 18.1253 17.5821 18.1253 17.5C18.1253 17.4179 18.1092 17.3366 18.0777 17.2607C18.0463 17.1848 18.0003 17.1159 17.9422 17.0578ZM3.125 8.75C3.125 7.63748 3.4549 6.54994 4.07298 5.62491C4.69107 4.69989 5.56957 3.97892 6.5974 3.55317C7.62524 3.12743 8.75624 3.01604 9.84738 3.23308C10.9385 3.45012 11.9408 3.98585 12.7275 4.77252C13.5141 5.55919 14.0499 6.56147 14.2669 7.65261C14.484 8.74376 14.3726 9.87475 13.9468 10.9026C13.5211 11.9304 12.8001 12.8089 11.8751 13.427C10.9501 14.0451 9.86252 14.375 8.75 14.375C7.25866 14.3733 5.82888 13.7802 4.77435 12.7256C3.71982 11.6711 3.12665 10.2413 3.125 8.75Z" fill="#1C1C1C" fill-opacity="0.4" />
                            </svg>
                        }
                        sx={{
                            "--Input-focusedThickness": "0px",
                            ':focus-within': {
                                border: `1px solid ${PRIMARY.P_BLue}`,
                            },
                            boxShadow: 'none',
                            height: 40,
                            width: 320,
                            borderRadius: 8,

                        }}
                        placeholder="Search"

                    />
                    <Button
                        sx={{
                            height: 40,
                            width: 120,
                            fontSize: 14,
                            fontWeight: 500,
                            backgroundColor: PRIMARY.P_BLue,
                            lineHeight: "20px",
                            ":hover": {
                                backgroundColor: PRIMARY.P_BLue
                            },
                            marginLeft: "12px",
                            borderRadius: 8
                        }}
                    >Search</Button>
                </div>
                {/* Table */}
                {
                    loadingUnmappedData ? (
                        <Skeleton
                            sx={{
                                height: "calc(100vh - 324px)",
                                width: "calc(100% - 80px)",
                                marginLeft: "40px",
                                borderRadius: 12,
                                opacity: "0.25"
                            }}
                        ></Skeleton>
                    ) : (
                        <div className="mx-10" style={{ height: "calc(100vh - 324px)", borderRadius: 12, boxShadow: "0px 4px 10px 4px rgba(130, 130, 130, 0.05" }} >

                            <div className="flex items-center w-full" style={{ borderTopLeftRadius: 12, borderTopRightRadius: 12, height: 56, fontSize: 14, fontWeight: 700, lineHeight: "20px", backgroundColor: PRIMARY.P_Purple50 }}  >
                                <div className="p-4" style={{ width: "37%" }}>Campaign Name</div>
                                <div className="p-4" style={{ width: "9%" }} >MPN</div>
                                <div className="p-4" style={{ width: "14%" }} >Sub Category</div>
                                <div className="p-4" style={{ width: "11%" }} >Main Category</div>
                                <div className="p-4" style={{ width: "15%" }} >Type of Ad</div>
                                <div className="p-4" style={{ width: "11%" }} >Platform Code</div>
                                <div className="p-4" style={{ width: "4%" }} ></div>
                            </div>
                            <div style={{ height: "calc(100vh - 440px)" }} className="overflow-y-auto" >
                                {
                                    unmappedCampaign?.map(campaign => {
                                        return (
                                            <div key={campaign.campaign_master_id} className="flex items-center w-full" style={{ height: 52, fontSize: 12, fontWeight: 400, lineHeight: "18px", borderBottom: `1px solid ${BLACK.B_5}` }}  >

                                                <div className="p-4" style={{ width: "37%" }} >
                                                    <Tooltip
                                                        sx={{
                                                            maxWidth: "400px",
                                                            color: WHITE.W_100,
                                                            backgroundColor: BLACK.B_100,
                                                            padding: "16px",
                                                            '& .MuiTooltip-tooltipArrow': {
                                                                backgroundColor: 'red' // Change this to the desired color
                                                            }
                                                        }}

                                                        title={campaign?.campaign_name} placement="bottom">
                                                        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} >{campaign?.campaign_name}</div>
                                                    </Tooltip>
                                                </div>

                                                <div className="p-4" style={{ width: "9%" }} >{campaign?.mpn}</div>

                                                <div className="p-4" style={{ width: "14%" }} >{campaign?.brand_sub_category}</div>
                                                <div className="p-4" style={{ width: "11%" }} >{campaign?.brand_main_category} </div>
                                                <div className="p-4" style={{ width: "15%" }} >{campaign?.campaign_type}</div>
                                                <div className="p-4" style={{ width: "11%" }}>{campaign?.product_code}</div>
                                                <div className="p-4" style={{ width: "4%" }}  >
                                                    <div className="cursor-pointer w-fit" onClick={() => toggleDrawer(true, campaign)} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            <path d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM3.33333 17.5C3.09722 17.5 2.89931 17.4201 2.73958 17.2604C2.57986 17.1007 2.5 16.9028 2.5 16.6667V14.6458C2.5 14.4236 2.54167 14.2118 2.625 14.0104C2.70833 13.809 2.82639 13.6319 2.97917 13.4792L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.52083 17.0208C6.36806 17.1736 6.19097 17.2917 5.98958 17.375C5.78819 17.4583 5.57639 17.5 5.35417 17.5H3.33333ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z" fill="#1C1C1C" fill-opacity="0.8" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>

                            <div style={{ borderTop: `1px solid ${BLACK.B_5}`, height: 60, borderBottomLeftRadius: 12, borderBottomRightRadius: 12 }} >

                            </div>
                        </div>
                    )
                }


            </div>
            <div className="flex flex-row justify-end items-center px-10 py-4" style={{ height: 72, boxShadow: "0px -4px 40px 4px rgba(0, 0, 0, 0.05)" }}>
                <Button

                    sx={{
                        marginRight: "16px",
                        height: 40,
                        width: 220,
                        fontSize: 14,
                        fontWeight: 500,
                        backgroundColor: BLACK.B_5,
                        color: BLACK.B_100,
                        lineHeight: "20px",
                        ":hover": {
                            backgroundColor: BLACK.B_5
                        },
                        borderRadius: 8
                    }}
                    onClick={fetchUnmappedProduct}
                >Clear All</Button>

                <Button
                    loading={lodingOnSave}
                    sx={{
                        height: 40,
                        width: 220,
                        fontSize: 14,
                        fontWeight: 500,
                        backgroundColor: PRIMARY.P_BLue,
                        lineHeight: "20px",
                        ":hover": {
                            backgroundColor: PRIMARY.P_BLue
                        },
                        borderRadius: 8
                    }}
                    onClick={onMapDataClickHandler}
                >Map Data</Button>
            </div>
            {/*  */}
            <Drawer
                key={'right'}
                anchor={"right"}
                open={editDrawer}
                onClose={() => toggleDrawer(false, null)}
                size="sm"
                sx={{

                }}


            >
                <div className="flex flex-row justify-between p-6" style={{ height: 72, borderBottom: `1px solid ${BLACK.B_10}` }} >
                    <div style={{ fontSize: 18, fontWeight: 500, lineHeight: "24px", color: BLACK.B_100 }} >Product Details</div>
                    <div className="w-fit cursor-pointer" onClick={() => toggleDrawer(false, null)} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M19.2806 18.2194C19.3502 18.289 19.4055 18.3718 19.4432 18.4628C19.4809 18.5539 19.5003 18.6514 19.5003 18.75C19.5003 18.8485 19.4809 18.9461 19.4432 19.0372C19.4055 19.1282 19.3502 19.2109 19.2806 19.2806C19.2109 19.3503 19.1281 19.4056 19.0371 19.4433C18.9461 19.481 18.8485 19.5004 18.7499 19.5004C18.6514 19.5004 18.5538 19.481 18.4628 19.4433C18.3717 19.4056 18.289 19.3503 18.2193 19.2806L11.9999 13.0603L5.78055 19.2806C5.63982 19.4213 5.44895 19.5004 5.24993 19.5004C5.05091 19.5004 4.86003 19.4213 4.7193 19.2806C4.57857 19.1399 4.49951 18.949 4.49951 18.75C4.49951 18.551 4.57857 18.3601 4.7193 18.2194L10.9396 12L4.7193 5.78061C4.57857 5.63988 4.49951 5.44901 4.49951 5.24999C4.49951 5.05097 4.57857 4.8601 4.7193 4.71936C4.86003 4.57863 5.05091 4.49957 5.24993 4.49957C5.44895 4.49957 5.63982 4.57863 5.78055 4.71936L11.9999 10.9397L18.2193 4.71936C18.36 4.57863 18.5509 4.49957 18.7499 4.49957C18.949 4.49957 19.1398 4.57863 19.2806 4.71936C19.4213 4.8601 19.5003 5.05097 19.5003 5.24999C19.5003 5.44901 19.4213 5.63988 19.2806 5.78061L13.0602 12L19.2806 18.2194Z" fill="#1C1C1C" />
                        </svg>
                    </div>
                </div>
                <DialogContent sx={{ paddingTop: "40px", paddingBottom: "16px", paddingX: "24px" }} >
                    <div className="mb-6" style={{ height: 72 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Platform Code</div>
                        <Input
                            value={state.campaign_name}
                            sx={{
                                "--Input-focusedThickness": "0px",
                                ':focus-within': {
                                    border: `1px solid ${PRIMARY.P_BLue}`,
                                },
                                boxShadow: 'none',
                                height: 52,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                borderRadius: 8,
                                backgroundColor: BLACK.B_10
                            }}
                            placeholder="Add Platform Code"
                            disabled={true}

                        />
                    </div>


                    <div className="mb-6" style={{ height: 72 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >MPN</div>
                        <Autocomplete

                            value={state?.mpn}
                            placeholder="Search Value"
                            onChange={(event, newValue) => {
                                onSelectChangeHandler("mpn", newValue);
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                if (params.inputValue !== '') {
                                    // filtered.push("Other")
                                }

                                return filtered;
                            }}
                            options={mpn as readonly any[]}
                            getOptionLabel={(option) => {
                                // for example value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option;
                            }}
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            renderOption={(props, option) => (
                                <AutocompleteOption
                                    {...props}>{option}</AutocompleteOption>
                            )}
                            sx={{

                                width: "100%",
                                height: 52,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                borderRadius: 8,
                                "--Input-focusedThickness": "0px",
                                ':focus-within': {
                                    border: `1px solid ${PRIMARY.P_BLue}`,
                                },
                                boxShadow: 'none',

                            }}
                        // open={mpnOpen}
                        // onOpen={() => setMpnOpen(true)}
                        // onClose={() => setMpnOpen(false)}
                        // endDecorator={
                        //     !mpnOpen ? (
                        //         <svg onClick={() => setMpnOpen(true)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        //             <path d="M9.99984 12.4791C9.88873 12.4791 9.78456 12.4618 9.68734 12.4271C9.59012 12.3923 9.49984 12.3333 9.4165 12.25L5.58317 8.41665C5.43039 8.26387 5.354 8.06942 5.354 7.83331C5.354 7.5972 5.43039 7.40276 5.58317 7.24998C5.73595 7.0972 5.93039 7.02081 6.1665 7.02081C6.40262 7.02081 6.59706 7.0972 6.74984 7.24998L9.99984 10.5L13.2498 7.24998C13.4026 7.0972 13.5971 7.02081 13.8332 7.02081C14.0693 7.02081 14.2637 7.0972 14.4165 7.24998C14.5693 7.40276 14.6457 7.5972 14.6457 7.83331C14.6457 8.06942 14.5693 8.26387 14.4165 8.41665L10.5832 12.25C10.4998 12.3333 10.4096 12.3923 10.3123 12.4271C10.2151 12.4618 10.1109 12.4791 9.99984 12.4791Z" fill="#1C1C1C" fill-opacity="0.4" />
                        //         </svg>
                        //     ) : null
                        // }

                        />
                    </div>

                    <div className="mb-6" style={{ height: 72 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Sub Category</div>
                        <Input
                            //onChange={onInputChangeHandler}
                            name="brand_sub_category"
                            value={state.brand_sub_category}
                            sx={{
                                "--Input-focusedThickness": "0px",
                                ':focus-within': {
                                    border: `1px solid ${PRIMARY.P_BLue}`,
                                },
                                boxShadow: 'none',
                                height: 52,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                borderRadius: 8,
                                backgroundColor: BLACK.B_10
                            }}
                            placeholder=""
                            disabled={true}
                        />
                    </div>
                    <div className="mb-6" style={{ height: 72 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Other Main Category</div>
                        <Input
                            //onChange={onInputChangeHandler}
                            name="brand_main_category"
                            value={state.brand_main_category}
                            sx={{
                                "--Input-focusedThickness": "0px",
                                ':focus-within': {
                                    border: `1px solid ${PRIMARY.P_BLue}`,
                                },
                                boxShadow: 'none',
                                height: 52,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                borderRadius: 8,
                                backgroundColor: BLACK.B_10
                            }}
                            placeholder=""
                            disabled={true}
                        />
                    </div>
                    <div className="mb-6" style={{ height: 72 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Other Main Category</div>
                        <Input
                            //onChange={onInputChangeHandler}
                            name="campaign_type"
                            value={state.campaign_type}
                            sx={{
                                "--Input-focusedThickness": "0px",
                                ':focus-within': {
                                    border: `1px solid ${PRIMARY.P_BLue}`,
                                },
                                boxShadow: 'none',
                                height: 52,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                borderRadius: 8,
                                backgroundColor: BLACK.B_10
                            }}
                            placeholder=""
                            disabled={true}
                        />
                    </div>

                    <div className="mb-6" style={{ height: 72 }} >
                        <div className="mb-1" style={{ fontSize: 14, fontWeight: 500, lineHeight: "20px", color: BLACK.B_40 }} >Platform Code</div>
                        <Input
                            onChange={onInputChangeHandler}
                            name="product_code"
                            value={state.product_code}
                            sx={{
                                "--Input-focusedThickness": "0px",
                                // ':focus-within': {
                                //     border: `1px solid ${PRIMARY.P_BLue}`,
                                // },
                                boxShadow: 'none',
                                height: 52,
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: "20px",
                                borderRadius: 8,
                            }}
                            placeholder="Add Product Code"
                        />
                    </div>

                </DialogContent>
                <div className="flex items-center  px-6" style={{ height: 88, borderTop: `1px solid ${BLACK.B_10}` }} >
                    <Button
                        loading={lodingOnSave}
                        sx={{
                            height: 40,
                            width: "100%",
                            fontSize: 14,
                            fontWeight: 500,
                            backgroundColor: PRIMARY.P_BLue,
                            lineHeight: "20px",
                            ":hover": {
                                backgroundColor: PRIMARY.P_BLue
                            },
                            borderRadius: 8
                        }}
                        onClick={onSaveClickHandler}
                    >Save</Button>
                </div>
            </Drawer>
        </div>
    )
}

export default CampaignMaster;