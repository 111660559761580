import { Button, Select, Option, selectClasses, Textarea, Tooltip, OptionProps } from "@mui/joy";

import { BASE_URL, BLACK, FLASK_EXCEL_API_URL, PRIMARY, STATUS, WHITE } from "../../appConstants";
import AddMainCategoryModal from "./AddMainCategoryModal";
import { useEffect, useState } from "react";
import axios from "axios";
import { UUID } from "crypto";
import { AgGridReact } from "ag-grid-react";
import { ColDef, GroupCellRendererParams } from "ag-grid-community";
import xlsx_to_json from "../../appFunctions";
import { v4 as uuidv4 } from "uuid"
import * as React from 'react';
import SvgIcon from '@mui/joy/SvgIcon';
import { styled } from '@mui/joy';
import toast from "react-hot-toast";
import appError from "../../appError";
import { error } from "console";
import { useSelector } from "react-redux";


type ProductObject = {
    id?: string,
    brand_product_id: UUID,
    brand_product_code: string,
    brand_product_sku_id: string,
    brand_sub_category: string,
    platform_sub_category: string,
    platform_main_category: string,
    platform: string,
    brand_sub_category_status: string | null,
    brand_sub_category_message: string | null,
}

type SubCategorybject = {
    brand_sub_category: string,
    brand_sub_category_id: UUID
}









const CategoryMapping = () => {
    const brandId = useSelector((state:any) => state.appData.brand_id);



    const [subCategories, seSubCategories] = useState<SubCategorybject[]>([]);
    const [rowData, setRowData] = useState<ProductObject[]>([]);
    const [toggleSaveButton, setToggleSaveButton] = useState<boolean>(false);
    const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;
    useEffect(() => {
        fetchSubCategories();
        fetchProducts();
    }, []);
    const fetchSubCategories = async () => {
        try {
            const token = localStorage.getItem("token");
          
            const res = await axios.get(`${BASE_URL}onboarding/subCategories?brand_id=${brandId}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const { brand_sub_category_array } = res?.data?.data;
            seSubCategories(brand_sub_category_array);
        } catch (error) {
            appError(error);
        }
    }


    const fetchProducts = async () => {
        try {
            const token = localStorage.getItem("token");
           
            const res = await axios.get(`${BASE_URL}onboarding/products?brand_id=${brandId}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const { brand_product_array } = res?.data?.data;
            setRowData(brand_product_array);
        } catch (error) {
            appError(error);
        }
    }





    // const getRowHeight = (params: any) => {
    //     const { data: { brand_sub_category_array } } = params
    //     return brand_sub_category_array.length * 50
    // }





    // const validateCategory = async (brand_sub_category_array: BrandSubCategoryObject[] | any) => {
    //     try {
    //         const token = localStorage.getItem("token");
    //         const brand_id = localStorage.getItem("brand_id");
    //         const res = await axios.post(`${BASE_URL}onboarding/validate/subCategories?brand_id=${brand_id}`,
    //             { brand_sub_category_array },
    //             {
    //                 headers: {
    //                     "Authorization": `Bearer ${token}`
    //                 }
    //             }
    //         );
    //         return res?.data?.data?.brand_sub_category_array
    //     } catch (error: any) {
    //         
    //         throw (new Error(error));
    //     }
    // }

    // const fillErrorAndMessageInCategoryAndGroup = async (apiResponseCategories: BrandSubCategoryObject[], userEnteredCategories: BrandSubCategoryObject[]) => {
    //     let map = new Map();
    //     for (let el of userEnteredCategories) {
    //         const { brand_main_category, id } = el;
    //         let errorObjectOfsubCategory = apiResponseCategories.find((e) => e.id === id);
    //         const parentObject: ParentObjectForMap = {};

    //         if (errorObjectOfsubCategory) {
    //             const {
    //                 brand_main_category_status, brand_main_category_message,
    //                 brand_sub_category_status, brand_sub_category_message
    //             } = errorObjectOfsubCategory;
    //             if (brand_sub_category_status === "error") {
    //                 el.brand_sub_category_status = brand_sub_category_status;
    //                 el.brand_sub_category_message = brand_sub_category_message
    //             }
    //             if (brand_main_category_status === "error") {
    //                 parentObject.brand_main_category_status = brand_main_category_status;
    //                 parentObject.brand_main_category_message = brand_main_category_message;
    //             }
    //         }
    //         let mapValue = map.get(brand_main_category);
    //         if (mapValue) {
    //             mapValue.brand_sub_category_array.push(el);
    //             map.set(brand_main_category, mapValue);
    //         } else {
    //             parentObject.brand_sub_category_array = [el];
    //             map.set(brand_main_category, parentObject);
    //         }

    //     }

    //     const rows: RowObject[] = [];
    //     for (let [key, value] of map) {
    //         const { brand_sub_category_array, brand_main_category_status, brand_main_category_message } = value;
    //         rows.push({
    //             brand_main_category: key,
    //             brand_main_category_status,
    //             brand_main_category_message,
    //             brand_sub_category_array: brand_sub_category_array
    //         });
    //     }
    //     return rows;
    // }



    const onFileChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!(event.target.files && event.target.files.length > 0)) {
            return;
        }

        try {

            let brand_product_array: ProductObject[] = await xlsx_to_json(event.target.files[0]);
            if (!brand_product_array.length) {
                return
            }
            let set = new Set();
            for (let el of subCategories) {
                set.add(el?.brand_sub_category?.toLowerCase());
            }

            brand_product_array.forEach(el => {
                if (!set.has(el.brand_sub_category)) {
                    el.brand_sub_category_status = "error";
                    el.brand_sub_category_message = "Invalid sub category or it does not exist.";
                }
            });
            setToggleSaveButton(true);
            setRowData(brand_product_array);

            // const res: ProductObject[] = await validateCategory(brand_sub_category_array);
            // const set = new Set();
            // brand_sub_category_array.forEach(el => {
            //     if (set.has(el.brand_sub_category)) {
            //         el.brand_sub_category_status = "error";
            //         el.brand_sub_category_message = "Duplicate Sub Category.";
            //     } else {
            //         set.add(el.brand_sub_category);
            //     }
            // });
            // const res_brand_sub_category_array: ProductObject[] = await fillErrorAndMessageInCategoryAndGroup(res, brand_sub_category_array);
            // res_brand_sub_category_array.forEach(el => {
            //     el.id = uuidv4();
            // });
            // setRowData(res_brand_sub_category_array);
        } catch (error) {
            appError(error);
        }
    }

    const onDeleteClickHandler = async (product_code_id: any) => {
        try {
            const token = localStorage.getItem("token");
          
            const res = await axios.delete(`${BASE_URL}onboarding/products/${product_code_id}?brand_id=${brandId}`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
            const { message } = res?.data?.data;
            setRowData(prevState => {
                let newState: ProductObject[] = [];
                for (let el of prevState) {
                    if (el?.brand_product_id != product_code_id) {
                        newState.push(el);
                    }
                }
                return newState;
            });
            toast.success(message);
        } catch (error) {
            fetchProducts();
            appError(error);
        }
    }


    const onSelectSubCategoryHandler = async (product_code_id: any, value: any) => {
        try {
            const token = localStorage.getItem("token");
            
            const res = await axios.put(`${BASE_URL}onboarding/products?brand_id=${brandId}`,
                {
                    brand_product_array: [{
                        brand_product_id: product_code_id,
                        brand_sub_category: value
                    }]
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
            const { message } = res?.data?.data;
            setRowData(prevState => {
                let newState: ProductObject[] = [];
                for (let el of prevState) {
                    if (el?.brand_product_id === product_code_id) {
                        el.brand_sub_category = value;
                        el.brand_sub_category_status = null;
                        el.brand_sub_category_message = null;
                    }
                    newState.push(el);
                }
                return newState;
            });
            toast.success(message);
        } catch (error) {
            fetchProducts();
            appError(error);
        }
    }

    const onSaveClickHandler = async () => {
        try {


            let brand_product_array = [];
            for (let el of rowData) {
                if (el.brand_sub_category_status === "error") {
                    toast.error("Please remove or fix all elements marked as red color.");
                    return
                }
                const { brand_product_id, brand_sub_category } = el;
                brand_product_array.push({
                    brand_product_id,
                    brand_sub_category
                })
            }

            const token = localStorage.getItem("token");
           

            const res = await axios.put(`${BASE_URL}onboarding/products?brand_id=${brandId}`,
                {
                    brand_product_array
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
            const { message } = res?.data?.data;
            fetchProducts();
            setToggleSaveButton(false);
            toast.success(message);
        } catch (error) {
            appError(error);
        }
    }

    // const getRowId = React.useCallback((params: any) => params.data.id, []);
    return (
        <div className="flex flex-col pt-8 px-10 h-full" >
            {/* Header */}
            <div className="flex flex-row items-center mb-5" style={{ height: 62 }} >
                <div className="w-full" >
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Category Mapping</div>
                    <div style={{ fontSize: 18, fontWeight: 400, color: BLACK.B_40 }} >Please review the list of categories along with their corresponding products and Amazon categories. Feel free to make any changes if necessary.</div>
                </div>
                <Button
                    component="a"
                    sx={{
                        height: "56px",
                        width: "265px",
                        marginRight: "16px",
                        ":hover": { cursor: "pointer" }
                    }}
                    href={`${FLASK_EXCEL_API_URL}onBoardingExcels?brand_id=${brandId}&module=category_mapping`}
                    onClick={() => {
                        toast.loading("Please wait download will start 👆 soon.", {
                            duration: 3000
                        })
                    }}
                    startDecorator={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M17.5 11.875V16.25C17.5 16.5815 17.3683 16.8995 17.1339 17.1339C16.8995 17.3683 16.5815 17.5 16.25 17.5H3.75C3.41848 17.5 3.10054 17.3683 2.86612 17.1339C2.6317 16.8995 2.5 16.5815 2.5 16.25V11.875C2.5 11.7092 2.56585 11.5503 2.68306 11.4331C2.80027 11.3158 2.95924 11.25 3.125 11.25C3.29076 11.25 3.44973 11.3158 3.56694 11.4331C3.68415 11.5503 3.75 11.7092 3.75 11.875V16.25H16.25V11.875C16.25 11.7092 16.3158 11.5503 16.4331 11.4331C16.5503 11.3158 16.7092 11.25 16.875 11.25C17.0408 11.25 17.1997 11.3158 17.3169 11.4331C17.4342 11.5503 17.5 11.7092 17.5 11.875ZM9.55781 12.3172C9.61586 12.3753 9.68479 12.4214 9.76066 12.4529C9.83654 12.4843 9.91787 12.5005 10 12.5005C10.0821 12.5005 10.1635 12.4843 10.2393 12.4529C10.3152 12.4214 10.3841 12.3753 10.4422 12.3172L13.5672 9.19219C13.6253 9.13412 13.6713 9.06518 13.7027 8.98931C13.7342 8.91344 13.7503 8.83212 13.7503 8.75C13.7503 8.66788 13.7342 8.58656 13.7027 8.51069C13.6713 8.43482 13.6253 8.36588 13.5672 8.30781C13.5091 8.24974 13.4402 8.20368 13.3643 8.17225C13.2884 8.14083 13.2071 8.12465 13.125 8.12465C13.0429 8.12465 12.9616 8.14083 12.8857 8.17225C12.8098 8.20368 12.7409 8.24974 12.6828 8.30781L10.625 10.3664V3.125C10.625 2.95924 10.5592 2.80027 10.4419 2.68306C10.3247 2.56585 10.1658 2.5 10 2.5C9.83424 2.5 9.67527 2.56585 9.55806 2.68306C9.44085 2.80027 9.375 2.95924 9.375 3.125V10.3664L7.31719 8.30781C7.19991 8.19054 7.04085 8.12465 6.875 8.12465C6.70915 8.12465 6.55009 8.19054 6.43281 8.30781C6.31554 8.42509 6.24965 8.58415 6.24965 8.75C6.24965 8.91585 6.31554 9.07491 6.43281 9.19219L9.55781 12.3172Z" fill="white" />
                    </svg>
                    }
                >Download Template</Button>

                <Button
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    variant="outlined"
                    color="neutral"
                    size="sm"
                    sx={{
                        color: PRIMARY.P_BLue,
                        borderColor: PRIMARY.P_BLue,
                        borderRadius: "8px",
                        width: "172px",
                        height: "56px",
                    }}
                    startDecorator={
                        <SvgIcon>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                            >
                                <path
                                    color={PRIMARY.P_BLue}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                />
                            </svg>
                        </SvgIcon>
                    }
                >
                    Upload Excel
                    <VisuallyHiddenInput
                        onChange={onFileChangeHandler}
                        type="file" />
                </Button>
            </div>
            {/* Container */}
            <div style={{ border: `1px solid ${BLACK.B_10}`, borderRadius: 8 }} className="flex flex-row mb-6 p-4 h-full w-full" >
                {
                    <div className="flex flex-col h-full mt-2.5 w-full">
                        <div style={{ height: 32, fontSize: 14, fontWeight: 400, color: BLACK.B_40 }} className="flex flex-row items-center w-full border-b" >
                            <div className="w-1/5">SKU ID</div>
                            <div className="w-1/5" >Brand Sub Category</div>
                            <div className="w-1/5">Platform Main Category</div>
                            <div className="w-1/5">Platform Sub Category</div>
                            <div className="w-1/5" >Action</div>
                        </div>
                        <div style={{ height: "calc(100vh - 456px)", overflowY: "scroll" }} className="mt-4">
                            {
                                rowData?.map(el => {
                                    return (
                                        <div key={el.brand_product_id} style={{ fontSize: 14, fontWeight: 400 }} className="flex flex-row justify-start w-full my-1" >
                                            <div className="w-1/5 pt-2 pb-2" >
                                                {el?.brand_product_sku_id}
                                            </div>
                                            <div className="w-1/5 pt-2 pb-2">
                                                <Tooltip key={el?.id} arrow title={el?.brand_sub_category_message} color="danger" placement="top" variant={"outlined"}>
                                                    <Select
                                                        name={el?.brand_product_id}
                                                        value={el.brand_sub_category}
                                                        onChange={(event, value) => onSelectSubCategoryHandler(el?.brand_product_id, value)}
                                                        placeholder=  {el?.brand_sub_category_status==="error"? el.brand_sub_category: "Select Category"}
                                                        indicator={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g id="Iconography">
                                                                <path id="Vector" d="M13.354 6.35354L8.35403 11.3535C8.30759 11.4 8.25245 11.4369 8.19175 11.4621C8.13105 11.4872 8.06599 11.5002 8.00028 11.5002C7.93457 11.5002 7.86951 11.4872 7.80881 11.4621C7.74811 11.4369 7.69296 11.4 7.64653 11.3535L2.64653 6.35354C2.55271 6.25972 2.5 6.13247 2.5 5.99979C2.5 5.86711 2.55271 5.73986 2.64653 5.64604C2.74035 5.55222 2.8676 5.49951 3.00028 5.49951C3.13296 5.49951 3.26021 5.55222 3.35403 5.64604L8.00028 10.2929L12.6465 5.64604C12.693 5.59958 12.7481 5.56273 12.8088 5.53759C12.8695 5.51245 12.9346 5.49951 13.0003 5.49951C13.066 5.49951 13.131 5.51245 13.1917 5.53759C13.2524 5.56273 13.3076 5.59958 13.354 5.64604C13.4005 5.69249 13.4373 5.74764 13.4625 5.80834C13.4876 5.86904 13.5006 5.93409 13.5006 5.99979C13.5006 6.06549 13.4876 6.13054 13.4625 6.19124C13.4373 6.25193 13.4005 6.30708 13.354 6.35354Z" fill="#1886FE" />
                                                            </g>
                                                        </svg>}
                                                        size="sm"
                                                        sx={{
                                                            color: "#1886FE", borderRadius: 8, borderColor: (el?.brand_sub_category_status === "error" ? "red" : "#1886FE"),
                                                            marginRight: "32px",
                                                            [`& .${selectClasses.indicator}`]: {
                                                                transition: '0.2s',
                                                                [`&.${selectClasses.expanded}`]: {
                                                                    transform: 'rotate(-180deg)',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {
                                                            subCategories?.map(el => {
                                                                return <Option value={el?.brand_sub_category}  >{el?.brand_sub_category}</Option>
                                                            })
                                                        }
                                                    </Select>
                                                </Tooltip>
                                            </div>
                                            <div className="w-1/5 pt-2 pb-2">
                                                {
                                                    el?.platform_main_category
                                                }
                                            </div>
                                            <div className="flex items-center  w-1/5 pt-2 pb-2">
                                                {
                                                    el?.platform_sub_category
                                                }
                                            </div>
                                            <div className="flex items-center  w-1/5 pt-2 pb-2">
                                                <div onClick={() => onDeleteClickHandler(el?.brand_product_id)} className="max-w-fit max-h-fit cursor-pointer" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M20.25 4.5H16.5V3.75C16.5 3.15326 16.2629 2.58097 15.841 2.15901C15.419 1.73705 14.8467 1.5 14.25 1.5H9.75C9.15326 1.5 8.58097 1.73705 8.15901 2.15901C7.73705 2.58097 7.5 3.15326 7.5 3.75V4.5H3.75C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25C3 5.44891 3.07902 5.63968 3.21967 5.78033C3.36032 5.92098 3.55109 6 3.75 6H4.5V19.5C4.5 19.8978 4.65804 20.2794 4.93934 20.5607C5.22064 20.842 5.60218 21 6 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V6H20.25C20.4489 6 20.6397 5.92098 20.7803 5.78033C20.921 5.63968 21 5.44891 21 5.25C21 5.05109 20.921 4.86032 20.7803 4.71967C20.6397 4.57902 20.4489 4.5 20.25 4.5ZM9 3.75C9 3.55109 9.07902 3.36032 9.21967 3.21967C9.36032 3.07902 9.55109 3 9.75 3H14.25C14.4489 3 14.6397 3.07902 14.7803 3.21967C14.921 3.36032 15 3.55109 15 3.75V4.5H9V3.75ZM18 19.5H6V6H18V19.5ZM10.5 9.75V15.75C10.5 15.9489 10.421 16.1397 10.2803 16.2803C10.1397 16.421 9.94891 16.5 9.75 16.5C9.55109 16.5 9.36032 16.421 9.21967 16.2803C9.07902 16.1397 9 15.9489 9 15.75V9.75C9 9.55109 9.07902 9.36032 9.21967 9.21967C9.36032 9.07902 9.55109 9 9.75 9C9.94891 9 10.1397 9.07902 10.2803 9.21967C10.421 9.36032 10.5 9.55109 10.5 9.75ZM15 9.75V15.75C15 15.9489 14.921 16.1397 14.7803 16.2803C14.6397 16.421 14.4489 16.5 14.25 16.5C14.0511 16.5 13.8603 16.421 13.7197 16.2803C13.579 16.1397 13.5 15.9489 13.5 15.75V9.75C13.5 9.55109 13.579 9.36032 13.7197 9.21967C13.8603 9.07902 14.0511 9 14.25 9C14.4489 9 14.6397 9.07902 14.7803 9.21967C14.921 9.36032 15 9.55109 15 9.75Z" fill="#D72C0D" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </div>
            <Button

                sx={{
                    margin: "24px 0px",
                    width: "236px",
                    ":disabled": {
                        backgroundColor: PRIMARY.P_Blue,
                        color: BLACK.B_10,
                    }
                }}
                className="self-end"
                endDecorator={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M17.3172 10.4425L11.6922 16.0675C11.5749 16.1848 11.4159 16.2507 11.25 16.2507C11.0841 16.2507 10.9251 16.1848 10.8078 16.0675C10.6905 15.9503 10.6247 15.7912 10.6247 15.6253C10.6247 15.4595 10.6905 15.3004 10.8078 15.1832L15.3664 10.6253H3.125C2.95924 10.6253 2.80027 10.5595 2.68306 10.4423C2.56585 10.3251 2.5 10.1661 2.5 10.0003C2.5 9.83459 2.56585 9.67562 2.68306 9.55841C2.80027 9.4412 2.95924 9.37535 3.125 9.37535H15.3664L10.8078 4.81753C10.6905 4.70026 10.6247 4.5412 10.6247 4.37535C10.6247 4.2095 10.6905 4.05044 10.8078 3.93316C10.9251 3.81588 11.0841 3.75 11.25 3.75C11.4159 3.75 11.5749 3.81588 11.6922 3.93316L17.3172 9.55816C17.3753 9.61621 17.4214 9.68514 17.4529 9.76101C17.4843 9.83688 17.5005 9.91821 17.5005 10.0003C17.5005 10.0825 17.4843 10.1638 17.4529 10.2397C17.4214 10.3156 17.3753 10.3845 17.3172 10.4425Z" fill={!toggleSaveButton ? "#1C1C1C" : WHITE.W_100} fill-opacity={!toggleSaveButton ? "0.1" : "1"} />
                </svg>
                }
                disabled={!toggleSaveButton}

                onClick={onSaveClickHandler}
            > Save & Continue</Button>
        </div >
    )
}




export default CategoryMapping;