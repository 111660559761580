const AppLogo = ({height}) => {
    return (
        <svg  height={height} viewBox="0 0 483 126" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_280_293)">
                <path d="M4.06006 98.35V30.88H21.4701V98.36H4.06006V98.35Z" fill="white" />
                <path d="M25.54 12.69C22.11 12.69 19.14 13.97 16.63 16.54C14.11 19.11 12.86 22.11 12.86 25.53C12.86 22.1 11.6 19.11 9.09002 16.54C6.57002 13.97 3.55002 12.69 0.0200195 12.69C3.55002 12.69 6.58002 11.43 9.09002 8.92001C11.61 6.40001 12.86 3.43001 12.86 0.0100098C12.86 3.44001 14.12 6.41001 16.63 8.92001C19.15 11.44 22.12 12.69 25.54 12.69Z" fill="white" />
                <path d="M359.95 12.69C356.52 12.69 353.55 13.97 351.04 16.54C348.52 19.11 347.27 22.11 347.27 25.53C347.27 22.1 346.01 19.11 343.5 16.54C340.98 13.97 337.96 12.69 334.43 12.69C337.96 12.69 340.99 11.43 343.5 8.92001C346.02 6.40001 347.27 3.43001 347.27 0.0100098C347.27 3.44001 348.53 6.41001 351.04 8.92001C353.56 11.44 356.53 12.69 359.95 12.69Z" fill="white" />
                <path d="M74.91 28.99C82.29 28.99 88.38 31.47 93.2 36.41C98.01 41.36 100.42 48.2 100.42 56.92V98.35H83.01V59.08C83.01 54.58 81.8 51.14 79.37 48.76C76.94 46.38 73.7 45.18 69.65 45.18C65.15 45.18 61.55 46.58 58.85 49.36C56.15 52.15 54.8 56.33 54.8 61.91V98.35H37.39V30.88H54.8V38.44C59.03 32.14 65.73 28.99 74.91 28.99Z" fill="white" />
                <path d="M151.57 18.73C142.03 18.01 137.26 21.83 137.26 30.2V30.87H151.57V47.6H137.26V98.34H119.85V47.6H110.13V30.87H119.85V30.2C119.85 20.66 122.5 13.42 127.81 8.46999C133.12 3.51999 141.04 1.35999 151.56 1.98999V18.72L151.57 18.73Z" fill="white" />
                <path d="M208.92 30.88H227.54L202.94 98.47C199.43 108.1 194.78 115.11 188.98 119.47C183.18 123.84 175.97 125.8 167.35 125.35V109.16C172.03 109.25 175.74 108.26 178.48 106.19C181.22 104.12 183.41 100.79 185.03 96.2L157.36 30.88H176.39L193.99 76.23L208.92 30.88Z" fill="white" />
                <path d="M277.07 47.61H261.82V75.68C261.82 78.02 262.4 79.73 263.57 80.81C264.74 81.89 266.45 82.5 268.7 82.63C270.95 82.76 273.74 82.74 277.07 82.56V98.35C265.1 99.7 256.67 98.57 251.77 94.98C246.87 91.38 244.41 84.95 244.41 75.68V47.61H232.67V30.88H244.41V17.25L261.82 11.99V30.88H277.07V47.61Z" fill="white" />
                <path d="M307.84 42.48C309.46 38.16 312.14 34.92 315.87 32.76C319.6 30.6 323.76 29.52 328.35 29.52V48.95C323.04 48.32 318.3 49.4 314.11 52.19C309.93 54.98 307.83 59.61 307.83 66.09V98.34H290.42V30.88H307.83V42.49L307.84 42.48Z" fill="white" />
                <path d="M338.47 98.35V30.88H355.88V98.36H338.47V98.35Z" fill="white" />
                <path d="M447.29 45.69L433.53 64.62L447.29 83.55L458.05 98.35H436.53L422.78 79.42L409.02 98.35H387.5L398.26 83.55L412.02 64.62L398.26 45.69L387.5 30.88H409.02L422.78 49.81L436.53 30.88H458.05L447.29 45.69Z" fill="url(#paint0_linear_280_293)" />
                <path d="M384.51 64.62L398.26 83.55L387.5 98.35L373.75 79.42L362.99 64.62L373.75 49.81L387.5 30.88L398.26 45.69L384.51 64.62Z" fill="white" fillOpacity="0.1" />
                <path d="M482.56 64.62L471.8 79.42L458.05 98.35L447.29 83.55L461.04 64.62L447.29 45.69L458.05 30.88L471.8 49.81L482.56 64.62Z" fill="white" fillOpacity="0.1" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_280_293" x1="387.5" y1="98.0639" x2="459.163" y2="30.88" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F879FF" />
                    <stop offset="0.517684" stopColor="#57A6FF" />
                    <stop offset="1" stopColor="#50F8FF" />
                </linearGradient>
                <clipPath id="clip0_280_293">
                    <rect width="482.56" height="125.41" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default AppLogo;