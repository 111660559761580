import { Avatar, Button, Input, SvgIcon, styled } from "@mui/joy";
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import appError from "../../appError";
import { UUID, verify } from "crypto";
import toast from "react-hot-toast";
const VisuallyHiddenInput = styled('input')`
clip: rect(0 0 0 0);
clip-path: inset(50%);
height: 1px;
overflow: hidden;
position: absolute;
bottom: 0;
left: 0;
white-space: nowrap;
width: 1px;
`;

type BrandObject = {

    "company_name": string,
    "brand_name": string,
    "company_pan_num": string,
    "company_gst_num": string,
    "company_bank_name": string,
    "company_bank_account_num": string,
    "company_bank_ifsc_code": string,
    "company_address": string,
    "social_media_handle_url": string,
    "gst_certificate_url": string,
    "brand_logo_url": string,
    "landing_page_url": string,
    "additional_data": string,

    "created_time_stamp": string,
    "updated_time_stamp": string,
    "stage": string
    "status": string
}

const AccountSetting = () => {

    const brandId = useSelector((state: any) => state.appData.brand_id);
    const [brand, setBrand] = useState<BrandObject>()
    useEffect(() => {
        fetchBrand();
    }, [brandId]);

    const fetchBrand = async () => {
        try {
            const token = localStorage.getItem("token");
            const brandsRes = await axios.get(`${BASE_URL}brand?brand_id=${brandId}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const brands: BrandObject[] = brandsRes?.data?.data?.brands;
            if (brands.length) {
                setBrand(brands[0])
            }
            //setBrandArray(brands);
        } catch (error) {
            appError(error,"auth");
        }

    }

    const onInputChangeHandler = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement & { name: keyof BrandObject }>) => {
        setBrand((prevState) => {
            if (prevState) {
                return { ...prevState, [name]: value };
            } else {
                return { [name]: value } as BrandObject;
            }
        })
    }

    const onSaveClickHandler = async () => {
        try {
            const token = localStorage.getItem("token");
            const brandRes = await axios.put(`${BASE_URL}brand?brand_id=${brandId}`,
                brand,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
            const { message } = brandRes?.data?.data;
            toast.success(message);
        } catch (error) {
            appError(error);
        }
    }

    return (
        <>
            {
                brand ? (
                    <div className="flex flex-col px-10 pt-4">
                        <div className="overflow-y-auto" style={{ height: "calc(100vh - 176px) " }}>
                            {/* Header */}
                            <div className="flex items-center justify-between" >
                                <div className="flex flex-col justify-between" style={{ height: 62 }}  >
                                    <div style={{ fontSize: 24, fontWeight: 500 }}>Account Setting</div>
                                    <div style={{ fontSize: 18, fontWeight: 400, color: BLACK.B_40 }}>Set your account settings down below.</div>
                                </div>
                            </div>
                            {/* Container */}
                            <div className="flex mt-6" >
                                {/* Left Container */}
                                <div style={{ backgroundColor: PRIMARY.P_Light, borderRadius: 16 }} className="flex flex-col w-2/3 p-4" >
                                    <div style={{ height: 150 }} className="flex" >
                                        <Avatar src={process.env.PUBLIC_URL + "/profil.jpg"} sx={{ height: 150, width: 150 }} />
                                        <div className="ml-6" >
                                            <div style={{ fontSize: 14, fontWeight: 400, color: BLACK.B_40, marginBottom: 10 }} >Brand Name</div>
                                            <Input
                                                value={brand?.brand_name}
                                                name="brand_name"
                                                onChange={onInputChangeHandler}
                                                sx={{
                                                    height: 58,
                                                    width: 226,
                                                    fontSize: 14,
                                                    fontWeight: 500
                                                }}
                                            />
                                            <div className="flex flex-row items-center mt-4" >
                                                <Button
                                                    component="label"
                                                    role={undefined}
                                                    tabIndex={-1}
                                                    variant="outlined"
                                                    color="neutral"

                                                    sx={{
                                                        height: 40,
                                                        color: PRIMARY.P_BLue,
                                                        borderColor: PRIMARY.P_BLue,
                                                        borderRadius: "8px",


                                                    }}
                                                    startDecorator={
                                                        <SvgIcon>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={1.5}
                                                                stroke="currentColor"
                                                            >
                                                                <path
                                                                    color={PRIMARY.P_BLue}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                                                />
                                                            </svg>
                                                        </SvgIcon>
                                                    }
                                                >
                                                    Upload Image
                                                    <VisuallyHiddenInput
                                                        // onChange={onFileChangeHandler}
                                                        type="file" />
                                                </Button>
                                                <div className="ml-16" style={{ fontSize: 14, fontWeight: 400, width: 236, color: BLACK.B_40 }} >
                                                    At least 800X800 px recommended JPG or PNG is allowed
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="my-6" />
                                    <div className="p-6" style={{ backgroundColor: WHITE.W_100, borderRadius: 16 }} >
                                        <div style={{ fontSize: 18, fontWeight: 500 }} >Organization Info</div>
                                        <div className="flex mt-6" >
                                            <div style={{ height: 88 }} className="flex flex-col justify-between w-1/3" >
                                                <div style={{ fontSize: 14, fontWeight: 400, color: BLACK.B_40 }}>*Company Name</div>
                                                <Input
                                                    value={brand?.company_name}
                                                    name="company_name"
                                                    onChange={onInputChangeHandler}
                                                    sx={{
                                                        height: 58,
                                                        fontSize: 14,
                                                        fontWeight: 500
                                                    }}
                                                />
                                            </div>
                                            <div style={{ height: 88 }} className="flex flex-col justify-between w-1/3 ml-3" >
                                                <div style={{ fontSize: 14, fontWeight: 400, color: BLACK.B_40 }}>*PAN</div>
                                                <Input
                                                    value={brand?.company_pan_num}
                                                    name="company_pan_num"
                                                    onChange={onInputChangeHandler}
                                                    sx={{
                                                        height: 58,
                                                        fontSize: 14,
                                                        fontWeight: 500
                                                    }}
                                                />
                                            </div>
                                            <div style={{ height: 88 }} className="flex flex-col justify-between w-1/3 ml-3" >
                                                <div style={{ fontSize: 14, fontWeight: 400, color: BLACK.B_40 }}>*Company GST no.</div>
                                                <Input
                                                    value={brand?.company_gst_num}
                                                    name="company_gst_num"
                                                    onChange={onInputChangeHandler}
                                                    sx={{
                                                        height: 58,
                                                        fontSize: 14,
                                                        fontWeight: 500
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex mt-6" >
                                            <div style={{ height: 88 }} className="flex flex-col justify-between w-full" >
                                                <div style={{ fontSize: 14, fontWeight: 400, color: BLACK.B_40 }}>*Company Address</div>
                                                <Input
                                                    value={brand?.company_address}
                                                    name="company_address"
                                                    onChange={onInputChangeHandler}
                                                    sx={{
                                                        height: 58,
                                                        fontSize: 14,
                                                        fontWeight: 500
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="flex mt-6" >
                                            <div style={{ height: 88 }} className="flex flex-col justify-between w-1/3" >
                                                <div style={{ fontSize: 14, fontWeight: 400, color: BLACK.B_40 }}>TAN</div>
                                                <Input
                                                    sx={{
                                                        height: 58,
                                                        fontSize: 14,
                                                        fontWeight: 500
                                                    }}
                                                />
                                            </div>
                                            <div style={{ height: 88 }} className="flex flex-col justify-between w-1/3 ml-3" >
                                                <div style={{ fontSize: 14, fontWeight: 400, color: BLACK.B_40 }}>Website</div>
                                                <Input
                                                    sx={{
                                                        height: 58,
                                                        fontSize: 14,
                                                        fontWeight: 500
                                                    }}
                                                />
                                            </div>
                                            <div style={{ height: 88 }} className="flex flex-col justify-between w-1/3 " >

                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {/* Right Container */}
                                <div style={{ backgroundColor: PRIMARY.P_Light, borderRadius: 16 }} className="flex flex-col w-1/3 ml-6 p-4" >
                                    <div className="p-6" style={{ borderRadius: 16, backgroundColor: WHITE.W_100 }} >
                                        <div style={{ fontSize: 18, fontWeight: 500 }} >Bank Details</div>
                                        <div style={{ height: 88 }} className="flex flex-col justify-between" >
                                            <div style={{ fontSize: 14, fontWeight: 400, color: BLACK.B_40 }}>*Bank Name</div>
                                            <Input
                                                value={brand?.company_bank_name}
                                                name="company_bank_name"
                                                onChange={onInputChangeHandler}
                                                sx={{
                                                    height: 58,
                                                    fontSize: 14,
                                                    fontWeight: 500
                                                }}
                                            />
                                        </div>

                                        <div style={{ height: 88 }} className="flex flex-col justify-between" >
                                            <div style={{ fontSize: 14, fontWeight: 400, color: BLACK.B_40 }}>*Account Number</div>
                                            <Input
                                                value={brand?.company_bank_account_num}
                                                name="company_bank_account_num"
                                                onChange={onInputChangeHandler}
                                                sx={{
                                                    height: 58,
                                                    fontSize: 14,
                                                    fontWeight: 500
                                                }}
                                            />
                                        </div>

                                        <div style={{ height: 88 }} className="flex flex-col justify-between" >
                                            <div style={{ fontSize: 14, fontWeight: 400, color: BLACK.B_40 }}>*IFSC Code</div>
                                            <Input
                                                value={brand?.company_bank_ifsc_code}
                                                name="company_bank_ifsc_code"
                                                onChange={onInputChangeHandler}
                                                sx={{
                                                    height: 58,
                                                    fontSize: 14,
                                                    fontWeight: 500
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button
                            sx={{
                                margin: "24px 0px",
                                width: "236px",
                                ":disabled": {
                                    backgroundColor: PRIMARY.P_Blue,
                                    color: BLACK.B_10,
                                }
                            }}
                            className="self-end"
                            endDecorator={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M17.3172 10.4425L11.6922 16.0675C11.5749 16.1848 11.4159 16.2507 11.25 16.2507C11.0841 16.2507 10.9251 16.1848 10.8078 16.0675C10.6905 15.9503 10.6247 15.7912 10.6247 15.6253C10.6247 15.4595 10.6905 15.3004 10.8078 15.1832L15.3664 10.6253H3.125C2.95924 10.6253 2.80027 10.5595 2.68306 10.4423C2.56585 10.3251 2.5 10.1661 2.5 10.0003C2.5 9.83459 2.56585 9.67562 2.68306 9.55841C2.80027 9.4412 2.95924 9.37535 3.125 9.37535H15.3664L10.8078 4.81753C10.6905 4.70026 10.6247 4.5412 10.6247 4.37535C10.6247 4.2095 10.6905 4.05044 10.8078 3.93316C10.9251 3.81588 11.0841 3.75 11.25 3.75C11.4159 3.75 11.5749 3.81588 11.6922 3.93316L17.3172 9.55816C17.3753 9.61621 17.4214 9.68514 17.4529 9.76101C17.4843 9.83688 17.5005 9.91821 17.5005 10.0003C17.5005 10.0825 17.4843 10.1638 17.4529 10.2397C17.4214 10.3156 17.3753 10.3845 17.3172 10.4425Z" fill="white" />
                            </svg>
                            }
                            //disabled={rowData.length > 0 ? false : true}
                            //onClick={onSaveClickHandler}
                            onClick={onSaveClickHandler}
                        > Save & Continue</Button>
                    </div >
                ) : (
                    <div>you do not have any brand access</div>
                )
            }
        </>

    )

}

export default AccountSetting;