import { Button, Checkbox, CircularProgress, Input, Modal, Option, Select } from "@mui/joy";
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { UUID } from "crypto";
import axios from "axios";
import appError from "../../appError";
import Joi from "joi";
import toast from "react-hot-toast";


type AddDashboardModalProps = {
    brandId: UUID | undefined,
    brandName: string | undefined,
    setOpenAddDashboardPopUp: Dispatch<SetStateAction<boolean>>
}


const DashboardUrlModal: React.FC<AddDashboardModalProps> = ({ setOpenAddDashboardPopUp, brandId,brandName }) => {
    const user_id: UUID | undefined = undefined//editUser?.user_id;

    type MyState = {
        [key: string]: boolean;
    };
    type DashboardTypeObj = {
        brand_power_bi_dashboard_type_id: UUID,
        dashboard_type: string
    }
    type DashboardObj = {
        brand_power_bi_dashboard_type_id: UUID,
        dashboard_type?: string,
        workspace_id?: string,
        report_id?: string,
        brand_power_bi_dashboard_id?: UUID
    }




    const [lodingOnSave, setLoadingOnSave] = useState<boolean>(false);
    const [lodingDashboards, setLoadingDashboards] = useState<boolean>(false)
    const [addNewDashboardState, setAddNewDashboardState] = useState<DashboardObj>({
        workspace_id: "",
        report_id: "",
        brand_power_bi_dashboard_type_id: "" as UUID
    });


    const [editDashboard, setEditDashboard] = useState<DashboardObj>({
        workspace_id: "",
        report_id: "",
        brand_power_bi_dashboard_type_id: "" as UUID,

    })


    const [dashboardTypes, setDashboardTypes] = useState<DashboardTypeObj[]>([]);
    const [dashboards, setDashboards] = useState<DashboardObj[]>([]);
    useEffect(() => {
        fetchDashboardType();
        fetchDashboards();
    }, []);

    const fetchDashboards = async () => {
        try {
            setLoadingDashboards(true)
            const token = localStorage.getItem("token");

            const brandsRes = await axios.get(`${BASE_URL}brand/powerBiDashboard?brand_id=${brandId}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const { dashboard_array } = brandsRes?.data?.data;
            setDashboards(dashboard_array);
        } catch (error) {
            appError(error);
        } finally {
            setLoadingDashboards(false)
        }
    }

    const fetchDashboardType = async () => {
        try {
            const token = localStorage.getItem("token");

            const brandsRes = await axios.get(`${BASE_URL}brand/powerBiDashboardType`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const { dashboard_type_array } = brandsRes?.data?.data;
            setDashboardTypes(dashboard_type_array);
        } catch (error) {
            appError(error);
        }
    }




    const onChangeHandler = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>): void => {
        setAddNewDashboardState(prevState => ({ ...prevState, [name]: value }));
    }

    const onSelectDashboardTypeHandler = (e: any, value: any) => {
        setAddNewDashboardState(prevState => ({ ...prevState, brand_power_bi_dashboard_type_id: value }));
    }


    const onEditChangeHandler = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>): void => {
       
        setEditDashboard(prevState => ({ ...prevState, [name]: value }));
    }

    const onEditSelectDashboardTypeHandler = (e: any, value: any) => {

        setEditDashboard(prevState => ({ ...prevState, brand_power_bi_dashboard_type_id: value }));
    }



    const onEditSaveClickHandler = async () => {

        const schema = Joi.object({
            //brand_power_bi_dashboard_type_id: Joi.string().guid({ version: "uuidv4" }),
            workspace_id: Joi.string().required(),
            report_id: Joi.string().required(),
            // url: Joi.string().required()
        });
        const { workspace_id, report_id, brand_power_bi_dashboard_id } = editDashboard;
        const validate = schema.validate({ workspace_id, report_id });
        if (validate.error) {
            appError(validate?.error?.toString());
            return;
        }
        try {
            setLoadingDashboards(true);
            const token = localStorage.getItem("token");
            const { workspace_id, report_id } = validate?.value;
            const res = await axios.put(`${BASE_URL}brand/powerBiDashboard/${brand_power_bi_dashboard_id}`,
                { workspace_id, report_id },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            );
            const { message } = res?.data?.data;
            toast.success(message);
            fetchDashboards();
            setEditDashboard({
                workspace_id: "",
                report_id: "",
                brand_power_bi_dashboard_type_id: "" as UUID,
                brand_power_bi_dashboard_id: "" as UUID
            })

        } catch (error) {
            appError(error);
        } finally {
            setLoadingDashboards(false);
        }
    }




    const onSaveClickHandler = async () => {

        const schema = Joi.object({
            brand_power_bi_dashboard_type_id: Joi.string().guid({ version: "uuidv4" }),
            workspace_id: Joi.string().required(),
            report_id: Joi.string().required(),
        });

        const validate = schema.validate(addNewDashboardState);
        if (validate.error) {
            appError(validate?.error?.toString());
            return;
        }
        try {
            setLoadingOnSave(true);
            const token = localStorage.getItem("token");
            const { brand_power_bi_dashboard_type_id, workspace_id, report_id } = validate?.value;
            const res = await axios.post(`${BASE_URL}brand/powerBiDashboard?brand_id=${brandId}`,
                { brand_power_bi_dashboard_type_id, workspace_id, report_id },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            );
            const { message } = res?.data?.data;
            toast.success(message);
            fetchDashboards();
            setAddNewDashboardState({
                workspace_id: "",
                report_id: "",
                brand_power_bi_dashboard_type_id: "" as UUID
            })
            // setEditUser(null);
            // onRefreshUserBrand(user_id);
            // setOpenEdit(false);
        } catch (error) {
            appError(error);
        } finally {
            setLoadingOnSave(false);
        }
    }


    const onEditDashboardClickHandler = (dashboard: DashboardObj) => {
        setEditDashboard(dashboard)
    }

    const onCancelEditDashboardClickHandler = () => {
        setEditDashboard({
            workspace_id: "",
            report_id: "",
            brand_power_bi_dashboard_type_id: "" as UUID
        })
    }

    return (
        <Modal
            open={true}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <div
                style={{ height: 514, width: 990 }}
                className="border rounded-lg px-12 py-12 bg-white"
            >
                <div style={{ height: 58 }} className="flex flex-col justify-between" >
                    <div style={{ fontSize: 24, fontWeight: 500, color: BLACK.B_100 }}>Add New Dashboard</div>
                    <p style={{ fontSize: 14, fontWeight: 400, color: BLACK.B_40 }} >Manually enter your dashboard details for <span style={{color:PRIMARY.P_BLue}} >{brandName}</span>.</p>
                </div>
                {/* Form */}
                <div style={{ height: 248, marginTop: 40 }} >
                    <div className="flex flex-col">
                        <div style={{ height: 190 }} >
                            {/* able */}
                            <div className="flex flex-col h-full mt-2.5">
                                <div style={{ height: 32, fontSize: 14, fontWeight: 400, color: BLACK.B_40 }} className="flex flex-row items-center w-full border-b" >
                                    <div className="w-1/6">Dashboard Type</div>
                                    <div className="w-2/6" >Workspace Id</div>
                                    <div className="w-2/6" >Report Id</div>
                                    <div className="w-1/6">Action</div>
                                </div>
                                <div style={{ overflowY: "scroll", height: 122 }} className="mt-4 flex flex-col  items-center">
                                    {
                                        lodingDashboards ? <CircularProgress size="sm" /> : (
                                            dashboards?.map(dashboard => {
                                                return (
                                                    <div key={dashboard?.brand_power_bi_dashboard_type_id} style={{ fontSize: 14, fontWeight: 400, height: 32 }} className="flex flex-row items-center w-full my-1" >
                                                        {
                                                            editDashboard?.brand_power_bi_dashboard_id === dashboard?.brand_power_bi_dashboard_id ? (
                                                                <>
                                                                    <div className="w-1/6 pr-4">
                                                                        {dashboard?.dashboard_type}
                                                                        {/* <Select
                                                                            onChange={onEditSelectDashboardTypeHandler}
                                                                            // disabled={true}
                                                                            value={editDashboard?.brand_power_bi_dashboard_type_id}
                                                                            placeholder="*Dashboard Type"
                                                                            size="sm"
                                                                            sx={{
                                                                                "--Input-focusedThickness": "0px",
    
                                                                                backgroundColor: BLACK.B_5
                                                                            }}>
                                                                            {
                                                                                dashboardTypes?.map(dashboardType => {
                                                                                    return <Option key={dashboardType?.brand_power_bi_dashboard_type_id} value={dashboardType?.brand_power_bi_dashboard_type_id} >{dashboardType?.dashboard_type}</Option>
                                                                                })
                                                                            }
    
                                                                            
                                                                        </Select> */}
                                                                    </div>
                                                                    <div className="w-2/6 mr-4  overflow-scroll whitespace-nowrap" >
                                                                        <Input
                                                                            placeholder="workspace id"
                                                                            value={editDashboard?.workspace_id}
                                                                            //disabled={true}
                                                                            size="sm"
                                                                            sx={{
                                                                                "--Input-focusedThickness": "0px",


                                                                                backgroundColor: BLACK.B_5
                                                                            }}
                                                                            name="workspace_id"
                                                                            onChange={onEditChangeHandler}
                                                                        />
                                                                    </div>
                                                                    <div className="w-2/6 mr-4  overflow-scroll whitespace-nowrap" >
                                                                        <Input
                                                                            placeholder="report id"
                                                                            value={editDashboard?.report_id}
                                                                            //disabled={true}
                                                                            size="sm"
                                                                            sx={{
                                                                                "--Input-focusedThickness": "0px",


                                                                                backgroundColor: BLACK.B_5
                                                                            }}
                                                                            name="report_id"
                                                                            onChange={onEditChangeHandler}
                                                                        />
                                                                    </div>
                                                                    <div className="w-1/6  flex ">
                                                                        <div style={{ fontSize: 14, fontWeight: 400, borderRadius: 4, backgroundColor: PRIMARY.P_BLue, color: WHITE.W_100 }} onClick={() => onEditSaveClickHandler()} className=" cursor-pointer max-w-fit px-2 py-0.5 mr-1"  >
                                                                            Save
                                                                        </div>
                                                                        <div style={{ fontSize: 14, fontWeight: 400, borderRadius: 4, backgroundColor: BLACK.B_40, color: WHITE.W_100 }} onClick={() => onCancelEditDashboardClickHandler()} className=" cursor-pointer max-w-fit px-2 py-0.5"  >
                                                                            cancel
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="w-1/6">
                                                                        {dashboard?.dashboard_type}
                                                                    </div>
                                                                    <div className="w-2/6  overflow-hidden whitespace-nowrap" >
                                                                        {dashboard?.workspace_id}
                                                                    </div>
                                                                    <div className="w-2/6   overflow-hidden whitespace-nowrap" >
                                                                        {dashboard?.report_id}
                                                                    </div>
                                                                    <div className="w-1/6 ">
                                                                        <div onClick={() => onEditDashboardClickHandler(dashboard)} className="mr-6 cursor-pointer max-w-fit"  >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M21.5306 5.46899L18.5306 2.46899C18.461 2.39926 18.3783 2.34394 18.2872 2.30619C18.1962 2.26845 18.0986 2.24902 18 2.24902C17.9014 2.24902 17.8038 2.26845 17.7128 2.30619C17.6217 2.34394 17.539 2.39926 17.4694 2.46899L8.46937 11.469C8.39975 11.5387 8.34454 11.6214 8.3069 11.7125C8.26926 11.8035 8.24992 11.9011 8.25 11.9996V14.9996C8.25 15.1985 8.32902 15.3893 8.46967 15.5299C8.61032 15.6706 8.80109 15.7496 9 15.7496H12C12.0985 15.7497 12.1961 15.7304 12.2871 15.6927C12.3782 15.6551 12.4609 15.5999 12.5306 15.5302L21.5306 6.53024C21.6004 6.46058 21.6557 6.37787 21.6934 6.28682C21.7312 6.19577 21.7506 6.09818 21.7506 5.99961C21.7506 5.90105 21.7312 5.80346 21.6934 5.71241C21.6557 5.62136 21.6004 5.53864 21.5306 5.46899ZM11.6897 14.2496H9.75V12.3099L15.75 6.30993L17.6897 8.24961L11.6897 14.2496ZM18.75 7.1893L16.8103 5.24961L18 4.05993L19.9397 5.99961L18.75 7.1893ZM21 11.2496V19.4996C21 19.8974 20.842 20.279 20.5607 20.5603C20.2794 20.8416 19.8978 20.9996 19.5 20.9996H4.5C4.10218 20.9996 3.72064 20.8416 3.43934 20.5603C3.15804 20.279 3 19.8974 3 19.4996V4.49961C3 4.10179 3.15804 3.72026 3.43934 3.43895C3.72064 3.15765 4.10218 2.99961 4.5 2.99961H12.75C12.9489 2.99961 13.1397 3.07863 13.2803 3.21928C13.421 3.35994 13.5 3.5507 13.5 3.74961C13.5 3.94853 13.421 4.13929 13.2803 4.27994C13.1397 4.4206 12.9489 4.49961 12.75 4.49961H4.5V19.4996H19.5V11.2496C19.5 11.0507 19.579 10.8599 19.7197 10.7193C19.8603 10.5786 20.0511 10.4996 20.25 10.4996C20.4489 10.4996 20.6397 10.5786 20.7803 10.7193C20.921 10.8599 21 11.0507 21 11.2496Z" fill="#1886FE" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }

                                                    </div>
                                                )
                                            })
                                        )
                                    }


                                </div>
                            </div>
                        </div>
                        <div className=" flex flex-row w-full justify-between" >
                            <Select
                                onChange={onSelectDashboardTypeHandler}
                                // disabled={true}
                                value={addNewDashboardState?.brand_power_bi_dashboard_type_id}
                                placeholder="*Dashboard Type"
                                sx={{
                                    "--Input-focusedThickness": "0px",
                                    width: "200px",
                                    height: "58px",
                                    backgroundColor: BLACK.B_5
                                }}>
                                {
                                    dashboardTypes?.map(dashboardType => {
                                        return <Option disabled={(dashboards.find(d => d.brand_power_bi_dashboard_type_id === dashboardType?.brand_power_bi_dashboard_type_id)) ? true : false} value={dashboardType?.brand_power_bi_dashboard_type_id} >{dashboardType?.dashboard_type}</Option>
                                    })
                                }

                                {/* <Option value={"CLIENT"}>CLIENT</Option> */}
                            </Select>

                            <Input
                                placeholder="workspace id"
                                value={addNewDashboardState?.workspace_id}
                                //disabled={true}
                                sx={{
                                    "--Input-focusedThickness": "0px",
                                    marginBottom: "14px",
                                    width: "340px",
                                    height: "58px",
                                    backgroundColor: BLACK.B_5
                                }}
                                name="workspace_id"
                                onChange={onChangeHandler}
                            />


                            <Input
                                placeholder="report id"
                                value={addNewDashboardState?.report_id}
                                //disabled={true}
                                sx={{
                                    "--Input-focusedThickness": "0px",
                                    marginBottom: "14px",
                                    width: "340px",
                                    height: "58px",
                                    backgroundColor: BLACK.B_5
                                }}
                                name="report_id"
                                onChange={onChangeHandler}
                            />

                        </div>
                    </div>


                    <div className="flex flex-row justify-between mt-6">
                        <Button color="neutral"
                            sx={{
                                height: "48px",
                                width: "45%",
                                backgroundColor: BLACK.B_5,
                                color: BLACK.B_100,
                                ":hover": {
                                    backgroundColor: BLACK.B_20,
                                }
                            }}
                            onClick={() => setOpenAddDashboardPopUp(false)}
                        >Close</Button>
                        <Button
                            disabled={addNewDashboardState.brand_power_bi_dashboard_type_id && addNewDashboardState.workspace_id && addNewDashboardState.report_id ? false : true}
                            loading={lodingOnSave}
                            onClick={onSaveClickHandler}
                            endDecorator={
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                    <path d="M17.8172 10.4425L12.1922 16.0675C12.0749 16.1848 11.9159 16.2507 11.75 16.2507C11.5841 16.2507 11.4251 16.1848 11.3078 16.0675C11.1905 15.9503 11.1247 15.7912 11.1247 15.6253C11.1247 15.4595 11.1905 15.3004 11.3078 15.1832L15.8664 10.6253H3.625C3.45924 10.6253 3.30027 10.5595 3.18306 10.4423C3.06585 10.3251 3 10.1661 3 10.0003C3 9.83459 3.06585 9.67562 3.18306 9.55841C3.30027 9.4412 3.45924 9.37535 3.625 9.37535H15.8664L11.3078 4.81753C11.1905 4.70026 11.1247 4.5412 11.1247 4.37535C11.1247 4.2095 11.1905 4.05044 11.3078 3.93316C11.4251 3.81588 11.5841 3.75 11.75 3.75C11.9159 3.75 12.0749 3.81588 12.1922 3.93316L17.8172 9.55816C17.8753 9.61621 17.9214 9.68514 17.9529 9.76101C17.9843 9.83688 18.0005 9.91821 18.0005 10.0003C18.0005 10.0825 17.9843 10.1638 17.9529 10.2397C17.9214 10.3156 17.8753 10.3845 17.8172 10.4425Z" fill="white" />
                                </svg>
                            }
                            sx={{
                                height: "48px",
                                width: "45%",
                                backgroundColor: PRIMARY.P_BLue,
                            }}
                        >Save</Button>
                    </div>
                </div>
            </div>
        </Modal >
    )
}

export default DashboardUrlModal


