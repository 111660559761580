import React from 'react';
import './App.css';
import Login from './components/auth/Login';
import { Toaster } from 'react-hot-toast';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import PowerBiIframe from './components/iframe/PowerBiIframe';
import Header from './components/appComponents/Header';
import Main from './components/appComponents/Main';
import UserManagement from './components/admin/UserManagement';
import BrandManagement from './components/admin/BrandManagement';
import OnBoarding from './components/onBoarding/OnBoarding';
import Category from './components/onBoarding/Category';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Keyword from './components/onBoarding/Keyword';
import Product from './components/onBoarding/Product';
import CategoryMapping from './components/onBoarding/CategoryMapping';
import Crawl from './components/onBoarding/Crawl';
import ViewCategory from './components/onBoarding/ViewCategory';
import { Provider } from 'react-redux';
import store from "./redux/store";
import Campaign from './components/onBoarding/Campaign';
import ParentChildMapping from './components/onBoarding/ParentChildMapping';
import AccountSetting from './components/onBoarding/AccountSetting';
import FavoriteSelection from './components/onBoarding/FavoriteSelection';
import CreatePassword from './components/auth/CreatePassword';
import ViewKeyword from './components/onBoarding/ViewKeyword';
import ManualOnBoarding from './components/manualOnBoarding/ManualOnBoarding';
import PreparingDashboard from './components/utils/PreparingDashboard';
import UnmappedData from './components/unmappedData/UnmappedData';
import ProductMaster from './components/unmappedData/ProductMaster';
import CampaignMaster from './components/unmappedData/CampaignMaster';
import KeywordMaster from './components/unmappedData/KeywordMaster';
import PowerBi from './components/powerbi/PowerBi';
import BulkUpload from './components/bulkUpload/BulkUpload';
import LatestFiles from './components/bulkUpload/LatestFiles';
import AllFiles from './components/bulkUpload/AllFiles';
import GroupKeyword from './components/groupKeyword/GroupKeyword';
function App() {


  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
      children: [
        {
          path: "unmappedData",
          element: <UnmappedData />,
          children: [
            {
              path: "productMaster",
              element: <ProductMaster />,
            },
            {
              path: "campaignMaster",
              element: <CampaignMaster />,
            },
            {
              path: "keywordMaster",
              element: <KeywordMaster />,
            }
          ]
        },
        {
          path: "bulkUpload",
          element: <BulkUpload />,
          children: [
            {
              path: "latestFiles",
              element: <LatestFiles />,
            },
            {
              path: "allFiles",
              element: <AllFiles />,
            }
          ]
        },
        {
          path: "groupKeyword",
          element: <GroupKeyword />
        },
        {
          path: "dashboard",
          element: <PowerBi />
        },
        {
          path: "embeddedDashboard",
          element: <PowerBi />
        },
        {
          path: "preparingDashboard",
          element: <PreparingDashboard />
        },
        {
          path: "userManagement",
          element: <UserManagement />
        },
        {
          path: "brandManagement",
          element: <BrandManagement />
        },
        {
          path: "onBoardingManual",
          element: <ManualOnBoarding />
        },
        {
          path: "category",
          element: <Category />
        },
        {
          path: "viewCategory",
          element: <ViewCategory />
        },
        {
          path: "keyword",
          element: <Keyword />
        },
        {
          path: "viewKeyword",
          element: <ViewKeyword />
        },
        {
          path: "onBoarding",
          element: <OnBoarding />,
          children: [
            {
              path: "category",
              element: <Category />
            },
            {
              path: "viewCategory",
              element: <ViewCategory />
            },
            {
              path: "keyword",
              element: <Keyword />
            },
            {
              path: "viewKeyword",
              element: <ViewKeyword />
            },
            {
              path: "product",
              element: <Product />
            },
            {
              path: "categoryMapping",
              element: <CategoryMapping />
            },
            {
              path: "crawl",
              element: <Crawl />
            },
            {
              path: "campaign",
              element: <Campaign />
            },
            {
              path: "parentChildMapping",
              element: <ParentChildMapping />
            },
            {
              path: "favoriteSelection",
              element: <FavoriteSelection />
            }
          ]
        },
        {
          path: "accountSetting",
          element: <AccountSetting />
        }
      ]
    },
  
    {
      path: "/login",
      element: <Login />
    },
    {
      path: "/createPassword/:token",
      element: <CreatePassword />
    }
  ]);



  return (
    <>
      <Provider store={store}>

        <RouterProvider router={router} />
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </Provider>
    </>
  );
}

export default App;
