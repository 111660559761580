import { Button, SvgIcon, styled } from "@mui/joy";
import { BASE_URL, BLACK, PRIMARY, WHITE } from "../../appConstants";
import { useState } from "react";
import xlsx_to_json from "../../appFunctions";
import appError from "../../appError";
import axios from "axios";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const VisuallyHiddenInput = styled('input')`
clip: rect(0 0 0 0);
clip-path: inset(50%);
height: 1px;
overflow: hidden;
position: absolute;
bottom: 0;
left: 0;
white-space: nowrap;
width: 1px;
`;

type CampaignObject = {
    external_campaign_id: string,
    campaign_id: string,
    campaign_name: string,
    campaign_type: string,
    platform: string,
    campaign_json: any

}

const Campaign = () => {
    const brandId = useSelector((state: any) => state.appData.brand_id);
    const [rowData, setRowData] = useState<CampaignObject[]>([]);
    const [fileName, setFileName] = useState<string>("");


    const onFileChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!(event.target.files && event.target.files.length > 0)) {
            return;
        }

        try {
            const file = event.target.files[0]
            let brand_campaign_array: CampaignObject[] = await xlsx_to_json(file);

            setFileName(file.name);
            setRowData(brand_campaign_array);
        } catch (error) {
            appError(error);
        }
    }

    const onSaveClickHandler = async () => {
        try {
            const token = localStorage.getItem("token");
            rowData.forEach(el => {
                el.campaign_id = el.campaign_id.toString();
                el.campaign_name = el.campaign_name.toString();
                el.campaign_type = el.campaign_type.toString();
                el.external_campaign_id = el.external_campaign_id.toString();
                el.platform = el.platform.toString();
            });

            const res = await axios.post(`${BASE_URL}onboarding/campaigns?brand_id=${brandId}`,
                { brand_campaign_array: rowData },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            );
            const { message } = res?.data?.data;
            toast.success(message);
            setRowData([]);
            setFileName("");
        } catch (error) {
            appError(error);
        }
    }

    return (
        <div className="flex flex-col pt-8 px-10 h-full" >
            {/* Header */}
            <div className="flex flex-row items-center mb-5" style={{ height: 62 }} >
                <div className="w-full" >
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Campaign</div>
                    <div style={{ fontSize: 18, fontWeight: 400, color: BLACK.B_40 }} >You can see all your campaign details of Amazon here. Please follow the steps to fetch the details</div>
                </div>
            </div>
            {/* Container */}
            <div className="flex flex-row h-full mt-12 p-12" style={{ borderRadius: 12, backgroundColor: PRIMARY.P_Light }} >
                {/* Left Container */}
                <div className="flex-1" >
                    <div style={{ fontSize: 18, fontWeight: 500 }}>
                        1. Download the file
                    </div>
                    <div style={{ fontSize: 14, fontWeight: 400 }} className="mt-4" >Read the instructions on right side carefully before downloading.</div>
                    <div style={{ fontSize: 14, fontWeight: 400 }} className="flex  mt-4" >
                        <a className="flex items-center justify-center py-1 px-2" style={{ backgroundColor: PRIMARY.P_BLue, borderRadius: 8, color: WHITE.W_100 }} href="https://infytrix-website-asset.s3.ap-south-1.amazonaws.com/website/onboarding/infytrix_campaign_master_compiler.exe" >Download for Windows OS</a>
                        <a className="flex items-center justify-center py-1 px-2 ml-4" style={{ backgroundColor: PRIMARY.P_BLue, borderRadius: 8, color: WHITE.W_100 }} href="https://infytrix-website-asset.s3.ap-south-1.amazonaws.com/website/onboarding/infytrix_campaign_master_compiler" >Download for Mac OS</a>
                    </div>
                    <div className="mt-12" style={{ fontSize: 18, fontWeight: 500 }}>
                        1. Upload your file
                    </div>
                    <div className="flex items-center" style={{ marginTop: "16px" }}>
                        <Button
                            component="label"
                            role={undefined}
                            tabIndex={-1}
                            variant="outlined"
                            color="neutral"
                            size="sm"
                            sx={{
                                color: PRIMARY.P_BLue,
                                borderColor: PRIMARY.P_BLue,
                                borderRadius: "8px",


                            }}
                            startDecorator={
                                <SvgIcon>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                    >
                                        <path
                                            color={PRIMARY.P_BLue}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                        />
                                    </svg>
                                </SvgIcon>
                            }
                        >
                            Upload Excel
                            <VisuallyHiddenInput
                                onChange={onFileChangeHandler}
                                type="file" />
                        </Button>
                        <div className="ml-4" style={{ color: BLACK.B_40 }}>
                            {fileName}
                        </div>
                    </div>

                </div>
                {/* Right Container */}
                <div style={{ width: 404, marginLeft: 114 }} >
                    <div style={{ fontSize: 18, fontWeight: 500 }}>
                        Upload Instructions
                    </div>
                    <div className="py-6 px-4 mt-4" style={{ backgroundColor: WHITE.W_100, borderRadius: 12 }}>
                        <div style={{ fontSize: 14, fontWeight: 400 }} >1. Download and run campaign-mapping.exe application.</div>
                        <div className="mt-2" style={{ fontSize: 14, fontWeight: 400 }} >2. The application will launch a chrome browser with amazon advertising portal.</div>
                        <div className="mt-2" style={{ fontSize: 14, fontWeight: 400 }} >3. User has to login into the amazon advertising account and select the desired account.</div>
                        <div className="mt-2" style={{ fontSize: 14, fontWeight: 400 }} >4. The browser will download an excel name campaign-mapping.xlsx and the launch browser terminates.</div>
                        <div className="mt-2" style={{ fontSize: 14, fontWeight: 400 }} >5. User has to upload the excel file on this page.</div>

                    </div>
                </div>
            </div>
            <Button
                sx={{
                    margin: "24px 0px",
                    width: "236px",
                    ":disabled": {
                        backgroundColor: PRIMARY.P_Blue,
                        color: BLACK.B_10,
                    }
                }}
                className="self-end"
                endDecorator={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M17.3172 10.4425L11.6922 16.0675C11.5749 16.1848 11.4159 16.2507 11.25 16.2507C11.0841 16.2507 10.9251 16.1848 10.8078 16.0675C10.6905 15.9503 10.6247 15.7912 10.6247 15.6253C10.6247 15.4595 10.6905 15.3004 10.8078 15.1832L15.3664 10.6253H3.125C2.95924 10.6253 2.80027 10.5595 2.68306 10.4423C2.56585 10.3251 2.5 10.1661 2.5 10.0003C2.5 9.83459 2.56585 9.67562 2.68306 9.55841C2.80027 9.4412 2.95924 9.37535 3.125 9.37535H15.3664L10.8078 4.81753C10.6905 4.70026 10.6247 4.5412 10.6247 4.37535C10.6247 4.2095 10.6905 4.05044 10.8078 3.93316C10.9251 3.81588 11.0841 3.75 11.25 3.75C11.4159 3.75 11.5749 3.81588 11.6922 3.93316L17.3172 9.55816C17.3753 9.61621 17.4214 9.68514 17.4529 9.76101C17.4843 9.83688 17.5005 9.91821 17.5005 10.0003C17.5005 10.0825 17.4843 10.1638 17.4529 10.2397C17.4214 10.3156 17.3753 10.3845 17.3172 10.4425Z" fill="#1C1C1C" fill-opacity="0.1" />
                </svg>
                }
                disabled={rowData.length > 0 ? false : true}
                onClick={onSaveClickHandler}
            > Save & Continue</Button>
        </div>
    )
}

export default Campaign;