import axios from "axios"
import { UUID } from "crypto"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { BASE_URL, BLACK } from "../../appConstants"
import appError from "../../appError"


type SubCategoriesComponentProps = {
    brand_main_category_id: string
}

type subCategorybject = {
    brand_sub_category: string,
    brand_sub_category_id: UUID
}

const SubCategoriesComponent: React.FC<SubCategoriesComponentProps> = ({ brand_main_category_id }) => {
    const brandId = useSelector((state: any) => state.appData.brand_id);

    const [subCategories, setSubCategories] = useState<subCategorybject[]>();
    useEffect(() => {
        fetchMainCategories();
    }, [brandId]);
    //Fetch Main Categories Function
    const fetchMainCategories = async () => {
        try {
            const token = localStorage.getItem("token");

            const res = await axios.get(`${BASE_URL}onboarding/subCategories?brand_id=${brandId}&brand_main_category_id=${brand_main_category_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            const { brand_sub_category_array } = res?.data?.data;
            setSubCategories(brand_sub_category_array);
        } catch (error) {
            appError(error);
        } finally {

        }
    }

    return (
        <div className="flex" >
            {
                subCategories?.map(el => {
                    return (
                        <div className="py-1 px-2 my-1 mx-2" style={{backgroundColor:BLACK.B_10,borderRadius:8}}>
                            {el.brand_sub_category}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default SubCategoriesComponent;