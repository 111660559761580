import { Button, Checkbox, Input, Modal, Option, Select } from "@mui/joy";
import { BASE_URL, BLACK, PRIMARY } from "../../appConstants";
import React, { Dispatch, SetStateAction, useState } from "react";
import { UUID } from "crypto";
import axios from "axios";
import appError from "../../appError";
import Joi from "joi";
import toast from "react-hot-toast";
type AddNewBrandModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>
}
const AddNewBrandModal: React.FC<AddNewBrandModalProps> = ({ setOpen }) => {

    const [state, setState] = useState({
        brand_name: "",
        company_name: ""
    });
    const [loading, setLoading] = useState<boolean>(false);
    const onChangeHandler = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>): void => {
        setState(prevState => ({ ...prevState, [name]: value }))
    }

    const onSaveClickHandler = async () => {

        const schema = Joi.object({
            brand_name: Joi.string().required().min(1),
            company_name: Joi.string().required().min(1)
        });

        const validate = schema.validate(state);
        if (validate.error) {
            appError(validate?.error?.toString());
            return;
        }
        try {
            setLoading(true);
            const token = localStorage.getItem("token");
            const res = await axios.post(`${BASE_URL}brand`,
                validate?.value,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            );
            const { message } = res?.data?.data;
            toast.success(message);
            setOpen(false);
        } catch (error) {
            appError(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            open={true}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <div
                style={{ height: 364, width: 790 }}
                className="border rounded-lg px-12 py-12 bg-white"
            >
                <div style={{ height: 58 }} className="flex flex-col justify-between" >
                    <div style={{ fontSize: 24, fontWeight: 500, color: BLACK.B_100 }}>Add New Brand</div>
                    <p style={{ fontSize: 14, fontWeight: 400, color: BLACK.B_40 }} >Manually enter your Brand details</p>
                </div>
                {/* Form */}
                <div style={{ height: 208, marginTop: 40 }} >
                    <div className="flex flex-row  justify-between w-full mb-4" >
                        <Input
                            placeholder="*Company Name"
                            sx={{
                                "--Input-focusedThickness": "0px",
                                width: "340px",
                                height: "58px",
                                backgroundColor: BLACK.B_5
                            }}
                            name="company_name"
                            onChange={onChangeHandler}
                        />
                        <Input
                            placeholder="*Brand Name"
                            sx={{
                                "--Input-focusedThickness": "0px",
                                width: "340px",
                                height: "58px",
                                backgroundColor: BLACK.B_5
                            }}
                            name="brand_name"
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="flex flex-row justify-between mt-16">
                        <Button color="neutral"
                            sx={{
                                height: "48px",
                                width: "340px",
                                backgroundColor: BLACK.B_5,
                                color: BLACK.B_100,
                                ":hover": {
                                    backgroundColor: BLACK.B_20,
                                }
                            }}
                            onClick={() => setOpen(false)}
                        >Cancel</Button>
                        <Button
                            loading={loading}
                            onClick={onSaveClickHandler}
                            endDecorator={
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                    <path d="M17.8172 10.4425L12.1922 16.0675C12.0749 16.1848 11.9159 16.2507 11.75 16.2507C11.5841 16.2507 11.4251 16.1848 11.3078 16.0675C11.1905 15.9503 11.1247 15.7912 11.1247 15.6253C11.1247 15.4595 11.1905 15.3004 11.3078 15.1832L15.8664 10.6253H3.625C3.45924 10.6253 3.30027 10.5595 3.18306 10.4423C3.06585 10.3251 3 10.1661 3 10.0003C3 9.83459 3.06585 9.67562 3.18306 9.55841C3.30027 9.4412 3.45924 9.37535 3.625 9.37535H15.8664L11.3078 4.81753C11.1905 4.70026 11.1247 4.5412 11.1247 4.37535C11.1247 4.2095 11.1905 4.05044 11.3078 3.93316C11.4251 3.81588 11.5841 3.75 11.75 3.75C11.9159 3.75 12.0749 3.81588 12.1922 3.93316L17.8172 9.55816C17.8753 9.61621 17.9214 9.68514 17.9529 9.76101C17.9843 9.83688 18.0005 9.91821 18.0005 10.0003C18.0005 10.0825 17.9843 10.1638 17.9529 10.2397C17.9214 10.3156 17.8753 10.3845 17.8172 10.4425Z" fill="white" />
                                </svg>
                            }
                            sx={{
                                height: "48px",
                                width: "340px",
                                backgroundColor: PRIMARY.P_BLue,
                            }}
                        >Save</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AddNewBrandModal