import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import { useEffect, useState } from "react";
import { UUID } from "crypto";
import axios from "axios";
import { BASE_URL } from "../../appConstants";
import appError from "../../appError";




const Main = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            navigate("/dashboard");
        } else {
            navigate("/login");
        }
    }, [])



    const clickedOnMenu = (url: string) => {
        navigate(url);
    }



    return (
        <div className="h-vh" >
            <Header clickedOnMenu={clickedOnMenu} />
            <div style={{ height: "calc(100vh - 72px)" }} >
                <Outlet />
            </div>
        </div>
    )
}


export default Main;